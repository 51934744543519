import loadable from 'znipe-utils/web/loadable';
import { Navigate } from 'react-router-dom';

import LogoutPage from '../../Logout/index';

import Error404Page from '../../Page404';
import PrivacyPage from '../../Privacy';
import TermsOfUsePage from '../../TermsOfUse';
import Embed from '../../Embed/Embed';
import Home from '../../Home/Home';
import Topic from '../../Topic/Topic';
import HighlightStage from '../../HighlightStage/HighlightStage';
import Matches from '../../Matches/Matches';
import StandingsPage from '../../Standings/Standings';
import BrowsePage from '../../Browse/Browse';
import RootPage from '../../Root';
import StageV2 from '../../Stage/StageV2';
import Subscribe from '../../Subscribe/Subscribe';
import Settings from '../../Settings/Settings';
import LoginPage from '../../Login/Login';
import ContentPolicy from '../../ContentPolicy/ContentPolicy';

const PopoutV2 = loadable(() => import('../../PopOut/PopoutV2'));

// This is to use the same component when the multiple routes show the same page
// @TODO Use nested routes instead
const standings = <StandingsPage />;
const topic = <Topic />;
const highlight = <HighlightStage />;
const browse = <BrowsePage />;
const matches = <Matches />;

const packageSlugRoutes = [
  {
    path: 'content-policy',
    element: <ContentPolicy />,
    scrollToTopOnMount: true,
  },
  {
    path: 'login/*',
    element: <LoginPage />,
    scrollToTopOnMount: true,
  },
  {
    path: 'logout',
    element: <LogoutPage />,
  },
  {
    path: 'embed',
    element: <Embed />,
    layout: 'stage',
    compact: true,
  },
  {
    path: 'popout',
    element: <PopoutV2 />,
    dontStartUserSessionHeartbeat: true,
  },
  {
    path: 'home',
    element: <Home />,
    layout: 'default',
  },
  // {
  //   path: 'home/:initialTab',
  //   element: <Home />,
  //   layout: 'default',
  // },
  {
    path: 'topic/:topicType/:topicQuery',
    element: topic,
    layout: 'default',
    scrollToTopOnMount: true,
  },
  {
    path: 'topic/:topicType/:topicQuery/:initialTab',
    element: topic,
    layout: 'default',
    scrollToTopOnMount: true,
  },
  {
    path: 'highlight',
    element: highlight,
    layout: 'stage',
    scrollToTopOnMount: true,
  },
  {
    path: 'editorial',
    element: highlight,
    layout: 'stage',
    scrollToTopOnMount: true,
  },
  {
    path: 'standings',
    element: standings,
    layout: 'default',
  },
  {
    path: 'standings/:tournament',
    element: standings,
    layout: 'default',
  },
  {
    path: 'standings/:tournament/:tournamentStage',
    element: standings,
    layout: 'default',
  },
  {
    path: 'browse',
    element: browse,
    layout: 'default',
    scrollToTopOnMount: true,
  },
  {
    path: 'browse/:subpage',
    element: browse,
    layout: 'default',
    scrollToTopOnMount: true,
  },
  {
    path: 'matches',
    element: matches,
    layout: 'default',
  },
  {
    path: 'matches/:initialTab',
    element: matches,
    layout: 'default',
  },
  {
    path: 'stage',
    element: <StageV2 />,
    layout: 'stage',
  },
  {
    path: 'subscribe/*',
    element: <Subscribe />,
  },
  {
    path: 'settings/*',
    element: <Settings />,
  },
];

// This is a hack due to react router 6 not supporting optional args anymore
const fixedRoutes = packageSlugRoutes.reduce((acc, route) => {
  const withSlug = { ...route };
  withSlug.path = `:packageSlug/${route.path}`;
  acc.push(route);
  acc.push(withSlug);
  return acc;
}, []);

const routes = [
  {
    path: '/',
    element: <RootPage />,
  },
  // {
  //   path: '/iframetest',
  //   element: <IframeTest />,
  //   scrollToTopOnMount: true,
  // },
  // {
  {
    path: '/privacy',
    element: <PrivacyPage />,
    scrollToTopOnMount: true,
  },
  {
    path: '/terms-of-use',
    element: <TermsOfUsePage />,
    scrollToTopOnMount: true,
  },
  ...fixedRoutes,
  {
    path: '/:packageSlug',
    redirect: <Navigate to="home" />,
  },
  {
    path: '*',
    element: <Error404Page />,
  },
];

export default routes;
