import isBrowser from 'znipe-utils/web/isBrowser';

const PRODUCTION = 'production';

const env = process.env.ENV || PRODUCTION;
const isProduction = env === PRODUCTION;
const loglevel =
  (isBrowser() ? localStorage : undefined)?.getItem('loglevel') || !isProduction ? 1 : 0;

// We want the noop to have the same signature as the console functions
const noop = (..._args: unknown[]) => undefined;

const Logger = {
  log: loglevel ? console.log : noop,
  warn: loglevel ? console.warn : noop,
  error: loglevel ? console.error : noop,
  deprecate: (...args: any[]) => console.warn('ZNIPE DEPRECATION WARNING: ', ...args),
};
export const { log, warn, error } = Logger;
export default Logger;
