import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';

const over = css`
  top: 0;
  transform: translateY(-40%);
`;

const under = css`
  bottom: 0;
  transform: translateY(40%);
`;

type BarProps = {
  $placement?: string;
};

export const Bar = styled.div<BarProps>`
  position: absolute;
  z-index: 1;
  display: flex;
  gap: 8px;
  right: 8px;
  ${({ $placement }) => ($placement === 'under' ? under : over)}
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ theme }) => theme.tertiaryBackgroundColor};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  height: 24px;
  width: 24px;
  border: none;
  padding: 0;
  color: ${colors.white};
`;
