import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

export const SectionWrapper = styled.div`
  ${({ $noPadding }) =>
    !$noPadding &&
    css`
      padding-top: 16px;
    `}
  > * {
    position: relative;
    margin-bottom: 16px;
  }
  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      margin: 0 8px;
    `}
`;

export const SectionLoading = styled.div`
  & > * {
    margin: 0 16px;
  }

  & > *:first-child {
    margin-bottom: 8px;
  }

  @media ${device.tablet} {
    & > * {
      margin: 0;
    }
  }
`;
