import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import prefers from 'znipe-styles/prefers';
import SingleViewPlayer from 'znipe-player/src/components/SingleView/SingleView';
import getResizeUrl from 'znipe-utils/misc/getResizeURL';
import useWindowWidth from 'znipe-hooks/useWindowWidth';

const Thumbnail = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  > *,
  img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;

const PosterOverlay = styled.img`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  @media ${prefers.motion} {
    transition: 0.4s;
  }
`;

const StreamThumbnailPreview = ({
  targetRef = null,
  src = '',
  thumbnail = '',
  alt = '',
  autoPlay = false,
}) => {
  const playerRef = useRef(null);
  const thumbnailRef = useRef({});
  const [isHovering, setIsHovering] = useState(autoPlay);
  const windowWidth = useWindowWidth();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Recalculate on window resize
  const maxThumbnailWidth = useMemo(() => {
    if (!thumbnailRef.current) return 480;
    const width = thumbnailRef.current.offsetWidth;
    if (width < 480) return 480;
    if (width < 720) return 720;
    return 1024;
  }, [windowWidth]);

  const posterImg = useMemo(() => {
    if (thumbnail) {
      return getResizeUrl(thumbnail, maxThumbnailWidth);
    }
    return '';
  }, [thumbnail, maxThumbnailWidth]);

  const handlePlay = useCallback(() => {
    if (autoPlay) return;
    setIsHovering(true);
    const player = playerRef.current;
    if (!player) return;
    player.play();
  }, [autoPlay]);

  const handlePause = useCallback(() => {
    if (autoPlay) return;
    setIsHovering(false);
    const player = playerRef.current;
    if (!player) return;
    player.seek(0);
    player.pause();
  }, [autoPlay]);

  // set mouse events on parent
  useEffect(() => {
    const target = (targetRef || {}).current;
    if (!target) return () => {};
    target.addEventListener('mouseenter', handlePlay);
    target.addEventListener('mouseleave', handlePause);
    return () => {
      target.removeEventListener('mouseenter', handlePlay);
      target.removeEventListener('mouseleave', handlePause);
    };
  }, [targetRef, handlePlay, handlePause]);

  const configuration = useMemo(
    () => ({
      streaming: {
        bufferingGoal: 2,
      },
    }),
    [],
  );

  const onReady = () => {
    const player = playerRef.current;
    if (!player) return;
    const availableQualities = player
      .getVariantTracks()
      .sort((q1, q2) => q1.bandwidth - q2.bandwidth);
    const containerRect = thumbnailRef.current.getBoundingClientRect() || {};
    const containerWidth = containerRect.width;

    const handleLoad = () => {
      handlePlay();
      handlePause();
      const selectedQuality =
        availableQualities.find(quality => quality.width > containerWidth) || {};
      const bandwidth = selectedQuality.bandwidth || -1;
      player.selectQuality(bandwidth);

      player.removeEventListener('loaded', handleLoad);
    };

    player.addEventListener('loaded', handleLoad);
  };

  return (
    <Thumbnail
      data-testid="stream-thumbnail-preview"
      onMouseEnter={targetRef ? undefined : handlePlay}
      onMouseLeave={targetRef ? undefined : handlePause}
      ref={thumbnailRef}
    >
      <SingleViewPlayer
        ref={playerRef}
        src={src}
        poster={posterImg || undefined}
        muted
        autoPlay={autoPlay}
        onReady={onReady}
        configuration={configuration}
      />
      {posterImg && (
        <PosterOverlay
          data-testid="video-poster-overlay"
          src={posterImg}
          visible={!isHovering || !src}
          alt={alt}
        />
      )}
    </Thumbnail>
  );
};

StreamThumbnailPreview.propTypes = {
  src: PropTypes.string,
  thumbnail: PropTypes.string,
  alt: PropTypes.string,
  targetRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  autoPlay: PropTypes.bool,
};

export default StreamThumbnailPreview;
