import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Typography from 'znipe-elements/general/Typography/Typography';
import colors from 'znipe-styles/colors';
import Line from './Line';
import { Container } from './Hint.styles';

const heightMap = {
  xshort: 17,
  short: 31,
  medium: 84,
  tall: 152,
  xtall: 319,
};

const MAX_RETRY_ATTEMPTS = 10;
const RETRY_TIME = 500;

const Hint = ({
  label,
  description,
  direction,
  size,
  height,
  position = 'top',
  positionOffset = 10,
  hintContainer,
}) => {
  const [parent, setParent] = useState(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    let interval = null;
    let attempts = 0;
    const setupHintElement = () => {
      const parentContainerClassname = 'hint-parent-container';
      const hintRoot = document.querySelector(hintContainer);

      if (!hintRoot) {
        attempts += 1;
        if (!interval) interval = setInterval(setupHintElement, RETRY_TIME);
        else if (attempts > MAX_RETRY_ATTEMPTS) clearInterval(interval);
        return;
      }
      let hintParent = hintRoot.querySelector(`.${parentContainerClassname}`);
      if (!hintParent) {
        hintParent = document.createElement('div');
        hintParent.style.cssText = 'position: relative; z-index: 4;';
        hintParent.className = parentContainerClassname;
        hintRoot.dataset.hasHint = 'true';
        hintRoot.appendChild(hintParent);
      }
      setParent(hintParent);
      clearInterval(interval);
    };

    setupHintElement();

    return () => clearInterval(interval);
    // Only run this effect once
  }, []);

  if (!parent) return null;
  return createPortal(
    <Container $position={position} $direction={direction} $positionOffset={positionOffset}>
      <Typography type={size === 'small' ? 'title-s' : 'title-l'} color={colors.white}>
        {label}
      </Typography>
      <Typography type={size === 'large' ? 'paragraph-m' : 'paragraph-s'} color={colors.grey75}>
        {description}
      </Typography>
      <Line direction={direction} position={position} height={heightMap[height]} />
    </Container>,
    parent,
  );
};

Hint.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  height: PropTypes.oneOf(['xshort', 'short', 'medium', 'tall', 'xtall']).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  position: PropTypes.oneOf(['top', 'bottom']),
  positionOffset: PropTypes.number,
  hintContainer: PropTypes.string.isRequired,
};

export default Hint;
