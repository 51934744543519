import { styled, css } from 'styled-components';
import { SMALL } from './InputField.constants';

export const Container = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 48px;
`;

const transparentInputCss = css`
  padding-left: 0;
  background: transparent;
  border: 1px solid transparent;
  &:focus {
    border: 1px solid transparent;
    outline: 1px solid transparent;
  }
`;

const removeSearchInputDefaultsCss = css`
  /* clears the ‘X’ from Internet Explorer */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

export const Input = styled.input.attrs(() => ({
  type: 'search',
}))`
  margin: 0;
  padding: ${({ size }) => (size === SMALL ? '14px' : '12px')} 16px;
  padding-right: calc(16px * 2 + ${({ size }) => (size === SMALL ? '16px' : '24px')});
  background: ${({ theme }) => theme.primaryBackgroundColor};
  color: ${({ theme, isOnFocus }) => (isOnFocus ? theme.textPrimary : theme.secondaryTextColor)};
  border: 1px solid ${({ theme }) => theme.containerStroke};
  border-radius: 8px;
  width: 100%;
  font-size: ${({ size }) => (size === SMALL ? '14px' : '16px')};
  line-height: ${({ size }) => (size === SMALL ? '20px' : '24px')};
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  caret-color: ${({ theme }) => theme.UIAccent};
  ::placeholder {
    color: ${({ theme }) => theme.placeholderText};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.UIAccent};
    outline: 1px solid transparent;
  }
  ${({ noBackground }) => noBackground && transparentInputCss}
  ${removeSearchInputDefaultsCss}
`;

export const IconWrapper = styled.div`
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-right: 16px;
  display: flex;
  height: 24px;
  align-items: center;
  color: ${({ theme, isOnFocus }) => (isOnFocus ? theme.textPrimary : theme.secondaryTextColor)};
  &:focus,
  &:hover {
    cursor: pointer;
  }
`;
