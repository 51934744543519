import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Consumer } from './context';

export const BaseModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
`;

const StyledModal = ({
  WrapperComponent,
  children,
  onEscapeKeydown,
  allowScroll = false,
  beforeOpen,
  afterOpen,
  beforeClose,
  afterClose,
  backgroundProps = {},
  isOpen: isOpenProp = false,
  ...rest
}) => {
  const prevBodyOverflowStyle = useRef(null);
  const isMounted = useRef(false);

  const [isOpen, setIsOpen] = useState(false);

  // Handle changing isOpen state and *before* isOpen change callbacks
  useEffect(() => {
    const handleChange = callback => {
      if (callback) {
        try {
          callback().then(() => setIsOpen(isOpenProp));
        } catch (_e) {
          setIsOpen(isOpenProp);
        }
      } else {
        setIsOpen(isOpenProp);
      }
    };

    if (isOpen !== isOpenProp) {
      if (isOpenProp) {
        handleChange(beforeOpen);
      } else {
        handleChange(beforeClose);
      }
    }
  }, [isOpen, isOpenProp, beforeOpen, beforeClose]);

  // Handle *after* isOpen change callbacks
  useEffect(() => {
    if (isOpen && afterOpen) {
      afterOpen();
    } else if (afterClose) {
      // The isMounted bit prevents the afterClose from getting called
      // on the initial mount
      if (isMounted.current) afterClose();
    }
  }, [isOpen, afterOpen, afterClose]);

  // Handle Escape keydown
  useEffect(() => {
    const handleKeydown = e => {
      if (e.key === 'Escape' && onEscapeKeydown) onEscapeKeydown(e);
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeydown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen, onEscapeKeydown]);

  // Handle changing document.body styles based on isOpen state
  useEffect(() => {
    if (isOpen && !allowScroll) {
      prevBodyOverflowStyle.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (!allowScroll) {
        document.body.style.overflow = prevBodyOverflowStyle.current || '';
      }
    };
  }, [isOpen, allowScroll]);

  // Keep track of whether the modal is mounted to prevent misfiring callbacks
  useEffect(() => {
    isMounted.current = true;
  }, []);

  let content;
  if (WrapperComponent) {
    content = <WrapperComponent {...rest}>{children}</WrapperComponent>;
  } else {
    content = children;
  }

  return (
    <Consumer>
      {({ modalNode, BackgroundComponent }) => {
        if (modalNode && BackgroundComponent && isOpen) {
          return ReactDOM.createPortal(
            <BackgroundComponent {...backgroundProps}>{content}</BackgroundComponent>,
            modalNode,
          );
        }
        return null;
      }}
    </Consumer>
  );
};

StyledModal.styled = (...args) => {
  const wrap = args ? styled.div(...args) : styled.div();

  return props => <StyledModal WrapperComponent={wrap} {...props} />;
};

StyledModal.propTypes = {
  WrapperComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onEscapeKeydown: PropTypes.func,
  allowScroll: PropTypes.bool,
  beforeOpen: PropTypes.func,
  afterOpen: PropTypes.func,
  beforeClose: PropTypes.func,
  afterClose: PropTypes.func,
  backgroundProps: PropTypes.shape({}),
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

export default StyledModal;
