import { styled, css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';

const withHeader = () => css`
  grid-template-rows: ${props => (props.headerHeight ? `${props.headerHeight}px` : '29px')} auto;
`;

const withLabels = () => css`
  grid-template-rows: auto;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns.length}, 1fr);
  height: 100%;
  ${props => !props.withLabels && withHeader}
  ${props => props.withLabels && withLabels}
  ${({ widerGap }) =>
    widerGap &&
    css`
      grid-gap: 0 10px;
    `}
`;

export const ColumnHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.secondaryTextColor};
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  line-height: 16px;
  padding: 0 2px;
`;

export const Label = styled.div`
  color: ${props => props.theme.secondaryTextColor};
  font-style: normal;
  font-weight: 600;
  font-size: ${({ fluidFontSizes }) => (fluidFontSizes ? '14px' : '12px')};
  line-height: ${({ fluidFontSizes }) => (fluidFontSizes ? '24px' : '20px')};
  @media ${device.tablet} {
    font-size: ${({ fluidFontSizes }) => (fluidFontSizes ? '14px' : '12px')};
    line-height: ${({ fluidFontSizes }) => (fluidFontSizes ? '24px' : '16px')};
  }
`;

export const GridCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: ${({ fluidFontSizes }) => (fluidFontSizes ? '24px' : '16px')};
  @media ${device.mobilePortrait} {
    font-size: ${({ fluidFontSizes }) => (fluidFontSizes ? '20px' : '15px')};
    line-height: ${({ fluidFontSizes }) => (fluidFontSizes ? '25px' : '20px')};
  }
  @media ${device.tablet} {
    font-size: ${({ fluidFontSizes }) => (fluidFontSizes ? '20px' : '14px')};
    line-height: ${({ fluidFontSizes }) => (fluidFontSizes ? '25px' : '16px')};
  }

  border-bottom: ${props =>
    props.showBorder && props.borderType === 'horizontal'
      ? `1px solid ${props.theme.borderColor}`
      : 'none'};

  border-right: ${props =>
    props.showBorder && props.borderType === 'vertical'
      ? `1px solid ${props.theme.borderColor}`
      : 'none'};
`;
