import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';
import isEqual from 'lodash.isequal';
import { useNavigate, useParams } from 'react-router-dom';
import Section from 'tv-elements/layout/Section/Section';
import FeaturedMatchItem from 'tv-modules/Featured/FeaturedMatchItem/FeaturedMatchItem';
import FeaturedHighlightItem from 'tv-modules/Featured/FeaturedHighlightItem/FeaturedHighlightItem';
import {
  TabsetWrapper,
  BodyWrapper,
  FeaturedWrapper,
} from 'tv-elements/layout/PageLayout/PageLayout';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import usePrefersReducedMotion from 'znipe-hooks/usePrefersReducedMotion';
import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import Tabset from 'znipe-elements/navigation/Tabset/Tabset';
import HighlightClip from 'tv-modules/HighlightsListing/HighlightClip/HighlightClip';
// import HighlightPlaylist from 'tv-modules/HighlightsListing/HighlightPlaylist/HighlightPlaylist';
import VODItem from 'tv-modules/VODListing/VODItem/VODItem';
import ScheduleMatchItem from 'tv-modules/Schedule/MatchItem/MatchItem';
import LoadingItemWithVideoPreview from 'tv-modules/Featured/ItemWithVideoPreview/ItemWithVideoPreview.loading';
import { GRID, LIST, SCROLLLIST } from 'tv-elements/layout/Section/Section.constants';
import FullScheduleButton from 'tv-modules/Schedule/FullScheduleButton/FullScheduleButton';
import { useIsMobile } from 'tv-selectors/deviceInfo/makeGetIsMobile';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetIsDesktopExtraLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopExtraLargeOrGreater';
import { useIsNative } from 'tv-selectors/deviceInfo/makeGetIsNative';
import usePageRootColor from 'tv-hooks/usePageRootColor';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import Typography from 'znipe-elements/general/Typography/Typography';
import { PanelWrapper, PanelOverlay } from 'znipe-elements/layout/MenuPanel/MenuPanel.styles';
import useFetchVideoCredentials from 'tv-hooks/useFetchVideoCredentials';
import useGroupMatchesByDate, { ASC } from 'tv-hooks/useGroupMatchesByDate';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useGetScheduledMatches } from 'tv-selectors/page/makeGetScheduledMatches';
import { useGetHighlights } from 'tv-selectors/page/makeGetHighlights';
// import { useGetPlaylists } from 'tv-selectors/page/makeGetPlaylists';
import { useGetLatestMatches } from 'tv-selectors/page/makeGetLatestMatches';
import { useGetFeaturedContents } from 'tv-selectors/page/makeGetFeaturedContents';
import { useGetMatchPreviews } from 'tv-selectors/matchPreviews/makeGetMatchPreviews';
import { useGetEditorials } from 'tv-selectors/page/makeGetEditorials';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import Sidebar from 'tv-modules/MyFeed/Sidebar/Sidebar';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { useGetFeaturedContentsMap } from 'tv-selectors/featured/makeGetFeaturedContentsMap';
import { rootSchema } from 'tv-schema/rootSchema.normalizr';
import { contentSubscriptionSchema } from 'tv-schema/contentSubscriptions.normalizr';
// import NoResults from 'tv-elements/data-display/NoResults/NoResults';
import PremiumPromoSection from 'tv-modules/Premium/PremiumPromoSection/PremiumPromoSection';
import IntroBanner from 'tv-modules/Onboarding/IntroBanner/IntroBanner';
import { useGetAllContentSubscriptionIds } from 'tv-selectors/contentSubscriptions/makeGetAllContentSubscriptionIds';
import usePackageName from 'tv-hooks/usePackageName';
import PlayerResultItem from 'tv-routes/Browse/components/PlayerResultItem';
import Spotlight from 'tv-modules/Spotlight/Spotlight';
import TeamResultItem from 'tv-routes/Browse/components/TeamResultItem';
import { useGetSpotlightTeamIds } from 'tv-selectors/page/makeGetSpotlightTeamIds';
import { useGetSpotlightTeams } from 'tv-selectors/team/makeGetSpotlightTeams';
import { useGetSpotlightChampionIds } from 'tv-selectors/page/makeGetSpotlightChampionIds';
import { useGetSpotlightChampions } from 'tv-selectors/champions/makeGetSpotlightChampions';
import { useGetSpotlightPlayerIds } from 'tv-selectors/page/makeGetSpotlightPlayerIds';
import { useGetSpotlightPlayers } from 'tv-selectors/players/makeGetSpotlightPlayers';
import { useIsAuthenticated } from 'tv-selectors/auth/makeGetIsAuthenticated';
import { useShouldShowOnboardingStep } from 'tv-selectors/auth/makeGetShouldShowOnboardingStep';
import ChampionResultItem from 'tv-routes/Browse/components/ChampionResultItem';
import getRandomCursor from 'znipe-gql/utils/getRandomCursor';
import { LOL_PATCH_VERSION } from 'znipe-constants/misc';
import { HOME_PAGE } from 'tv-constants/pageTypes';
import NotSingedIn from 'tv-modules/MyFeed/NotSignedIn/NotSignedIn';
import PageWelcome from 'tv-modules/Onboarding/PageWelcome/PageWelcome';
import useSetPlayedStatus from 'tv-modules/Onboarding/hooks/useSetPlayedStatus';
import MyFeedOnboarding from 'tv-modules/Onboarding/MyFeed/MyFeed';
import usePackageIncludesGame from 'tv-hooks/usePackageIncludesGame';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import usePageBanner from './hooks/usePageBanner';
import {
  ScrollWrapper,
  ScheduleItemStack,
  ScrollItemsFlex,
  SpaceDiv,
  ButtonWrapper,
  ScheduledMatchListContainer,
  FullScheduleButtonWrapper,
  EmptyStateWrapper,
} from './Home.styles';
import { HomepageQuery, HomepageWithSubsQuery } from './Home.queries';
import {
  singletonPadding,
  desktopExtraLargeSizes,
  desktopLargeSizes,
  desktopSizes,
  tabletSizes,
  mobileSizes,
  DISCOVER,
  MY_FEED,
  EDIT_TEXT,
  HOME_WELCOME_STEP_ID,
  hints,
  MY_FEED_TARGET_ID,
} from './Home.constants';
import theme from './Home.theme';

const singletonLoadingComp = <LoadingSpinner fillColor="white" position="relative" />;
const noContentSingleton = <NoResults type="myfeed" padding={singletonPadding} />;
const notSignedInSingleton = <NotSingedIn />;

const AnimatedPanelWrapper = animated(PanelWrapper);
const AnimatedPanelOverlay = animated(PanelOverlay);

// const singletonPlaylistsComp = <NoResults hideHeader type="playlists" />;

// INFO: These are left overs from the custom normalize callback
// When the API is updated to support better filtering, this can be removed
const unusedEntities = [
  'playerDetails',
  'contentSubscription',
  'players',
  'teams',
  'globalStreams',
  'games',
  'streamUrls',
  'streams',
  'highlightEdges',
  'teamDetails',
  'matchEdges',
  'data',
  'roster',
  'players',
];

export const postSubscriptionsNormalize = (flatData = {}) => {
  const partnerKey = Object.keys(flatData?.data)?.[0] || '';
  const result = { ...flatData?.data?.[partnerKey] } || {};
  Object.keys(flatData).forEach(item => {
    if (unusedEntities.includes(item)) return;
    result[item] = [...new Set(Object.keys(flatData[item]))];
  });
  if (!result.scheduledMatches) {
    result.scheduledMatches = [];
  }
  return result;
};

const Home = () => {
  const isMobile = useIsMobile();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const isDesktopExtraLargeOrGreater = useGetIsDesktopExtraLargeOrGreater();
  const isNative = useIsNative();
  const palette = usePageRootColor();
  const { matches: plushPurple, standings: cottonCandy, highlights: peachParty } = palette;
  const { packageSlug } = useParams();
  const packageName = usePackageName();
  const navigate = useNavigate();

  const [view, setView] = useState(DISCOVER);
  const PAGE_TYPE = view === DISCOVER ? HOME_PAGE : MY_FEED;
  const [isFollowingPanelOpen, setIsFollowingPanelOpen] = useState(false);
  const [showFollowingPanel, setShowFollowingPanel] = useState(false);
  const allContentSubscriptions = useGetAllContentSubscriptionIds();
  const userHasContentSubscriptions = useMemo(
    () => !!allContentSubscriptions.length,
    [allContentSubscriptions],
  );
  const spotlightTeamsIds = useGetSpotlightTeamIds({ pageType: PAGE_TYPE });
  const isAuthenticated = useIsAuthenticated();
  const spotlightTeams = useGetSpotlightTeams({ ids: spotlightTeamsIds });
  const spotlightChampionIds = useGetSpotlightChampionIds({ pageType: PAGE_TYPE });
  const spotlightChampions = useGetSpotlightChampions({ ids: spotlightChampionIds });
  const spotlightPlayerIds = useGetSpotlightPlayerIds({ pageType: PAGE_TYPE });
  const spotlightPlayers = useGetSpotlightPlayers({ ids: spotlightPlayerIds });
  const showHomeWelcome = useShouldShowOnboardingStep({ stepId: HOME_WELCOME_STEP_ID });
  const setHomeWelcomePlayed = useSetPlayedStatus(HOME_WELCOME_STEP_ID);

  const showPageContent = view === DISCOVER || (view === MY_FEED && userHasContentSubscriptions);
  const currentUserId = useUserId();

  const randomCursor = useMemo(() => getRandomCursor(20), []);

  const baseUrl = useMemo(() => (packageSlug ? `/${packageSlug}` : ''), [packageSlug]);
  const handleTabClick = useCallback(
    nextView => {
      /* const nextPath = nextView === MY_FEED ? `${baseUrl}/home/${ME}` : `${baseUrl}/home`;
      navigate(nextPath); */
      setHomeWelcomePlayed(true);
      setView(nextView?.label);
    },
    [setHomeWelcomePlayed],
  );

  const handleEditClick = useCallback(() => setIsFollowingPanelOpen(true), []);
  const handlePanelClose = useCallback(() => setIsFollowingPanelOpen(false), []);
  const panelRef = useOutsideClick(handlePanelClose);

  useEffect(() => () => setHomeWelcomePlayed(true), [setHomeWelcomePlayed]);

  useEffect(() => {
    if (!isFollowingPanelOpen) setTimeout(() => setShowFollowingPanel(false), 200);
    return setShowFollowingPanel(true);
  }, [isFollowingPanelOpen]);

  const prefersReducedMotion = usePrefersReducedMotion();

  const animationPanel = useSpring({
    transform: `translateX(${isFollowingPanelOpen ? 0 : 100}%)`,
    config: { tension: 800, friction: 60 },
    immediate: prefersReducedMotion,
  });

  const animationOverlay = useSpring({
    opacity: isFollowingPanelOpen ? '70%' : '0%',
    config: { duration: 200 },
    immediate: prefersReducedMotion,
  });

  /*   useEffect(() => {
    const selectedTab = initialTab === ME ? MY_FEED : DISCOVER;
    setView(selectedTab);
  }, [initialTab]); */

  const matchPreview = useGetMatchPreviews();
  const featuredContentMap = useGetFeaturedContentsMap();
  const scheduledMatches = useGetScheduledMatches({ pageType: PAGE_TYPE, limit: 3 });
  const highlights = useGetHighlights({ pageType: PAGE_TYPE }); // replace in page.highlights
  // const playlists = useGetPlaylists({ pageType: PAGE_TYPE });
  const allLatestMatches = useGetLatestMatches({ pageType: PAGE_TYPE, limit: 3 });
  const editorialVideos = useGetEditorials({ pageType: PAGE_TYPE });
  const featuredContent = useGetFeaturedContents({
    pageType: PAGE_TYPE,
  });
  const { home: homeTheme } = useThemeContext();

  const [headlineContent, contentOfTheWeek] = useMemo(
    () =>
      Object.keys(featuredContentMap).map(id => ({
        id: featuredContentMap[id].id,
        type: featuredContentMap[id].type,
      })),
    [featuredContentMap],
  );

  const elementSizes = useMemo(() => {
    if (isDesktopExtraLargeOrGreater) {
      return desktopExtraLargeSizes;
    }
    if (isDesktopLargeOrGreater) {
      return desktopLargeSizes;
    }
    if (isDesktopOrGreater) {
      return desktopSizes;
    }
    if (isTabletOrGreater) {
      return tabletSizes;
    }
    return mobileSizes;
  }, [
    isDesktopExtraLargeOrGreater,
    isDesktopLargeOrGreater,
    isDesktopOrGreater,
    isTabletOrGreater,
  ]);

  const headlineContentModule = useMemo(() => {
    if (!headlineContent) return null;
    if (headlineContent.type === 'Vod') {
      return (
        <FeaturedMatchItem
          size={elementSizes.featuredMatch}
          featuredId={headlineContent?.id}
          autoPlay
        />
      );
    }
    if (headlineContent.type === 'Highlight') {
      return (
        <FeaturedHighlightItem
          size={elementSizes.featuredHighlight}
          featuredId={headlineContent?.id}
          autoPlay
        />
      );
    }
    return null;
  }, [elementSizes, headlineContent]);

  const prevRequestedCredentials = useRef({});
  // biome-ignore lint/correctness/useExhaustiveDependencies: @DEP_CHECK: Check what is needed here
  const requestedCredentials = useMemo(() => {
    const featuredMatches = [
      ...(featuredContentMap[headlineContent?.id]?.matches || []),
      ...(featuredContentMap[contentOfTheWeek?.id]?.matches || []),
    ];
    const matches = [...scheduledMatches, ...allLatestMatches, ...featuredMatches];
    const credentialsToRequest = {
      matches: matches.reduce((acc, matchId) => {
        const previewStreamId = matchPreview[matchId];
        if (previewStreamId) {
          acc.push({ id: matchId, streams: [previewStreamId] });
        }
        return acc;
      }, []),
      videos: [...editorialVideos, ...highlights].reduce((acc, videoId) => {
        acc.push({ id: videoId });
        return acc;
      }, []),
    };
    if (isEqual(prevRequestedCredentials.current, credentialsToRequest))
      return prevRequestedCredentials.current;

    prevRequestedCredentials.current = credentialsToRequest;

    return credentialsToRequest;
  }, [scheduledMatches, allLatestMatches, featuredContent, highlights]);

  useFetchVideoCredentials(requestedCredentials);

  const maxItemsCount = useMemo(() => {
    if (isDesktopExtraLargeOrGreater) return 7;
    if (isDesktopLargeOrGreater) return 6;
    if (isDesktopOrGreater) return 4;
    return -1;
  }, [isDesktopOrGreater, isDesktopLargeOrGreater, isDesktopExtraLargeOrGreater]);

  const groupedScheduledMatches = useGroupMatchesByDate(
    scheduledMatches,
    ASC,
    undefined,
    maxItemsCount,
  );

  const hasFullScheduleButton = useMemo(
    () => scheduledMatches.length > maxItemsCount,
    [scheduledMatches.length, maxItemsCount],
  );

  const links = useMemo(
    () => ({
      highlights: `${baseUrl}/browse/highlights`,
      schedule: `${baseUrl}/matches`,
      latestMatches: `${baseUrl}/matches/vods`,
      allPlayers: `${baseUrl}/browse/players`,
      allTeams: `${baseUrl}/browse/teams`,
      allChampions: `${baseUrl}/browse/champions`,
      editorials: `${baseUrl}/browse/editorials`,
    }),
    [baseUrl],
  );

  const { numberOfVisibleHighlights, numberOfEditorials } = elementSizes;

  const highlightsLimit = view === DISCOVER ? numberOfVisibleHighlights : 1;

  const packageIncludesLol = usePackageIncludesGame('lol', packageSlug);

  const queryOptions = useMemo(
    () => ({
      variables: {
        package: packageName,
        tag: 'homePage',
        sortType: 'DESC',
        // playlistLimit: numberOfVisiblePlaylists,
        scheduledMatchesLimit: 10,
        editorialsLimit: numberOfEditorials,
        highlightsLimit: parseInt(highlightsLimit, 10),
        nextPage: '',
        patch: LOL_PATCH_VERSION,
        randomCursor,
        includeChampions: packageIncludesLol,
      },
    }),
    [highlightsLimit, numberOfEditorials, packageName, randomCursor, packageIncludesLol],
  );

  const myFeedQueryOptions = useMemo(
    () => ({
      variables: {
        ...queryOptions.variables,
        userId: currentUserId,
      },
      persisted: false,
    }),
    [queryOptions.variables, currentUserId],
  );

  const QUERY = useMemo(() => (view === DISCOVER ? HomepageQuery : HomepageWithSubsQuery), [view]);
  const schema = useMemo(
    () => (view === DISCOVER ? rootSchema : contentSubscriptionSchema),
    [view],
  );
  const options = useMemo(
    () => (view === DISCOVER ? queryOptions : myFeedQueryOptions),
    [myFeedQueryOptions, queryOptions, view],
  );
  const pageName = useMemo(() => (view === MY_FEED ? MY_FEED : undefined), [view]);
  const postProcessFunc = useMemo(
    () => (view === DISCOVER ? undefined : postSubscriptionsNormalize),
    [view],
  );

  const { refetch, loading } = useReduxGqlQuery(
    QUERY,
    options,
    {},
    schema,
    postProcessFunc,
    pageName,
    view === MY_FEED,
  );

  const sortedScheduledMatches = useMemo(
    () =>
      Object.keys(groupedScheduledMatches)
        ?.filter(item => !!groupedScheduledMatches[item]?.length)
        ?.map(date => (
          <ScheduleItemStack key={date}>
            <Typography type={elementSizes.typeSize}>{date}</Typography>
            <ScrollItemsFlex>
              {groupedScheduledMatches[date]?.map(({ id }) => (
                <ScheduleMatchItem key={id} matchId={id} size="xsmall" />
              ))}
            </ScrollItemsFlex>
          </ScheduleItemStack>
        )),
    [elementSizes.typeSize, groupedScheduledMatches],
  );

  const scheduledMatchListContent = useMemo(() => {
    if (sortedScheduledMatches.length === 0) return [];

    return (
      <>
        {sortedScheduledMatches}
        {hasFullScheduleButton && (
          <FullScheduleButtonWrapper>
            <SpaceDiv />
            <FullScheduleButton key="FullScheduleButton" link={links.schedule} />
          </FullScheduleButtonWrapper>
        )}
      </>
    );
  }, [links.schedule, sortedScheduledMatches, hasFullScheduleButton]);

  const scheduledMatchList = useMemo(
    () =>
      isDesktopOrGreater ? (
        <ScheduledMatchListContainer>{scheduledMatchListContent}</ScheduledMatchListContainer>
      ) : (
        <ScrollContainer vertical={false}>
          <ScrollWrapper>{scheduledMatchListContent}</ScrollWrapper>
        </ScrollContainer>
      ),
    [scheduledMatchListContent, isDesktopOrGreater],
  );

  const latestMatches = useMemo(() => {
    if (!allLatestMatches?.length && loading) return singletonLoadingComp;
    if (!allLatestMatches?.length && !loading) return noContentSingleton;
    return allLatestMatches?.map(id => (
      <VODItem key={id} matchId={id} size={elementSizes.vodItem} />
    ));
  }, [allLatestMatches, elementSizes.vodItem, loading]);

  const highlightVideos = useMemo(() => {
    if (!highlights?.length && loading) return singletonLoadingComp;
    if (!highlights?.length && !loading) return noContentSingleton;
    return highlights
      ?.slice(0, numberOfVisibleHighlights)
      .map((id, index) => (
        <HighlightClip
          key={id}
          highlightId={id}
          type="tile"
          size={elementSizes.highlightClip}
          index={index}
        />
      ));
  }, [highlights, loading, numberOfVisibleHighlights, elementSizes.highlightClip]);

  const allEditorialVideos = useMemo(() => {
    if (!editorialVideos?.length && loading) return singletonLoadingComp;
    if (!editorialVideos?.length && !loading) return noContentSingleton;
    return editorialVideos.map(id => (
      <HighlightClip
        key={id}
        highlightId={id}
        type="tile"
        size={elementSizes.highlightClip}
        videoType="editorial"
      />
    ));
  }, [editorialVideos, loading, elementSizes.highlightClip]);

  const playersSpotlightCards = useMemo(() => {
    if (!spotlightPlayers?.length && loading) return singletonLoadingComp;
    if (!spotlightPlayers?.length && !loading) return noContentSingleton;
    return spotlightPlayers.map(({ id }) => (
      <PlayerResultItem
        key={id}
        playerId={id}
        shouldUsePlayerCard
        isDesktopOrGreater={isDesktopOrGreater}
        elementSizes={elementSizes}
      />
    ));
  }, [elementSizes, isDesktopOrGreater, spotlightPlayers, loading]);

  const teamsSpotlightCards = useMemo(() => {
    if (!spotlightTeams?.length && loading) return singletonLoadingComp;
    if (!spotlightTeams?.length && !loading) return noContentSingleton;
    return spotlightTeams.map(({ id }) => (
      <TeamResultItem key={id} teamId={id} isDesktopOrGreater elementSizes={elementSizes} />
    ));
  }, [elementSizes, spotlightTeams, loading]);

  const championsSpotlightCards = useMemo(() => {
    if (!spotlightChampions?.length && loading) return singletonLoadingComp;
    if (!packageIncludesLol) return noContentSingleton;
    if (!spotlightChampions?.length && !loading) return noContentSingleton;
    const max = isDesktopLargeOrGreater ? 6 : 5;
    return spotlightChampions
      ?.slice(0, max)
      ?.map(({ champId }) => (
        <ChampionResultItem
          key={champId}
          championId={champId}
          isDesktopOrGreater
          elementSizes={elementSizes}
        />
      ));
  }, [elementSizes, isDesktopLargeOrGreater, spotlightChampions, loading, packageIncludesLol]);

  // const allPlaylists = useMemo(() => {
  //   if (!playlists?.length) return singletonPlaylistsComp;
  //   return playlists?.map(id => (
  //     <HighlightPlaylist key={id} playlistId={id} size={elementSizes.playlist} />
  //   ));
  // }, [playlists, elementSizes.playlist]);

  const MyFeedEdit = useMemo(
    () => (
      <Button
        variant="secondary"
        onClick={handleEditClick}
        size="xsmall"
        icon="pencil"
        iconPosition="right"
      >
        <Typography type="title-xs">{EDIT_TEXT}</Typography>
      </Button>
    ),
    [handleEditClick],
  );

  const feedBanner = usePageBanner(MY_FEED_TARGET_ID);

  const { useOverlayTitles } = homeTheme;

  return (
    <>
      <FeaturedWrapper>
        {!isNative && (
          <TabsetWrapper>
            <Tabset
              activeTab={view}
              size={isDesktopOrGreater ? 'medium' : 'small'}
              tabs={[
                { label: DISCOVER },
                { label: MY_FEED, 'data-onboarding-id': MY_FEED_TARGET_ID },
              ]}
              alignment="left"
              onClickTab={handleTabClick}
              isMobile={isMobile}
            />
            {view === MY_FEED && isAuthenticated && <ButtonWrapper>{MyFeedEdit}</ButtonWrapper>}
          </TabsetWrapper>
        )}
        {showHomeWelcome && isDesktopOrGreater && (
          <PageWelcome
            hints={hints}
            headline={homeTheme[HOME_WELCOME_STEP_ID].headline}
            subHeadline={homeTheme[HOME_WELCOME_STEP_ID].subHeadline}
            buttonLabel={homeTheme[HOME_WELCOME_STEP_ID].buttonLabel}
            backgroundImage={homeTheme[HOME_WELCOME_STEP_ID].backgroundImage}
            stepId={HOME_WELCOME_STEP_ID}
            fixedPosition
          />
        )}
        {view === DISCOVER && feedBanner.isEnabled && (
          <IntroBanner
            targetId={feedBanner.id}
            coverImage={feedBanner.coverImage}
            title={feedBanner.title}
            text={feedBanner.text}
            isEnabled={feedBanner.isEnabled}
          />
        )}
        {showPageContent && (
          <>
            {view === DISCOVER && loading && (
              <LoadingItemWithVideoPreview
                size={elementSizes.featuredMatch}
                orientation={elementSizes.featuredMatch === 'small' ? 'vertical' : 'horizontal'}
              />
            )}
            {view === DISCOVER && !loading && headlineContentModule}
            <div>
              <Section direction="horizontal" fullWidth type={SCROLLLIST} noTopMargin>
                {scheduledMatchList}
              </Section>
            </div>
          </>
        )}
      </FeaturedWrapper>
      <BodyWrapper>
        {showPageContent && (
          <>
            {view === DISCOVER && (
              <>
                <Spotlight
                  title={useOverlayTitles ? '' : 'Player spotlight'}
                  backgroundTitle={useOverlayTitles ? 'player' : ''}
                  foregroundTitle={useOverlayTitles ? 'spotlight' : ''}
                  link={links.allPlayers}
                  type="players"
                  noContent={!spotlightPlayerIds?.length}
                >
                  {playersSpotlightCards}
                </Spotlight>
                <Section
                  fullWidth
                  title={useOverlayTitles ? '' : 'Latest editorials'}
                  backgroundTitle={useOverlayTitles ? 'latest' : ''}
                  foregroundTitle={useOverlayTitles ? 'editorials' : ''}
                  type={GRID}
                  link={links.editorials}
                >
                  {allEditorialVideos}
                </Section>
                <Spotlight
                  title={useOverlayTitles ? '' : 'Teams on fire'}
                  backgroundTitle={useOverlayTitles ? 'teams' : ''}
                  foregroundTitle={useOverlayTitles ? 'on fire' : ''}
                  link={links.allTeams}
                  type="teams"
                  noContent={!spotlightTeamsIds?.length}
                >
                  {teamsSpotlightCards}
                </Spotlight>
              </>
            )}
            <Section
              fullWidth
              title={useOverlayTitles ? '' : 'Gripping moments'}
              backgroundTitle={useOverlayTitles ? 'gripping' : ''}
              foregroundTitle={useOverlayTitles ? 'moments' : ''}
              titleColor={peachParty}
              type={GRID}
              link={links.highlights}
            >
              {highlightVideos}
            </Section>
            <Section fullWidth type={LIST} showAllLink={false}>
              <PremiumPromoSection type="splitType" />
            </Section>
            {view === DISCOVER && packageIncludesLol && (
              <Spotlight
                title={useOverlayTitles ? '' : 'Champions spotlight'}
                backgroundTitle={useOverlayTitles ? 'champions' : ''}
                foregroundTitle={useOverlayTitles ? 'spotlight' : ''}
                link={links.allChampions}
                type="champions"
                noContent={!spotlightChampionIds?.length}
              >
                {championsSpotlightCards}
              </Spotlight>
            )}
            <Section
              type={LIST}
              title={useOverlayTitles ? '' : 'Latest matches'}
              backgroundTitle={useOverlayTitles ? 'latest' : ''}
              foregroundTitle={useOverlayTitles ? 'matches' : ''}
              titleColor={cottonCandy}
              link={links.latestMatches}
            >
              {latestMatches}
            </Section>
            {view === DISCOVER && (
              <Section
                fullWidth
                title={useOverlayTitles ? '' : 'Relive the thrill'}
                backgroundTitle={useOverlayTitles ? 'relive' : ''}
                foregroundTitle={useOverlayTitles ? 'the thrill' : ''}
                type={LIST}
                titleColor={plushPurple}
                showAllLink={false}
              >
                <FeaturedMatchItem
                  size={elementSizes.matchRerun}
                  featuredId={contentOfTheWeek?.id}
                />
              </Section>
            )}
            {/* {!!playlists?.length && view === DISCOVER && (
              <Section
                type={PLAYLISTGRID}
                backgroundTitle="hottest"
                foregroundTitle="lists"
                titleColor={peachParty}
                link={links.playlists}
              >
                {allPlaylists}
              </Section>
            )} */}
          </>
        )}
        {view === MY_FEED && !isAuthenticated && notSignedInSingleton}
        {view === MY_FEED &&
          isAuthenticated &&
          !userHasContentSubscriptions &&
          loading &&
          singletonLoadingComp}
        {view === MY_FEED && isAuthenticated && !userHasContentSubscriptions && !loading && (
          <EmptyStateWrapper>
            <MyFeedOnboarding
              headline={homeTheme.myFeedOnboarding.headline}
              text={homeTheme.myFeedOnboarding.text}
              onClick={() => navigate(`/${packageName}/browse`)}
            />
          </EmptyStateWrapper>
        )}
        {view === MY_FEED && isAuthenticated && showFollowingPanel && (
          <>
            <AnimatedPanelWrapper ref={panelRef} style={animationPanel}>
              <Sidebar
                onClick={handleEditClick}
                onClose={handlePanelClose}
                onMutationChange={refetch}
              />
            </AnimatedPanelWrapper>
            <AnimatedPanelOverlay style={animationOverlay} />
          </>
        )}
      </BodyWrapper>
    </>
  );
};

export default withTheme(Home, theme, 'home');
