import shaka from 'shaka-player';

const SEGMENT_LENGTH = 2;

const estimateLoadTime = (player: shaka.Player, useTTFB = false) => {
  const { estimatedBandwidth } = player.getStats();
  // @ts-ignore
  const combinedTTFB = useTTFB ? (shaka.net.NetworkingEngine.ttfb || 0) * 3 : 0; // Estimating for 3 requests, Manifest, playlist, segmentS
  const currentBitrate =
    player.getVariantTracks().find(track => track.active)?.bandwidth ?? 2500000;
  return estimatedBandwidth
    ? (SEGMENT_LENGTH * currentBitrate) / estimatedBandwidth + combinedTTFB
    : combinedTTFB;
};

export default estimateLoadTime;
