import { styled, css } from 'styled-components';

const SelectedStyles = css`
  background-color: ${({ theme }) => theme.containerStroke};
  border-right: ${({ isMultiSelect, theme }) =>
    isMultiSelect ? 'none' : `4px solid ${theme.UIAccent}`};
`;

export const Container = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  ${({ selected }) => selected && SelectedStyles};
  list-style: none;
  user-select: none;
  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:hover {
    ${({ theme, selected }) =>
      !selected &&
      css`
        background-color: ${theme.tertiaryBackgroundColor};
      `};
    cursor: pointer;
  }
  &:focus {
    outline: 1px solid ${({ theme }) => theme.tertiaryBackgroundColor};
    outline-offset: -6px;
    cursor: pointer;
  }
  &:focus:not(:focus-visible) {
    outline: 1px solid transparent;
  }
  p {
    margin: 0;
  }
`;

export const SelectIconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.UIAccent};
`;

export const ImageWrapper = styled.div`
  width: 32px;
  height: 24px;
  margin-right: 8px;
  img {
    margin-top: -4px;
  }
`;
