import { memo, useMemo } from 'react';
import { DefaultTheme } from 'styled-components';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import { singleWaveBackground, WavesBackground } from 'znipe-elements/general/Flourish/Waves/Waves';
import { EdgedWavesBackground } from 'znipe-elements/general/Flourish/EdgedWaves/EdgedWaves';
import {
  Container,
  ActiveBackgroundContainer,
  ActiveBackgroundWrapper,
  Content,
  theme,
} from './FlourishContainer.styles';

const flourishes = {
  edgedWaves: EdgedWavesBackground,
  waves: WavesBackground,
  singleWave: singleWaveBackground,
  none: undefined,
};

type FlourishType = 'edgedWaves' | 'waves' | 'singleWave' | 'none';

type FlourishContainerProps = {
  active?: boolean;
  children?: React.ReactNode;
  flourish?: FlourishType;
  animationDuration?: number;
  background?: string;
  activeBackground?: string;
  shouldPreserveAspectRatio?: boolean;
  containerMask?: boolean;
};

const FlourishContainer: React.FC<FlourishContainerProps> = ({
  active = true,
  children,
  flourish: propsFlourish = undefined,
  animationDuration = 0,
  background = undefined,
  activeBackground = background,
  shouldPreserveAspectRatio = true,
  containerMask = false,
}) => {
  const {
    flourishContainer: { flourish },
  } = useThemeContext() as DefaultTheme & { flourishContainer: { flourish: FlourishType } };
  const Flourish = useMemo(() => flourishes[propsFlourish || flourish], [flourish, propsFlourish]);
  const shouldAnimate = useMemo(() => animationDuration > 0, [animationDuration]);
  const useActiveContainer = useMemo(
    () =>
      shouldAnimate &&
      ((activeBackground && activeBackground.indexOf('gradient') > -1) ||
        (background && background.indexOf('gradient') > -1)),
    [shouldAnimate, activeBackground, background],
  );

  return (
    <Container
      data-testid="flourish-container"
      $active={active}
      $animationDuration={animationDuration}
      $background={background}
      $activeBackground={useActiveContainer ? undefined : activeBackground}
      $containerMask={containerMask}
    >
      {useActiveContainer && (
        <ActiveBackgroundWrapper>
          <ActiveBackgroundContainer
            data-testid="active-background"
            background={activeBackground}
            active={active}
            animationDuration={animationDuration}
          />
        </ActiveBackgroundWrapper>
      )}
      {Flourish && active && (
        <Flourish
          animate={shouldAnimate}
          animationDuration={animationDuration}
          shouldPreserveAspectRatio={shouldPreserveAspectRatio}
        />
      )}
      <Content data-testid="content">{children}</Content>
    </Container>
  );
};

export default memo(withTheme(FlourishContainer, theme, 'flourishContainer'));
