import React, { useState, useEffect, memo, useCallback } from 'react';
import { styled } from 'styled-components';
import Button, { iconSizes, Size, Variant } from 'znipe-elements/general/IconButtonV2/IconButton';
import { IconName } from 'znipe-elements/general/Icon/Icon';
import colors from 'znipe-styles/colors';
import { AnimatedProps } from '@react-spring/web';
import { ToolTipPlacement } from 'znipe-watch/src/contexts/ConfigurationContext';
import { PlacementTypes } from 'znipe-elements/feedback/ToolTip/ToolTip.types';
import type { ControlFeedbackPosition } from '../../../ControlFeedback/ControlFeedback';
import { useUiInfoStore } from '../../../../stores/uiInfo';

const tooltipPlacementMap: { [key: string]: PlacementTypes } = {
  over: 'top',
  under: 'bottom',
  left: 'left',
  right: 'right',
};

export const { white: iconColor } = colors;

const Tile = styled.div`
  background: ${({ theme }) => theme.containerStroke ?? '#484848'};
  border-radius: 5px;
  padding: 5px;
`;

type ControlButtonProps = {
  icon: IconName;
  size?: Size;
  color?: string;
  isActive?: boolean;
  toolTip?: string;
  tooltipPlacement?: ToolTipPlacement;
  onClick?: (type: string | undefined, identifier: string | undefined, e: React.MouseEvent) => void;
  identifier?: string;
  type?: string;
  tiled?: boolean;
  style?: AnimatedProps<object>;
  disabled?: boolean;
  iconStyle?: AnimatedProps<object>;
  variant?: Variant;
  feedbackPosition?: ControlFeedbackPosition;
};

const ControlButton: React.FC<ControlButtonProps> = ({
  icon,
  size = 'small',
  toolTip = '',
  color,
  tooltipPlacement = 'over',
  identifier,
  type,
  onClick,
  tiled = false,
  style,
  disabled = false,
  iconStyle,
  variant = 'ghost',
  feedbackPosition,
}) => {
  const [buttonIcon, setButtonIcon] = useState<IconName | undefined>(icon);
  const [iconIsImage, setIconIsImage] = useState(false);
  const uiInfoStore = useUiInfoStore();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const uiInfo = uiInfoStore?.getState();
      if (onClick) onClick(type, identifier, e);
      if (feedbackPosition && uiInfo?.triggerControlFeedback) {
        uiInfo.triggerControlFeedback(icon, feedbackPosition);
      }
      e.stopPropagation();
    },
    [onClick, type, identifier, feedbackPosition, uiInfoStore, icon],
  );

  useEffect(() => {
    if (icon?.indexOf('http') === 0) {
      setIconIsImage(true);
    }
    setButtonIcon(icon);
    return () => setButtonIcon(undefined);
  }, [icon]);

  const button = (
    <Button
      style={style}
      size={size}
      variant={variant}
      onClick={handleClick}
      icon={iconIsImage ? undefined : buttonIcon}
      iconColor={iconIsImage ? undefined : color}
      iconStyle={iconStyle}
      data-testid={`${icon}-control-button`}
      aria-label={toolTip || `${icon} button`}
      tooltipPlacement={tooltipPlacementMap[tooltipPlacement]}
      tooltip={toolTip}
      disabled={disabled}
    >
      {iconIsImage && (
        <img height={iconSizes[size]} width={iconSizes[size]} src={icon} alt="custom icon" />
      )}
    </Button>
  );

  if (!toolTip) return tiled ? <Tile>{button}</Tile> : button;

  return tiled ? <Tile>{button}</Tile> : button;
};

export default memo(ControlButton);
