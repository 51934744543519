import { styled, css } from 'styled-components';

export const AvatarsWrapper = styled.div<{
  $columnsPerRow?: number;
  $hasFeaturedAvatars?: boolean;
}>`
  display: grid;
  ${({ $columnsPerRow }) =>
    $columnsPerRow &&
    css`
      grid-template-columns: repeat(${$columnsPerRow}, 1fr);
    `}
  grid-gap: ${({ $hasFeaturedAvatars }) => ($hasFeaturedAvatars ? 8 : 4)}px;
`;

export const FeaturedWraper = styled.div`
  margin-bottom: 8px;
`;
