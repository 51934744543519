import { useRef, useCallback, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import gqlSelector from 'tv-hooks/useGqlSelector';

const useMemoizedGqlSelector = (makeSelector, props = {}) => {
  // INFO: only memo on first render
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const selector = useMemo(() => makeSelector(), []);
  const ref = useRef(props);

  if (!isEqual(props, ref.current)) {
    ref.current = props;
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to run it on ref value change
  const memoizedSelector = useCallback(
    state => selector(state, ref.current),

    [selector, ref.current],
  );

  return gqlSelector(memoizedSelector);
};

export default useMemoizedGqlSelector;
