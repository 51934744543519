import styled, { css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { inputStyles } from 'znipe-elements/data-entry/Input/Input.styles';

const stripeElementsStyles = css`
  ${inputStyles};
  cursor: text;
`;

export const StyledCardNumberElement = styled(CardNumberElement)`
  ${stripeElementsStyles}
`;
export const StyledCardCvcElement = styled(CardCvcElement)`
  ${stripeElementsStyles}
`;
export const StyledCardExpiryElement = styled(CardExpiryElement)`
  ${stripeElementsStyles}
`;

export const Form = styled.form`
  width: 100%;
  max-width: 450px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'card-name card-name card-name card-name'
    'card card card card'
    'exp exp cvc cvc'
    'tos tos tos tos'
    ' . submit submit .';
  grid-gap: 20px;

  @media ${device.tablet} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'card-name card-name card-name card-name'
      'card card card card'
      'exp cvc . .'
      'tos tos tos tos'
      ' . submit submit .';
  }
`;

export const GridArea = styled.div`
  grid-area: ${props => props.gridArea};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TosWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CcvInfoWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto;
  height: 20px;
  width: 20px;

  @media ${device.tablet} {
    right: -25px;
  }
`;

export const CardIconsWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto;
  height: 20px;
  width: 31px;
`;

export const FreeMonthsInfo = styled.span`
  display: flex;
  align-items: center;
  margin-top: 45px;
  color: ${props => props.theme.cardForm.freeMonthsInfoColor};
  font-size: 14px;
  line-height: 150%;
  text-align: center;
`;
