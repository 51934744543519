import SVG from 'znipe-elements/general/Icon/Icon.styles';
import { IconProps } from 'znipe-elements/general/Icon/Icon.types';
import { styled, keyframes } from 'styled-components';
import prefers from 'znipe-styles/prefers';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSVG = styled(SVG)`
  animation: none;
  @media ${prefers.motion} {
    animation: ${rotate360} 1s linear infinite;
  }
`;

const Spinner: React.FC<IconProps> = ({ fillColor = '', hoverColor = '', activeColor = '' }) => (
  <StyledSVG viewBox="0 0 40 40" fill={fillColor} hoverColor={hoverColor} activeColor={activeColor}>
    <path
      opacity="0.25"
      d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
      s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
      c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
    />
    <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0C22.32,8.481,24.301,9.057,26.013,10.047z" />
  </StyledSVG>
);
export default Spinner;
