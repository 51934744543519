import { styled, css } from 'styled-components';
// @ts-ignore
import Cleave from 'cleave.js/react';
import Typography from 'znipe-elements/general/Typography/Typography';
import prefers from 'znipe-styles/prefers';

type ContainerProps = {
  $hideOverflow?: boolean;
  $disabled?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ $hideOverflow = true }) =>
    $hideOverflow &&
    css`
      overflow: hidden;
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    `};
`;

type WrapperProps = {
  $isHorizontal?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.$isHorizontal ? 'column' : 'row')};
  align-items: ${props => (props.$isHorizontal ? 'flex-start' : 'center')};
  box-sizing: border-box;
  line-height: ${({ theme: { input } }) => input.lineHeight};
  height: 100%;
  width: 100%;
`;

type LabelProps = {
  $error?: boolean;
  $lightLabel?: boolean;
  $isHorizontal?: boolean;
  $centerAlign?: string;
  $labelAlign?: string;
};

export const Label = styled.div<LabelProps>`
  display: flex;
  pointer-events: none;
  color: ${({ theme, $error, $lightLabel }) => {
    if ($error) return theme.errorRed;
    if ($lightLabel) return theme.secondaryTextColor;
    return theme.textSecondary;
  }};
  text-transform: capitalize;
  white-space: nowrap;
  p {
    margin-bottom: unset;
  }
  ${({ $isHorizontal }) =>
    $isHorizontal
      ? css`
          margin-bottom: 5px;
        `
      : css`
          padding-right: 16px;
        `};
  ${({ $centerAlign, $labelAlign }) => css`
    flex-basis: ${$centerAlign !== '' ? $centerAlign : 'auto'};
    justify-content: ${$labelAlign};
  `}
  ${({ $centerAlign }) =>
    $centerAlign &&
    css`
      width: calc(${$centerAlign} + 2px);
    `}
`;

export const activeLabelPosition = css`
  transform: translateX(-12px) translateY(-200%);
  left: 0;
  font-size: 12px;
`;

type InlineLabelContentProps = {
  $hasData?: boolean;
};

export const InlineLabelContent = styled.span<InlineLabelContentProps>`
  display: flex;
  position: absolute;
  top: ${({ theme }) => theme.input.height};
  left: 0;
  transform: translateY(-110%);
  transition: all 0.3s ease;

  ${props => props.$hasData && activeLabelPosition}
  @media ${prefers.reducedMotion} {
    transition: none;
  }
`;

type InlineLabelProps = {
  $focus?: boolean;
  $error?: boolean;
};
export const InlineLabel = styled.label<InlineLabelProps>`
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: ${({ theme, $error }) => {
    if ($error) return theme.errorRed;
    return theme.textSecondary;
  }};
  ${({ $focus }) =>
    $focus &&
    css`
      ${InlineLabelContent} {
        ${activeLabelPosition}
      }
    `}

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(${props => (props.$focus ? 0 : -100)}%);

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
`;

type InputFocusCssProps = {
  $error?: boolean;
};

export const inputFocusCss = css<InputFocusCssProps>`
  border: 1px solid
    ${({ $error, theme: { input, UIAccent, errorRed } }) => {
      if ($error) return errorRed;

      return input.borderColor?.focus || UIAccent;
    }};
  background-color: ${({ theme }) => theme.input.bgColor.focus || theme.input.bgColor.default};
  outline: none;
`;

type InputBaseCssProps = {
  $error?: boolean;
  $isInline?: boolean;
  $centerAlign?: string;
  $hasTopMargin?: boolean;
  disabled?: boolean;
};

export const inputBaseCss = css<InputBaseCssProps>`${({
  theme,
  $error,
  $isInline,
  $centerAlign,
  $hasTopMargin,
  disabled,
}) => {
  const { input = {} } = theme;
  return css`
    width: ${$centerAlign !== '' ? `calc(100% - ${$centerAlign})` : '100%'};
    height: ${$isInline ? '70px' : input.height};
    min-height: ${$isInline ? '70px' : input.height};
    padding-top: ${$isInline ? input.horizontalPadding : input.verticalPadding};
    padding-bottom: ${input.verticalPadding};
    padding-right: ${input.horizontalPadding};
    padding-left: ${$isInline ? '8px' : input.horizontalPadding};
    outline: none;
    color: ${input.textColor || theme.primaryTextColor};
    background-color: ${input.bgColor?.default};
    border-radius: ${input.borderRadius};
    ${
      $hasTopMargin &&
      css`
      margin-top: 8px;
    `
    }
    ${
      $error
        ? css`
          border: ${input.errorBorderWidth} solid ${theme.errorRed};
        `
        : css`
          border: 1px solid ${input.borderColor?.default || theme.containerStroke};
        `
    };
    ${
      disabled &&
      css`
      cursor: not-allowed;
      opacity: 0.5;
    `
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  `;
}}`;

export type InputStylesProps = InputBaseCssProps & {
  $centerAlign?: string;
  $customWidth?: string;
  $focus?: boolean;
};

export const inputStyles = css<InputStylesProps>`
  ${inputBaseCss};
  ${({ $centerAlign, $customWidth }) => {
    let calcValue = '100%';
    if ($centerAlign !== '') {
      calcValue = $customWidth || `calc(100% - ${$centerAlign})`;
    }
    return css`
      width: ${$customWidth || '100%'};
      flex-basis: ${calcValue};
      justify-content: flex-start;
    `;
  }}

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
  &::-webkit-clear-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  ${({ $focus }) => $focus && inputFocusCss}

  &:focus {
    ${inputFocusCss}
  }

  &:focus + ${InlineLabel} {
    ${InlineLabelContent} {
      ${activeLabelPosition}
    }
  }

  &:focus + ${/* sc-selector */ InlineLabel}::after {
    transform: translateX(0%);
  }

  &::placeholder {
    font-weight: normal;
    ${({ theme: { input } }) => css`
      color: ${input.placeholder.color};
      font-style: ${input.placeholder.fontStyle};
    `}
  }
`;

export const CleaveInput = styled(Cleave)`
  ${inputStyles}
`;

export const NormalInput = styled(Typography).attrs<InputStylesProps>(props => ({
  forwardedAs: 'input',
  type: 'paragraph-m',
  domType: props.type,
  dataTestId: props['data-testid'],
}))`
  margin: 0;
  ${inputStyles};
`;

export const Required = styled.div`
  padding-left: ${({ theme: { input } }) => input.marginAsterisk};
  color: ${({ theme }) => theme.errorLight};
  &::after {
    content: '*';
    font-weight: bold;
  }
`;

type MaxLengthProps = {
  $isCloseToMax?: boolean;
  $isMax?: boolean;
};

export const MaxLength = styled.div<MaxLengthProps>`
  padding-left: 15px;
  font-weight: bold;
  color: ${({ theme: { input }, $isCloseToMax, $isMax }) => {
    if ($isMax) {
      return input.maxLengthColor.max;
    }
    if ($isCloseToMax) {
      return input.maxLengthColor.almostMax;
    }
    return input.maxLengthColor.default;
  }};
`;

type PaddingLeftProps = {
  $centerAlign?: string;
  $customWidth?: string;
};

const paddingLeftCss = css<PaddingLeftProps>`
  padding-left: ${({ $centerAlign, $customWidth }) => {
    let calcValue = $centerAlign || '0px';
    if ($customWidth !== '') {
      calcValue = $customWidth || `calc(100% - ${$customWidth})`;
    }
    return calcValue;
  }};
`;

export const ErrorMessage = styled.div<PaddingLeftProps>`
  padding-top: 10px;
  color: ${({ theme: { input } }) => input.errorMessageColor};
  ${paddingLeftCss};
`;

type LeftEndElementProps = {
  $labelPosition?: string;
};

export const leftEndElementCss = css<LeftEndElementProps>`
  position: absolute;
  color: ${({ theme }) => theme.secondaryTextColor};
  height: ${({
    theme: {
      input: { height, verticalPadding },
    },
  }) => `calc(${height} - 2 * ${verticalPadding})`};
  padding-left: 12px;
  text-align: left;
  ${({
    $labelPosition,
    theme: {
      input: { verticalPadding },
    },
  }) =>
    $labelPosition === 'top' &&
    css`
      bottom: calc(${verticalPadding});
    `}
`;

export type RightEndElementProps = LeftEndElementProps & {
  $width?: string;
};

export const rightEndElementCss = css<RightEndElementProps>`
  ${leftEndElementCss};
  width: ${({ $width }) => $width};
  padding-left: unset;
  padding-right: 12px;
  text-align: right;
`;

type PrefixSuffixProps = {
  $centerAlign?: string;
  $width?: string;
  $disabled?: boolean;
};

const prefixAndSuffixStyle = css<PrefixSuffixProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: ${({ $centerAlign, $width }) => ($width === '100%' ? 0 : $centerAlign)};
  pointer-events: none;
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const Prefix = styled.span<LeftEndElementProps & PrefixSuffixProps>`
  ${leftEndElementCss};
  ${prefixAndSuffixStyle};
`;

export const Suffix = styled.span<RightEndElementProps & PrefixSuffixProps>`
  ${rightEndElementCss};
  ${prefixAndSuffixStyle};
`;

export const Comment = styled.div<PaddingLeftProps>`
  color: ${({ theme }) => theme.secondaryTextColor};
  margin-top: 4px;
  p {
    margin-bottom: unset;
  }
  ${paddingLeftCss}
`;

export const TransparentInput = styled.input<InputStylesProps>`
  ${inputStyles};
  border: none;
  height: 100%;
  width: 100%;
  min-height: unset;
  padding: 0;
  background-color: ${({ theme }) => theme.input.bgColor?.default};

  &:focus {
    border: none;
    background-color: transparent;
  }

  ::placeholder {
    ${({ theme: { input } }) => css`
      color: ${input.placeholder.color};
      font-style: ${input.placeholder.fontStyle};
    `}
  }
`;
