import { styled, css } from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'znipe-styles/colors';
import Typography from 'znipe-elements/general/Typography/Typography';
import type { Size, Variant } from './Button';

export const heights = {
  xsmall: '32px',
  small: '40px',
  medium: '48px',
  large: '64px',
};

const minWidths = {
  xsmall: '104px',
  small: '144px',
  medium: '184px',
  large: '224px',
};

const paddings = {
  xsmall: '16px',
  small: '24px',
  medium: '32px',
  large: '40px',
};

export const borderRadius = {
  xsmall: '4px',
  small: '4px',
  medium: '4px',
  large: '8px',
};

const disabled = css`
  cursor: not-allowed;
  opacity: 0.5;
`;

export const InteractionColors = styled.div<{ $size: Size; $compact?: boolean }>`
  background-color: transparent;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: ${({ $size }) => heights[$size]};
`;

const size = css<{ $size: Size }>`
  ${({ $size }) => css`
    height: ${heights[$size]};
    border-radius: ${borderRadius[$size]};
    ${InteractionColors} {
      border-radius: ${borderRadius[$size]};
      padding: 0 ${paddings[$size]};
    }
  `}
`;

const alphaTransparent = css`
  &:hover,
  &:focus {
    ${InteractionColors} {
      background-color: ${colors.white12};
    }
  }
  &:active {
    ${InteractionColors} {
      background-color: ${colors.black20};
    }
  }
  &:focus-visible {
    outline: 2px solid white;
    ${InteractionColors} {
      background-color: ${colors.black20};
    }
  }
`;

const interactionPsuedo = css`
  &:hover,
  &:focus {
    background-color: ${colors.white16};
    @supports (backdrop-filter: blur(10px)) {
      backdrop-filter: blur(10px);
    }
  }

  &:active {
    background-color: ${colors.white20};
    @supports (backdrop-filter: blur(10px)) {
      backdrop-filter: blur(10px);
    }
  }

  &:focus-visible {
    background-color: ${colors.white16};
    outline: 2px solid ${({ theme }) => theme.UIAccent};
    @supports (backdrop-filter: blur(10px)) {
      backdrop-filter: blur(10px);
    }
  }
`;

const primaryStyles = css<{ $disabled: boolean }>`
  background-color: ${({ theme }) => theme.buttonPrimary || theme.UIAccent};
  ${({ $disabled }) => !$disabled && alphaTransparent};
`;

const secondaryStyles = css<{ $disabled: boolean }>`
  background-color: ${({ theme }) => theme.tertiaryBackgroundColor};
  ${({ $disabled }) => !$disabled && alphaTransparent};
`;

const semiTransparentStyles = css<{ $disabled: boolean }>`
  background-color: ${colors.white08};
  ${({ $disabled }) => !$disabled && interactionPsuedo};

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
  }
`;

const ghostStyles = css<{ $disabled: boolean }>`
  background-color: transparent;
  ${({ $disabled }) => !$disabled && interactionPsuedo};
`;

const destructiveStyles = css<{ $disabled: boolean }>`
  background-color: ${({ theme }) => theme.errorRed};
  ${({ $disabled }) => !$disabled && alphaTransparent};
`;

const cautionStyles = css<{ $disabled: boolean }>`
  background-color: ${({ theme }) => theme.amberYellow};
  ${({ $disabled }) => !$disabled && alphaTransparent};
`;

const successStyles = css<{ $disabled: boolean }>`
  background-color: ${({ theme }) => theme.successGreen};
  ${({ $disabled }) => !$disabled && alphaTransparent};
`;

const outlineStyles = css<{ $disabled: boolean }>`
  background-color: ${colors.white08};
  border: 1px solid ${({ theme }) => theme.UIAccent};
  ${({ $disabled }) => !$disabled && interactionPsuedo};
`;

export const variants = {
  primary: primaryStyles,
  secondary: secondaryStyles,
  semiTransparent: semiTransparentStyles,
  ghost: ghostStyles,
  destructive: destructiveStyles,
  caution: cautionStyles,
  outline: outlineStyles,
  success: successStyles,
};

export const StyledButton = styled.button<{
  $size: Size;
  $compact?: boolean;
  $fluid?: boolean;
  $customWidth?: string;
  $variant: Variant;
  $disabled: boolean;
}>`
  display: flex;
  border: none;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  user-select: none;
  white-space: nowrap;
  padding: unset;

  ${size};

  min-width: ${({ $size, $compact, $fluid, $customWidth }) => {
    if ($customWidth) return $customWidth;
    if ($compact) return 'none';
    if ($fluid) return '100%';
    return minWidths[$size] ?? minWidths.xsmall;
  }};

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
  }

  ${({ $variant }) => variants[$variant]};
  ${({ $disabled }) => $disabled && disabled};

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

// @TODO: fix this later, ugly copy-paste
export const StyledLink = styled(Link)<{
  $size: Size;
  $compact?: boolean;
  $fluid?: boolean;
  $customWidth?: string;
  $variant: Variant;
  $disabled: boolean;
}>`
  display: flex;
  border: none;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  user-select: none;
  white-space: nowrap;
  padding: unset;

  ${size};

  min-width: ${({ $size, $compact, $fluid, $customWidth }) => {
    if ($customWidth) return $customWidth;
    if ($compact) return 'none';
    if ($fluid) return '100%';
    return minWidths[$size] ?? minWidths.xsmall;
  }};

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
  }

  ${({ $variant }) => variants[$variant]};
  ${({ $disabled }) => $disabled && disabled};

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const ButtonTypography = styled(Typography)<{
  $textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'initial' | 'inherit';
}>`
  text-transform: ${({ $textTransform }) => $textTransform};
`;
