import { useCallback, useContext, useMemo } from 'react';
import { styled } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import useDimensions from 'react-cool-dimensions';
import AvatarPicker from '../AvatarPicker/AvatarPicker';
import ChatContext from '../../../contexts/ChatContext';

const Header = styled.div`
  text-align: center;
`;

const AlligenceWrapper = styled.div`
  padding: 16px;
`;

type Size = 'small' | 'medium';

type SizeInfo = {
  headline: string;
  description: string;
};

type Sizes = Record<Size, SizeInfo>;

const sizes: Sizes = {
  small: {
    headline: 'heading-s',
    description: 'paragraph-m',
  },
  medium: {
    headline: 'heading-s',
    description: 'paragraph-m',
  },
};

export const HEADLINE_TEXT = 'Choose your team allegiance';
export const DESCRIPTION_TEXT = 'This will be your representation in the chat';

const Allegiance = () => {
  const { featuredAvatars, avatars, userAvatar, setUserAvatar, setModalInfo } =
    useContext(ChatContext);
  const { observe, currentBreakpoint } = useDimensions({
    breakpoints: { small: 0, medium: 646 },
    useBorderBoxSize: true,
  });
  const selectedSizes = useMemo(
    () => sizes[currentBreakpoint as Size] ?? sizes.small,
    [currentBreakpoint],
  );
  const onAvatarSelect = useCallback(
    (selectedAvatar: string) => {
      setUserAvatar(selectedAvatar);
      setModalInfo({});
    },
    [setUserAvatar, setModalInfo],
  );

  return (
    <AlligenceWrapper ref={observe}>
      <Header>
        <Typography type={selectedSizes.headline}>{HEADLINE_TEXT}</Typography>
        <Typography type={selectedSizes.description}>{DESCRIPTION_TEXT}</Typography>
      </Header>
      <AvatarPicker
        featuredAvatars={featuredAvatars}
        avatars={avatars}
        selectedAvatar={userAvatar}
        onAvatarSelect={onAvatarSelect}
      />
    </AlligenceWrapper>
  );
};

export default Allegiance;
