import { useState, useCallback, useMemo, useEffect } from 'react';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';

const useMessageCooldown = () => {
  const { chatStatus } = useChatContext() ?? {};
  const [messagesQueue, setMessagesQueue] = useState<number[]>([]);
  const [cooldown, setCooldown] = useState<number>(0);

  const isOnCooldown = useMemo(
    () =>
      !!chatStatus?.spamFilter &&
      chatStatus.spamFilter.timeWindow > 0 &&
      messagesQueue.length >= chatStatus.spamFilter.maxMessages,
    [chatStatus?.spamFilter, messagesQueue.length],
  );

  const oldestTimestamp = messagesQueue[0];

  const recalculateCooldown = useCallback(() => {
    if (!chatStatus?.spamFilter || !isOnCooldown || !oldestTimestamp) {
      return 0;
    }

    const timeElapsed = Date.now() - oldestTimestamp;
    const timeLeft = Math.max(
      Math.floor((chatStatus.spamFilter.timeWindow - timeElapsed) / 1000),
      0,
    );
    return timeLeft;
  }, [chatStatus?.spamFilter, oldestTimestamp, isOnCooldown]);

  useEffect(() => {
    const newCooldown = recalculateCooldown();
    setCooldown(newCooldown);

    if (newCooldown > 0) {
      const timer = setInterval(() => {
        const intervalCooldown = recalculateCooldown();
        setCooldown(intervalCooldown);
        if (intervalCooldown <= 0) {
          clearInterval(timer);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [recalculateCooldown]);

  const onCooldownMessage = () => {
    const currentTimestamp = Date.now();
    setMessagesQueue(prevQueue => [...prevQueue, currentTimestamp]);

    setTimeout(() => {
      setMessagesQueue(prevQueue => {
        const newQueue = [...prevQueue];
        newQueue.shift();
        return newQueue;
      });
    }, chatStatus?.spamFilter?.timeWindow);
  };

  return { isOnCooldown, onCooldownMessage, cooldown };
};

export default useMessageCooldown;
