import { useRef, useEffect } from 'react';
import { dequal as deepEqual } from 'dequal';

const isPrimitive = val => val == null || /^[sbn]/.test(typeof val);

const checkDeps = deps => {
  if (!deps || !deps.length) {
    throw new Error(
      'useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.',
    );
  }
  if (deps.every(isPrimitive)) {
    throw new Error(
      'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.',
    );
  }
};

const useDeepCompareMemoize = value => {
  const ref = useRef();

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useDeepCompareEffect = (callback, dependencies) => {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }
  useEffect(callback, useDeepCompareMemoize(dependencies));
};

export default useDeepCompareEffect;
