import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import braintreeClient from 'braintree-web/client';
import paypalCheckout from 'braintree-web/paypal-checkout';
import { useForm } from 'react-hook-form';
import withTheme from 'znipe-themes/hocs/withTheme';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import Checkbox from 'znipe-elements/data-entry/Checkbox/Checkbox';
import ErrorMessage from 'tv-modules/Payment/SharedStyles/ErrorMessage';
import Description from 'tv-modules/Payment/SharedStyles/Description';
import usePaymentRequest from 'tv-modules/Payment/hooks/usePaymentRequest';
import { useUserId } from 'tv-selectors/auth/makeGetUserId';
import { useAuthToken } from 'tv-selectors/auth/makeGetAuthToken';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import { getPurchaseToken } from 'tv-utils/api/users';
import config from 'tv-config/config';

import {
  Form,
  TermsOfService,
  FreeMonthsInfo,
  FormContainer,
  ButtonContainer,
} from './PayPalForm.styles';
import themes from './PayPalForm.themes';
import PayPalLogo from './PayPalLogo';

const PayPalForm = ({
  onSuccess = () => {},
  onFailure = () => {},
  onSubmit = () => {},
  productId,
  termsOfServices = [],
  freeMonthsText,
  promoCode,
  currency,
  period,
  edit = false,
}) => {
  const {
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = useForm();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const userId = useUserId();
  const authToken = useAuthToken();
  const errorMessage = Object.keys(errors).length > 0 ? 'Please accept terms and conditions' : '';

  const paypalContainerID = `${productId}-paypal-container`;

  const [ready, setReady] = useState(false);

  const sendPaymentRequest = usePaymentRequest(
    productId,
    promoCode,
    period,
    currency,
    'braintree',
    edit,
  );

  useEffect(() => {
    let buttonPromise = null;
    let cancel = false;
    let payPalButtonComponent = null;
    const initializeBraintree = async () => {
      if (!userId || !authToken) return () => {};

      try {
        const { token } = await getPurchaseToken(userId, 'braintree', authToken);
        if (cancel) return Promise.resolve();

        const clientInstance = await braintreeClient.create({ authorization: token });
        if (cancel) return Promise.resolve();

        const paypalCheckoutInstance = await paypalCheckout.create({
          client: clientInstance,
        });
        if (cancel) return Promise.resolve();

        const payment = async () => {
          if (cancel) return Promise.resolve();
          const isValid = await trigger();
          if (!isValid) return Promise.resolve();
          return paypalCheckoutInstance.createPayment({
            flow: 'vault',
          });
        };
        if (cancel) return Promise.resolve();

        const onAuthorize = data => {
          if (cancel) return Promise.resolve();
          const onTokenized = payload => {
            if (cancel) return Promise.resolve();
            // Submit payload.nounce to server
            const { details = {} } = payload;
            const braintreeData = {
              braintreeNonce: payload.nonce,
              braintreeCustomerFirstName: details.firstName,
              braintreeCustomerLastName: details.lastName,
              braintreeCustomerEmail: details.email,
              braintreeCustomerId: details.payerId,
              braintreeCustomerType: payload.type,
              braintreeCustomerCountry: details.billingAddress
                ? details.billingAddress.countryCodeAlpha2
                : details.countryCode,
            };
            return sendPaymentRequest(braintreeData).then(res => ({ res, braintreeData }));
          };

          return paypalCheckoutInstance
            .tokenizePayment(data)
            .then(onTokenized)
            .then(response => {
              const { braintreeNonce } = response.braintreeData || {};
              const { results = {} } = response.res || {};
              return onSuccess(braintreeNonce, results);
            }, onFailure);
        };

        // PayPal is initialized globally when importing braintree-web. I can't get this as a variable :(
        buttonPromise = paypal.Button.render(
          {
            env: config.PAYPAL_ENV || 'sandbox',
            payment,
            onAuthorize,
            style: {
              tagline: false,
              label: 'paypal',
              size: 'responsive',
              color: 'black',
            },
          },
          paypalContainerID,
        )
          .then(component => {
            payPalButtonComponent = component;
            setReady(true);
          })
          .catch(onFailure);
      } catch (error) {
        onFailure(error);
      }
      return Promise.resolve();
    };

    initializeBraintree();
    return () => {
      cancel = true;
      if (buttonPromise) buttonPromise.reject();
      if (payPalButtonComponent) payPalButtonComponent.element.remove();
    };
  }, [authToken, onFailure, onSuccess, paypalContainerID, userId, sendPaymentRequest, trigger]);

  return (
    <FormContainer data-testid="paypal-form">
      <PayPalLogo />
      <Description>You will be re-directed to PayPal to verify your account.</Description>
      {isTabletOrGreater && freeMonthsText && (
        <FreeMonthsInfo data-testid="free-months-info">{freeMonthsText}</FreeMonthsInfo>
      )}
      <Form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
        {termsOfServices.map((to, index) => {
          const name = `tos-${index}`;
          return (
            <TermsOfService key={name}>
              <Checkbox
                {...register(name, { required: true })}
                label={to}
                longLabel
                error={!!errors[name]}
              />
            </TermsOfService>
          );
        })}
        <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>
        <ButtonContainer id={paypalContainerID} data-testid="submit-button">
          {!ready && <LoadingSpinner />}
        </ButtonContainer>
      </Form>
      {!isTabletOrGreater && freeMonthsText && (
        <FreeMonthsInfo data-testid="free-months-info">{freeMonthsText}</FreeMonthsInfo>
      )}
    </FormContainer>
  );
};

PayPalForm.propTypes = {
  termsOfServices: PropTypes.arrayOf(PropTypes.node),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onSubmit: PropTypes.func,
  productId: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  freeMonthsText: PropTypes.string,
  promoCode: PropTypes.string,
  edit: PropTypes.bool,
};

export default withTheme(memo(PayPalForm), themes, 'payPalForm');
