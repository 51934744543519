import { styled } from 'styled-components';
import {
  Container as DefaultContainer,
  StyledButton,
  IconWrapper as DefaultIconWrapper,
} from '../NavButton.styles';

export const Container = styled(DefaultContainer)`
  width: auto;
`;

export const Button = styled(StyledButton)`
  border-radius: 40px;
  width: auto;
`;

export const IconWrapper = styled(DefaultIconWrapper)`
  margin-right: 8px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
`;
