import { memo, useRef, useEffect, forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import mux from 'mux.js';
import * as shaka from 'shaka-player';
import withZnipeAnalytics from 'znipe-player/src/hocs/withZnipeAnalytics';
import styled from 'styled-components';
import logger from 'znipe-logger';
import useThemeContext from 'znipe-hooks/useThemeContext';
import withTheme from 'znipe-themes/hocs/withTheme';
import isBrowser from 'znipe-utils/web/isBrowser';
import registerHttpXHRPlugin from './plugins/httpXHRPlugin';
import registerHttpFetchPlugin from './plugins/httpFetchPlugin';
import useNetworkFilter from './useNetworkFilter';
import useAntiFreeze from './useAntiFreeze';

const Video = styled.video`
  width: 100%;
  height: 100%;
`;

const emptyObject = {};

if (isBrowser()) {
  window.muxjs = mux;
  shaka.polyfill.installAll();
}

export const posterTheme = {
  default: {
    poster: 'https://assets.znipe.tv/video-player/poster-image-znipe-small.jpg',
  },
  proview: {
    poster: 'https://assets.znipe.tv/video-player/poster-image-proview-small.jpg',
  },
};

const ShakaPlayer = forwardRef(
  (
    {
      AnalyticsListener,
      autoPlay = true,
      controls = false,
      liveDelay = 20,
      muted = true,
      onSetPlayer,
      poster,
      quality = -1,
      src = '',
      startTime,
      configuration = emptyObject,
      disablePictureInPicture = false,
    },
    ref,
  ) => {
    const retryTimeout = useRef(null);
    const [player, setPlayer] = useState(null);
    const videoRef = useRef({});
    const {
      shaka: { poster: posterImage },
    } = useThemeContext();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Only configure player once
    useEffect(() => {
      if (!shaka.Player.isBrowserSupported()) {
        return () => {};
      }
      registerHttpXHRPlugin();
      registerHttpFetchPlugin();
      const shakaPlayer = new shaka.Player(videoRef.current);

      shakaPlayer.addEventListener('error', logger.error);
      const defaultBandwidthEstimate = quality === -1 ? 4000000 : parseFloat(quality);

      shakaPlayer.configure({
        abr: { defaultBandwidthEstimate },
        manifest: {
          dash: {
            ignoreMinBufferTime: true,
            clockSyncUri: 'https://time.akamai.com/',
            initialSegmentLimit: Infinity,
          },
          availabilityWindowOverride: Infinity,
        },
        streaming: {
          rebufferingGoal: 1,
          useNativeHlsOnSafari: false,
          preferNativeHls: false,
        },
      });
      setPlayer(shakaPlayer);
      return () => {
        if (retryTimeout.current) clearTimeout(retryTimeout.current);
        if (shakaPlayer) {
          shakaPlayer.detach();
          shakaPlayer.destroy();
        }
        shakaPlayer.removeEventListener('error', logger.error);
      };
    }, []);

    useImperativeHandle(ref, () => player, [player]);
    useNetworkFilter(player);
    useAntiFreeze(player);

    useEffect(() => {
      const video = videoRef.current;
      if (!video || !player) return () => {};

      const onTimeUpdate = ({ target }) => {
        const { currentTime } = target;
        const { playRangeEnd } = player.getConfiguration();
        if (currentTime < playRangeEnd) return;

        if (!video.paused) video.pause();
        if (AnalyticsListener) AnalyticsListener.onEnded();
      };

      video.addEventListener('timeupdate', onTimeUpdate);

      return () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }, [AnalyticsListener, player]);

    useEffect(() => {
      if (!player) return;
      if (typeof liveDelay === 'number' && liveDelay >= 10) {
        player.configure({
          manifest: {
            dash: {
              ignoreSuggestedPresentationDelay: true,
            },
            defaultPresentationDelay: liveDelay,
          },
        });
      } else {
        player.configure({
          manifest: {
            dash: {
              ignoreSuggestedPresentationDelay: false,
            },
            defaultPresentationDelay: undefined,
          },
        });
      }
    }, [player, liveDelay]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: We don't want to re-load the stream if the src hasn't changed
    useEffect(() => {
      if (!player) return;
      if (!src) {
        player.unload();
        return;
      }
      if (configuration && Object.keys(configuration).length > 0) {
        player.configure(configuration);
      }
      if (onSetPlayer) onSetPlayer();

      const playRetry = () =>
        new Promise((resolve, reject) => {
          const video = player.getMediaElement();

          // Shaka sometimes starts at the beginning of a segment
          if (startTime && video.currentTime < startTime) video.currentTime = startTime;

          logger.log('ShakaPlayer', 'loaded', { autoPlay, currentTime: video.currentTime });
          if (!autoPlay) {
            video.pause();
            resolve();
            return;
          }

          const TIMEOUT = 100;
          const MAX_TIMES = 20; // Retry 20 times (2s)
          let times = MAX_TIMES;
          let error;
          const attempt = () => {
            if (times === 0) return reject(error);
            if (times < MAX_TIMES) video.muted = true; // Mute if not first try to start
            const onError = e => {
              times -= 1;
              error = e;
              if (retryTimeout.current) clearTimeout(retryTimeout.current);
              retryTimeout.current = setTimeout(attempt, TIMEOUT);
            };
            return video.play().then(resolve).catch(onError);
          };
          attempt();
        });

      logger.log('ShakaPlayer', 'load', { src, startTime });
      player.load(src, startTime).then(playRetry).catch(logger.error);
      if (AnalyticsListener) {
        AnalyticsListener.sendLoadVideoEvent(src);
      }
    }, [player, src, startTime, onSetPlayer]);

    return (
      <Video
        disablePictureInPicture={disablePictureInPicture}
        data-testid="shaka-player"
        controls={controls}
        muted={muted}
        autoPlay={false}
        ref={videoRef}
        playsInline
        poster={
          poster ??
          posterImage ??
          'https://assets.znipe.tv/video-player/poster-image-znipe-small.jpg'
        }
      />
    );
  },
);

ShakaPlayer.propTypes = {
  AnalyticsListener: PropTypes.shape({
    sendLoadVideoEvent: PropTypes.func,
    onEnded: PropTypes.func,
  }),
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  liveDelay: PropTypes.number,
  muted: PropTypes.bool,
  onSetPlayer: PropTypes.func,
  poster: PropTypes.string,
  quality: PropTypes.number,
  src: PropTypes.string,
  startTime: PropTypes.number,
  configuration: PropTypes.shape({
    playRangeStart: PropTypes.number,
    playRangeEnd: PropTypes.number,
  }),
  disablePictureInPicture: PropTypes.bool,
};

const areEqual = (prevProps, nextProps) => {
  if (prevProps.src !== nextProps.src) return false;
  if (!prevProps.AnalyticsListener && nextProps.AnalyticsListener) return false;
  return true;
};

const MemoizedShakaPlayer = memo(withTheme(ShakaPlayer, posterTheme, 'shaka'), areEqual);

export default MemoizedShakaPlayer;

export const ShakaPlayerWithAnalytics = withZnipeAnalytics()(MemoizedShakaPlayer);
