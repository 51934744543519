import { styled } from 'styled-components';
import colors from 'znipe-styles/colors';

export const themes = {
  proview: {
    textColor: colors.liveRed,
    bgColor: colors.palePink,
    borderRadius: '3px',
  },
  default: {
    textColor: colors.liveRed,
    bgColor: colors.palePink,
    borderRadius: '3px',
  },
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.warningMessage.bgColor};
  color: ${({ theme }) => theme.warningMessage.textColor};
  border-radius: ${({ theme }) => theme.warningMessage.borderRadius};
  padding: 12px;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
`;

export const Text = styled.div`
  padding-left: 12px;
`;
