import { memo, useState, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Tab from './Tab';
import { Container, ActiveLine, Wrapper } from './Tabset.styles';

const Tabset = ({
  tabs,
  onClickTab,
  activeTab = '',
  alignment = 'center',
  showBottomBorder = true,
  size = 'small',
  isMobile = false,
}) => {
  const scrollRef = useRef(null);
  const activeElement = useRef(null);
  const [currentTab, setCurrentTab] = useState(null);
  const showOverflow = useMemo(() => (isMobile ? undefined : { overflow: 'visible' }), [isMobile]);
  const [activeLineProperties, setActiveLineProperties] = useState({
    width: 0,
    position: 0,
    scrollPosition: 0,
  });
  const { colorSchemeMap } = useThemeContext();
  const pageColors = useMemo(() => colorSchemeMap.default ?? {}, [colorSchemeMap]);

  const { position: activeLinePosition, width: activeLineWidth } = activeLineProperties;

  const measuredRef = useCallback(
    node => {
      if (node !== null) {
        if (!activeTab || activeTab === '') {
          const childElement = node.children[0];
          activeElement.current = childElement;
          const position = childElement?.offsetLeft ?? 0;
          const width = childElement?.offsetWidth ?? 0;
          setCurrentTab(tabs[0]);
          setActiveLineProperties(prev => ({
            ...prev,
            width,
            position: position - prev.scrollPosition,
          }));
        } else {
          let tabIndex = tabs.findIndex(tab => tab.label === activeTab);
          if (tabIndex === -1) tabIndex = 0;
          const selectedElement = node.children[tabIndex];
          activeElement.current = selectedElement;
          if (isMobile && selectedElement?.scrollIntoView) {
            selectedElement?.scrollIntoView(false);
          }
          const position = selectedElement ? selectedElement.offsetLeft : 0;
          const width = selectedElement ? selectedElement.offsetWidth : 0;
          setCurrentTab(tabs[tabIndex]);
          setActiveLineProperties(prev => ({
            ...prev,
            width,
            position: position - prev.scrollPosition,
          }));
        }
      }
    },
    [activeTab, isMobile, tabs],
  );

  const handleClick = useCallback(
    (lineProperties, clickedLabel) => {
      setActiveLineProperties(prev => ({ ...prev, ...lineProperties }));
      const clickedTab = tabs.find(({ label }) => label === clickedLabel);
      if (onClickTab) onClickTab(clickedTab);
      setCurrentTab(clickedTab);
    },
    [tabs, onClickTab],
  );

  const tabItems = useMemo(
    () =>
      tabs.map(({ label, badge, ...restProps }) => (
        <Tab
          key={`tab-${label}`}
          size={size}
          label={label}
          badge={badge}
          active={label === currentTab?.label}
          onClick={handleClick}
          pageColor={pageColors.rootColor}
          {...restProps}
        />
      )),
    [currentTab, handleClick, tabs, pageColors, size],
  );

  return (
    <Wrapper showBottomBorder={showBottomBorder}>
      <ScrollContainer
        vertical={false}
        activationDistance={20}
        innerRef={scrollRef}
        style={showOverflow}
      >
        <Container alignment={alignment} ref={measuredRef} data-testid="tabs-container" size={size}>
          {tabItems}
          <ActiveLine
            data-testid="active-line"
            size={size}
            positionFromLeft={activeLinePosition}
            width={activeLineWidth}
            gradient={pageColors.gradient}
          />
        </Container>
      </ScrollContainer>
    </Wrapper>
  );
};

Tabset.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      badge: PropTypes.node,
    }),
  ).isRequired,
  onClickTab: PropTypes.func,
  activeTab: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'center']),
  showBottomBorder: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  isMobile: PropTypes.bool,
};
export { TabsetWrapper } from './Tabset.styles';

export default memo(Tabset);
