const gaTrackEvent = global.document
  ? (action, label, type = 'event', value = null, category = global.location.pathname) => {
      if (category === '/') {
        // biome-ignore lint/style/noParameterAssign:
        category = 'landing';
      }

      if (window && window.ga) {
        if (value) {
          ga('send', type, category, action, label, value);
        } else {
          ga('send', type, category, action, label);
        }
      }

      if (window && window.mixpanel) {
        mixpanel.track(action, {
          label,
          url: category,
        });
      }
    }
  : () => null;

export default gaTrackEvent;
