import { useMemo, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import usePackageName from 'tv-hooks/usePackageName';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsMobilePortraitOrGreater } from 'tv-selectors/browser/makeGetIsMobilePortraitOrGreater';
import { useGetSearchHistoryQueries } from 'tv-selectors/search/makeGetSearchHistoryQueries';
import { useGetSearchHistoryResults } from 'tv-selectors/search/makeGetSearchHistoryResults';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import Navigation from 'znipe-elements/layout/Navigation/Navigation';
import FlourishContainer from 'znipe-elements/data-display/FlourishContainer/FlourishContainer';
import SearchMasthead from 'tv-modules/Search/SearchMasthead/SearchMasthead';
import SearchDropdown from 'tv-modules/Search/SearchDropdown/SearchDropdown';
import useSetPlayedStatus from 'tv-modules/Onboarding/hooks/useSetPlayedStatus';
import { HOME_WELCOME_STEP_ID } from 'tv-routes/Home/Home.constants';
import { useIsNative } from 'tv-selectors/deviceInfo/makeGetIsNative';
import config from 'tv-config/config';
import { LOL_PATCH_VERSION } from 'znipe-constants/misc';
import withTheme from 'znipe-themes/hocs/withTheme';
import ExternalLink from './ExternalLink';
import SettingsMenuButton from './SettingsMenuButton';
import {
  OuterWrapper,
  Container,
  SearchContainer,
  SearchIconWrapper,
  TitleWrapper,
  NavigationWrapper,
  DesktopContentWrapper,
  themes,
} from './Masthead.styles';
import useMastheadFlourish from './useMastheadFlourish';
import Logo from './Logo';

const HIDDEN = 'hidden';
const PASSIVE = 'passive';
const ACTIVE = 'active';

const { QUARTERBACK_API_URL } = config;
const SUGGESTIONS_ENDPOINT = `${QUARTERBACK_API_URL}/v5/search/as-you-type`;

// TODO: fetch from api
const popularResults = [
  {
    id: 'Rogue',
    name: 'Rogue',
    category: 'team',
    logoDark: 'https://assets.znipe.tv/teams/Rogue/teamlogo_dark.svg',
    game: 'lol',
  },
  {
    id: 'Spica',
    nickname: 'Spica',
    category: 'player',
    image: 'https://assets.znipe.tv/teams/TSM/Spica_2020-12-14.png',
    game: 'lol',
  },
  {
    id: 'Fnatic',
    name: 'Fnatic',
    category: 'team',
    logoDark: 'https://assets.znipe.tv/teams/Fnatic/teamlogo_dark.svg',
    game: 'lol',
  },
];

const Masthead = ({
  title = '',
  baseUrl = '',
  eventSlug = '',
  links = [
    {
      label: 'Home',
      icon: 'home',
      link: '/znipe/home',
    },
  ],
  onSettingsClick,
  packageName: providedPackageName,
}) => {
  const theme = useThemeContext();
  const { themeName, masthead } = theme;
  const packageName = usePackageName(providedPackageName);
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isMobilePortraitOrGreater = useGetIsMobilePortraitOrGreater();
  const [searchState, setSearchState] = useState(HIDDEN);
  const isMobileSearchMode = searchState !== HIDDEN && !isDesktopOrGreater;
  const isNative = useIsNative();
  const searchQueries = useGetSearchHistoryQueries();
  const historyResults = useGetSearchHistoryResults();
  const location = useLocation();
  const navigate = useNavigate();
  const setHomeWelcomePlayed = useSetPlayedStatus(HOME_WELCOME_STEP_ID);

  const hasExternalLink = themeName === 'proview';

  const onOutsideClick = useCallback(() => setSearchState(HIDDEN), []);
  const desktopSearchRef = useOutsideClick(onOutsideClick);
  const eventSlugString = eventSlug ? `/${eventSlug}` : '';
  const browseUrl = `${baseUrl}/browse${eventSlugString}`;

  const [suggestions, setSuggestions] = useState([]);
  const recentSearches = useMemo(
    () => ({
      searches: historyResults,
      queries: searchQueries,
    }),
    [searchQueries, historyResults],
  );

  const typographyType = useMemo(
    () => (isMobilePortraitOrGreater ? 'heading-m' : 'heading-s'),
    [isMobilePortraitOrGreater],
  );

  const { flourish } = useMastheadFlourish();

  const background = useMemo(() => {
    const searchBackground =
      isMobileSearchMode && searchState === PASSIVE ? theme.bg2Pop : theme.tertiaryBackgroundColor;
    const searchOrNot = isMobileSearchMode ? searchBackground : theme.bg2Pop;
    return theme.mastheadGradient ?? searchOrNot;
  }, [theme, isMobileSearchMode, searchState]);

  const fetchSearchSuggestions = useCallback(
    query => {
      if (!query) return;
      fetch(
        `${SUGGESTIONS_ENDPOINT}?q=${query}&f=packages.humanReadableId:${packageName},patch:${LOL_PATCH_VERSION}&l=10&asSingleList=true`,
        {
          headers: {
            'Znipe-Search-Context': 'web',
          },
        },
      )
        .then(res => res.json())
        .then(res => {
          const { results = [] } = res;
          const { hits } = results;
          if (hits?.length > 0) {
            setSuggestions(hits);
          }
        });
    },
    [packageName],
  );

  const onLogoClick = useCallback(() => {
    const homeLink = links[0]?.link;
    const isOnHomePage = location.pathname.includes(homeLink);
    if (!isOnHomePage) navigate(homeLink);
  }, [links[0]?.link, location.pathname, navigate]);

  const fetchRecentSearches = useCallback(() => {}, []);

  const handleClickSearchIcon = useCallback(() => {
    if (searchState === HIDDEN) {
      fetchRecentSearches();
      setSearchState(PASSIVE);
    }
  }, [searchState, fetchRecentSearches]);
  const handleClickBack = useCallback(() => setSearchState(HIDDEN), []);
  const handleSearchChange = useCallback(
    query => {
      if (query !== '') {
        fetchRecentSearches();
      }
      fetchSearchSuggestions(query);
      setSearchState(query === '' ? PASSIVE : ACTIVE);
    },
    [fetchSearchSuggestions, fetchRecentSearches],
  );
  const handleClickDelete = useCallback(() => setSearchState(HIDDEN), []);
  const handleFocus = useCallback(
    e => {
      if (searchState === ACTIVE) return;
      if (e.target.value === '') setSearchState(PASSIVE);
      setHomeWelcomePlayed(true);
    },
    [searchState, setHomeWelcomePlayed],
  );

  const searchDropDownElement = useMemo(
    () => (
      <SearchDropdown
        ref={desktopSearchRef}
        searchState={searchState}
        onSearchChange={handleSearchChange}
        onClickDelete={handleClickDelete}
        onFocus={handleFocus}
        browseUrl={browseUrl}
        packageName={packageName}
        baseUrl={baseUrl}
        suggestions={suggestions}
        recentSearches={recentSearches}
        popularSearches={popularResults}
      />
    ),
    [
      desktopSearchRef,
      searchState,
      baseUrl,
      browseUrl,
      packageName,
      suggestions,
      recentSearches,
      handleSearchChange,
      handleClickDelete,
      handleFocus,
    ],
  );

  const menuSize = useMemo(() => {
    if (isDesktopOrGreater) return 'large';
    if (isTabletOrGreater) return 'medium';
    return 'small';
  }, [isTabletOrGreater, isDesktopOrGreater]);

  const contentElements = useMemo(() => {
    if (isMobileSearchMode) {
      return (
        <SearchContainer $isActive={searchState === ACTIVE}>
          <SearchMasthead
            browseUrl={browseUrl}
            onClickBack={handleClickBack}
            onSearchChange={handleSearchChange}
            onClickDelete={handleClickDelete}
          />
        </SearchContainer>
      );
    }
    const defaultContent = isDesktopOrGreater ? (
      <DesktopContentWrapper>
        <NavigationWrapper>
          <Navigation menuItems={links} size="large" />
          {!isNative && searchDropDownElement}
        </NavigationWrapper>
      </DesktopContentWrapper>
    ) : (
      <>
        {!hasExternalLink && (
          <TitleWrapper>
            <Typography dataTestId="masthead-title" type={typographyType}>
              {title}
            </Typography>
          </TitleWrapper>
        )}
        {!isNative && (
          <SearchIconWrapper data-testid="search-button" onClick={handleClickSearchIcon}>
            <Icon type="search" />
          </SearchIconWrapper>
        )}
      </>
    );
    return (
      <Container data-testid="masthead-container">
        <ExternalLink />
        <Logo title={title} onClick={onLogoClick} />
        {defaultContent}
        {onSettingsClick && (
          <SettingsMenuButton size={menuSize} type={masthead.menuType} onClick={onSettingsClick} />
        )}
      </Container>
    );
  }, [
    links,
    title,
    isMobileSearchMode,
    isDesktopOrGreater,
    isNative,
    searchDropDownElement,
    typographyType,
    browseUrl,
    menuSize,
    searchState,
    masthead,
    hasExternalLink,
    onSettingsClick,
    handleClickSearchIcon,
    handleClickBack,
    handleSearchChange,
    handleClickDelete,
    onLogoClick,
  ]);

  return (
    <OuterWrapper>
      <FlourishContainer flourish={isMobileSearchMode ? 'none' : flourish} background={background}>
        {contentElements}
      </FlourishContainer>
      {isMobileSearchMode && searchDropDownElement}
    </OuterWrapper>
  );
};

Masthead.propTypes = {
  title: PropTypes.string,
  baseUrl: PropTypes.string,
  eventSlug: PropTypes.string,
  packageName: PropTypes.string.isRequired,
  onSettingsClick: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
};

export default withTheme(memo(Masthead), themes, 'masthead');
