import { styled } from 'styled-components';
import { rightEndElementCss, RightEndElementProps } from './Input.styles';

export const IconWrapper = styled.div`
  padding-right: 10px;
`;

type ContainerProps = RightEndElementProps & {
  $centerAlign?: number | string;
  $customWidth?: number | string;
  $elementWidth?: number | string;
};
export const Container = styled.div<ContainerProps>`
  ${rightEndElementCss};
  margin-left: ${({ $centerAlign = 0, $customWidth = 0, $elementWidth }) =>
    `calc(${$centerAlign} + ${$customWidth} - ${$elementWidth})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ theme }) => theme.input.height};
  cursor: pointer;
`;

export const Button = styled.div<{ $width: number | string }>`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  outline: none;
  user-select: none;
  width: ${({ $width }) => $width};
`;

export const OptionWrapper = styled.div<{ $isInline: boolean }>`
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: center;
  outline: none;
  user-select: none;
  top: ${({ theme: { input }, $isInline }) => ($isInline ? '70px' : input.height)};
  background-color: ${({ theme: { input } }) => input.bgColor.default};
  border: 1px solid
    ${({ theme: { input, containerStroke } }) => input.borderColor?.default || containerStroke};
  > option {
    position: relative;
    padding: 4px 0;
    &:hover,
    &:focus {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;
