import { styled, css } from 'styled-components';
import { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';
import { device } from 'znipe-styles/breakpoints';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';

const MEDIUM_ITEM_PADDING = 24;
const SMALL_ITEM_PADDING = 16;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  ${({ showBottomBorder }) =>
    showBottomBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.containerStroke};
    `}

  .indiana-scroll-container--native-scroll {
    overflow: initial;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media ${device.tablet} {
    justify-content: ${({ alignment }) => (alignment === 'left' ? 'flex-start' : 'center')};
  }
  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 0 8px;
    `}
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  height: ${({ size }) => (size === 'medium' ? '24px' : '16px')};
`;

const activeTabItemStyles = () => css`
  color: ${({ theme }) => theme.primaryTextColor};
  ${IconWrapper} {
    color: ${({ theme }) => theme.primaryTextColor};
    @supports (filter: drop-shadow(0px 0px 0px #000000)) {
      filter: drop-shadow(
        0px 0px 6px
          rgba(${props => (props.shadow ? convertHexToRGBDigits(props.shadow) : 'transparent')})
      );
    }
  }
`;

export const TabItem = styled.button.attrs({
  type: 'button',
})`
  ${buttonDefaultStyle}
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryTextColor};
  padding: 0 ${({ size }) => (size === 'medium' ? MEDIUM_ITEM_PADDING : SMALL_ITEM_PADDING)}px;
  &:hover,
  &:focus {
    ${activeTabItemStyles}
  }
  ${({ isActive }) => isActive && activeTabItemStyles}
`;

export const ActiveLine = styled.div`
  width: ${({ width }) => `${width}px`};
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(${({ positionFromLeft }) => positionFromLeft}px);
  transition: 0.3s ease;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: ${({ theme }) => theme.UIAccent};
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const TabsetWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 72px;
  padding: 8px 0;
  @media ${device.desktop} {
    min-height: 88px;
    padding: 16px 0;
  }
`;
