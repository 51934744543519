import { styled, css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import { SMALL, MEDIUM, LARGE, XLARGE } from './AvatarInfo.constants';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.primaryTextColor};
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* stylelint-enable property-no-vendor-prefix, value-no-vendor-prefix */
  overflow: hidden;
  text-align: left;
`;

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.secondaryTextColor};
  margin: 0;
  text-align: left;
  text-transform: capitalize;
`;

export const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  margin-left: ${({ $size }) => {
    switch ($size) {
      case LARGE:
        return '24px';
      default:
        return '16px';
    }
  }};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${({ $size }) => ($size === XLARGE ? '16px' : '8px')};
  }
`;

export const AvatarWrapper = styled.div`
  ${({ $size }) => {
    switch ($size) {
      case XLARGE:
      case LARGE:
        return css`
          width: 88px;
          height: 88px;
        `;
      case MEDIUM:
        return css`
          width: 64px;
          height: 64px;
        `;
      case SMALL:
        return css`
          width: 56px;
          height: 56px;
        `;
      default:
        return css`
          width: 48px;
          height: 48px;
        `;
    }
  }}
`;

export const TagLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.containerStroke};
  border-radius: 2px;
  color: ${({ theme }) => theme.textPrimary};
  padding: ${({ $size }) => {
    switch ($size) {
      case XLARGE:
      case LARGE:
        return '8px 16px';
      case MEDIUM:
        return '6px 12px';
      case SMALL:
        return '4px 8px';
      default:
        return '4px 8px';
    }
  }};
`;
