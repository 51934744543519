import { styled, css } from 'styled-components';
import { BaseContainer, KDAWrapper, borderRadius } from '../Selector.styles';

const small = {
  containerWidth: '56px',
  containerHeight: '72px',
};

const medium = {
  containerWidth: '64px',
  containerHeight: '88px',
};

export const imageSize = {
  small: 32,
  medium: 40,
};

const sizes = { small, medium };

export const Container = styled(BaseContainer)`
  align-items: center;
  ${({ $size }) => {
    const { containerWidth, containerHeight } = sizes[$size] ?? sizes.small;
    return css`
      width: ${containerWidth};
      height: ${containerHeight};
    `;
  }};
`;

export const CenterArea = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-end')};
  flex-direction: column;
  overflow: hidden;

  ${({ $imageType }) =>
    $imageType === 'portrait' &&
    css`
      > ${KDAWrapper} {
        width: 100%;
        position: absolute;
        bottom: 0;
        justify-content: center;
        align-items: end;
      }
    `}
`;

export const ImageArea = styled.div`
  ${({ $imageType, $size }) =>
    $imageType === 'image'
      ? css`
          height: ${imageSize[$size]}px;
          width: ${imageSize[$size]}px;
        `
      : css`
          height: 100%;
          width: 100%;
          border-radius: 0 0 ${borderRadius} ${borderRadius};
          overflow: hidden;
        `}

  > * {
    width: 100%;
    height: 100%;
  }
`;
