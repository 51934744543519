import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-rows: repeat(auto-fit, 25px);
  grid-template-columns: 300px 120px;
  grid-auto-flow: column;
  color: white;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  z-index: 2;
`;

const Row = styled.span`
  height: 25px;
  white-space: nowrap;
`;

const Red = styled.span`
  color: ${({ theme }) => theme.errorLight};
`;

const Green = styled.span`
  color: ${({ theme }) => theme.beanGreen};
`;

const Tab = styled.button`
  background: none;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    css`
      border-bottom: 1px solid white;
    `}
`;

const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 1/3;
  margin-bottom: 8px;
`;

const tabs = ['sync info', 'other info'];

const Debug = ({ info = {} }) => {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const syncInfoTab = useMemo(
    () => (
      <>
        <Row>time diff: {info.timeDiff}</Row>
        <Row>pbr: {info.playbackRate}</Row>
        <Row>
          sync:{' '}
          {info.inSync ? (
            <Green>{info.inSync?.toString()}</Green>
          ) : (
            <Red>{info.inSync?.toString()}</Red>
          )}
        </Row>
        <Row>bitrate: {info.stats?.streamBandwidth}</Row>
        <Row>
          dimensions: {info.stats?.width}x{info.stats?.height}
        </Row>
        <Row>offset: {info.offset}</Row>
        <Row>sync time: {info.videoSyncTime}</Row>
        <Row>date time: {info.dateTime}</Row>
      </>
    ),
    [info],
  );

  const otherInfoTab = useMemo(
    () => (
      <>
        <Row>id: {info.id}</Row>
        <Row>master: {info.master?.toString()}</Row>
        <Row>muted: {info.muted?.toString()}</Row>
        <Row>volume: {info.volume}</Row>
        <Row>time: {info.currentTime}</Row>
        <Row>master time: {info.masterCurrentTime}</Row>
        <Row>dropped frames: {info.stats?.droppedFrames}</Row>
        <Row>
          dropped frames percentage:{' '}
          {((info.stats?.droppedFrames ?? 0) * 100) / (info?.stats?.decodedFrames ?? 1)}%
        </Row>
        <Row>bandwidth: {info.stats?.estimatedBandwidth}</Row>
        <Row>load latency: {info.stats?.loadLatency}</Row>
        <Row>buffering: {info.buffering?.toString()}</Row>
        <Row>paused: {info.paused?.toString()}</Row>
        <Row>buffering time: {info.stats?.bufferingTime}</Row>
        <Row>play time: {info.stats?.playTime}</Row>
        <Row>live latency: {info.stats?.liveLatency}</Row>
      </>
    ),
    [info],
  );

  const tabMap = useMemo(
    () => ({ 'sync info': syncInfoTab, 'other info': otherInfoTab }),
    [syncInfoTab, otherInfoTab],
  );

  return (
    <Wrapper data-testid="debug">
      <TabWrapper>
        {tabs.map(tab => (
          <Tab key={tab} onClick={() => setCurrentTab(tab)} $selected={tab === currentTab}>
            <strong>{tab}</strong>
          </Tab>
        ))}
      </TabWrapper>
      {tabMap[currentTab]}
    </Wrapper>
  );
};

Debug.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.string,
    muted: PropTypes.bool,
    volume: PropTypes.number,
    paused: PropTypes.bool,
    buffering: PropTypes.bool,
    offset: PropTypes.number,
    currentTime: PropTypes.number,
    dateTime: PropTypes.string,
    videoSyncTime: PropTypes.number,
    masterCurrentTime: PropTypes.number,
    timeDiff: PropTypes.number,
    playbackRate: PropTypes.number,
    inSync: PropTypes.bool,
    master: PropTypes.bool,
    stats: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      streamBandwidth: PropTypes.number,
      decodedFrames: PropTypes.number,
      droppedFrames: PropTypes.number,
      estimatedBandwidth: PropTypes.number,
      loadLatency: PropTypes.number,
      bufferingTime: PropTypes.number,
      playTime: PropTypes.number,
      liveLatency: PropTypes.number,
    }),
  }),
};

export default Debug;
