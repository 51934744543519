import { styled, css } from 'styled-components';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import colors from 'znipe-styles/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const FeedWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(100px, 1fr) min-content;
  grid-gap: 5px;
`;

export const Background = styled.div<{ $gradient?: string }>`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 16px;
  pointer-events: none;
  height: 100%;
  width: 100%;
  background: ${({ theme, $gradient }) =>
    $gradient ??
    `radial-gradient(ellipse at top right, ${theme.teamOneColor} 0%, ${theme.secondaryBackgroundColor} 50%)`};
  opacity: 0.25;
`;

const OverlayBaseStyles = css<{ $hideContent?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ $hideContent }) => ($hideContent ? 1 : 0)};
`;

export const OverlayWrapper = styled.div<{ $transparent?: boolean; $hideContent?: boolean }>`
  ${OverlayBaseStyles};
  background: ${({ theme, $transparent }) =>
    $transparent ? 'transparent' : theme.secondaryBackgroundColor};
`;

export const DisabledOverlay = styled.div<{ $hideContent?: boolean }>`
  ${OverlayBaseStyles};
  background: ${convertHexToRGB(colors.gorgeousGrey, 0.9)};
`;
