/* stylelint-disable a11y/no-outline-none */
import { styled, css, keyframes } from 'styled-components';
import { animated } from 'react-spring';
import { Link } from 'react-router-dom';
import colors from 'znipe-styles/colors';
import prefers from 'znipe-styles/prefers';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import convertHexToHSL from 'znipe-utils/misc/convertHexToHSL';
import Typography from 'znipe-elements/general/Typography/Typography';

export const heights = {
  xsmall: '32px',
  small: '40px',
  medium: '48px',
  large: '64px',
};

export const minWidths = {
  xsmall: '120px',
  small: '150px',
  medium: '190px',
  large: '260px',
};

export const paddings = {
  xsmall: '16px',
  small: '24px',
  medium: '32px',
  large: '40px',
};

const border = css`
  border: 1px solid ${({ $color }) => $color};
`;

const dropShadow = css`
  @supports (filter: drop-shadow(0px 0px 8px #1a1a1a)) {
    filter: drop-shadow(0px 0px 8px ${({ $color }) => $color});
  }
`;

export const ButtonTypography = styled(Typography)`
  text-transform: ${({ $textTransform }) => $textTransform};
`;

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: ${({ $iconPosition }) => ($iconPosition === 'right' ? 'row-reverse' : 'row')};

  ${({ $iconShadow }) =>
    $iconShadow &&
    css`
      > div:first-of-type {
        ${dropShadow}
      }
    `}
`;

const baseStyles = css`
  ${({ $isShakeActivated }) =>
    $isShakeActivated &&
    css`
      animation: ${shake} 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    `}
  position: relative;
  background: ${convertHexToRGB(colors.white, 0.1)};
  color: ${({ $textColor, theme }) =>
    theme[$textColor] ?? colors[$textColor] ?? $textColor ?? theme.primaryTextColor};
  height: ${({ $size }) => heights[$size] ?? heights.xsmall};
  min-width: ${({ $size, $compact, $fluid, $customWidth }) => {
    if ($customWidth) return $customWidth;
    if ($compact) return 'none';
    if ($fluid) return '100%';
    return minWidths[$size] ?? minWidths.xsmall;
  }};
  ${({ $square, $size }) => $square && `width: ${heights[$size]};`}
  ${({ $square, $size }) => !$square && `padding: 0 ${paddings[$size] ?? paddings.xsmall};`}
  border: none;
  border-radius: 4px;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  appearance: none;
  user-select: none;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;

  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(10px);
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          opacity: 0.5;
        `
      : css`
          &:hover,
          &:focus-visible {
            outline: none;
            background: ${({ $hoverGradient }) => $hoverGradient};
          }

          &:active {
            outline: none;
            background: ${({ $activeGradient }) => $activeGradient};
          }
        `}
`;

export const cornerPositions = {
  xsmall: '-3px',
  small: '-3px',
  medium: '-3px',
  large: '-3px',
};

const borderSize = {
  xsmall: '12px',
  small: '16px',
  medium: '16px',
  large: '24px',
};

const cornerBorder = css`
  @media ${prefers.motion} {
    transition: all 100ms;
  }

  content: '';
  position: absolute;
  display: block;
  width: ${({ $size }) => borderSize[$size]};
  height: ${({ $size }) => borderSize[$size]};
  border-width: 1px;

  border-color: ${({ $color }) => $color};
`;

const cornerLeft = css`
  border-left-style: solid;
  left: ${({ $size }) => cornerPositions[$size]};
`;

const cornerRight = css`
  border-right-style: solid;
  right: ${({ $size }) => cornerPositions[$size]};
`;

export const TopCornerBorders = styled.div`
  &:before,
  &:after {
    top: ${({ $size }) => cornerPositions[$size]};
    border-top-style: solid;
  }

  &:before {
    border-top-left-radius: 7px;
    ${cornerLeft};
  }

  &:after {
    border-top-right-radius: 7px;
    ${cornerRight};
  }
`;
export const BottomCornerBorders = styled.div`
  &:before,
  &:after {
    bottom: ${({ $size }) => cornerPositions[$size]};
    border-bottom-style: solid;
  }

  &:before {
    border-bottom-left-radius: 7px;
    ${cornerLeft};
  }

  &:after {
    border-bottom-right-radius: 7px;
    ${cornerRight};
  }
`;

const cornerStyles = css`
  ${BottomCornerBorders},
  ${TopCornerBorders} {
    &:before,
    &:after {
      ${cornerBorder};
    }
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus-visible {
        ${BottomCornerBorders},
        ${TopCornerBorders} {
          &:before,
          &:after {
            border-width: 2px;
          }
        }
      }

      &:active {
        ${BottomCornerBorders} {
          &:after {
            bottom: -1px;
            right: -1px;
          }
          &:before {
            bottom: -1px;
            left: -1px;
          }
        }
        ${TopCornerBorders} {
          &:after {
            top: -1px;
            right: -1px;
          }
          &:before {
            top: -1px;
            left: -1px;
          }
        }
      }
    `}
`;

export const BottomBorder = styled(animated.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: ${({ $width }) => $width};
  border: solid 1px
    ${({ $color, theme }) => theme[$color] ?? colors[$color] ?? $color ?? theme.primaryTextColor};
`;

const ghost = css`
  background: transparent;
  backdrop-filter: none;
  border: none;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus,
      &:active {
        outline: none;
        background: none;
      }

      &:active {
        ${BottomBorder} {
          border-width: 2px;
        }
      }
    `}
`;

const solid = css`
  background: ${({ $solidColor, theme }) =>
    theme[$solidColor] ?? colors[$solidColor] ?? $solidColor};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus-visible,
      &:active {
        background: ${({ $solidColor, theme, $lightness = -14 }) => {
          const color = theme[$solidColor] ?? colors[$solidColor] ?? $solidColor;
          return convertHexToHSL(color, { addMode: true, l: $lightness });
        }};
      }
    `}
`;

const variantStyles = css`
  ${({ $variant }) => $variant === 'primary' && border};
  ${({ $variant }) => $variant === 'text' && ghost};
  ${({ $variant }) => $variant === 'feature' && cornerStyles};
  ${({ $variant }) => $variant === 'solid-color' && solid};
`;

export const StyledLink = styled(Link)`
  ${baseStyles};
  ${variantStyles};

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const StyledButton = styled.button`
  ${baseStyles};
  ${variantStyles};
`;

export const ShimmerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Shimmer = styled.div`
  position: absolute;
  height: 50px;
  width: 250px;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(-30deg);
`;
