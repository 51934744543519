import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';

export const themes = {
  proview: {
    hoverBackground: colors.riotBackgroundRule,
  },
  esportal: {
    hoverBackground: colors.esportalContainerStroke,
  },
  default: {
    hoverBackground: colors.esportalContainerStroke,
  },
};

export const FlexContainer = styled.div<{ maxHeight?: number }>`
  display: flex;
  flex-direction: column;

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    `}
`;

type HeaderWrapperProps = {
  headerPadding: string;
};
export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;
  padding: ${props => props.headerPadding};
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
`;

type ItemWrapperProps = {
  menuPadding: string;
  itemIndex: number;
  itemsLength: number;
  customBorderRadius: string;
  isSelected?: boolean;
};
/* Add support theme hover background color */
export const ItemWrapper = styled.div<ItemWrapperProps>`
  padding: ${props => props.menuPadding};
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;
  &:hover,
  &:focus,
  &:active {
    background-color: ${convertHexToRGB(colors.white, 0.1)};
    cursor: pointer;
  }
  ${props =>
    props.isSelected &&
    css`
      border-right: 3px solid ${props.theme.UIAccent};
    `}
  ${props => {
    switch (props.itemIndex) {
      case 0:
        return `
        border-top-left-radius: ${props.customBorderRadius};
        border-top-right-radius: ${props.customBorderRadius};
        `;
      case props.itemsLength - 1:
        return `
        border-bottom-left-radius: ${props.customBorderRadius};
        border-bottom-right-radius: ${props.customBorderRadius};
        `;
      default:
        return '0';
    }
  }};
`;
