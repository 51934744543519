import { memo, forwardRef } from 'react';
import { Container, Image, InnerContainer } from '../Avatar.styles';

const fallbackAvatar = 'https://assets.znipe.tv/icons/Avatar-Fallback.svg';

type UserAvatarProps = {
  image?: string;
  imageAlt?: string;
  hasBorder?: boolean;
  onClick?: () => void;
};

const UserAvatar = forwardRef<HTMLDivElement | null, UserAvatarProps>(
  ({ image = fallbackAvatar, imageAlt, hasBorder = true, onClick }, ref) => (
    <Container onClick={onClick} ref={ref}>
      <InnerContainer data-testid="avatar-inner-container" $hasBorder={hasBorder} $isCircle>
        <Image data-testid="user-avatar-image" src={image} alt={imageAlt} />
      </InnerContainer>
    </Container>
  ),
);

export default memo(UserAvatar);
