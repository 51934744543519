import { memo, useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import StreamPreviewOnHover from 'tv-modules/Player/StreamPreview/StreamPreviewOnHover';
import ToolTip from 'znipe-elements/feedback/ToolTip/ToolTip';
import PremiumIcon from 'znipe-elements/data-display/PremiumIcon/PremiumIcon';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import OnboardingPopup from 'tv-modules/Onboarding/Popup/Popup';
import PopOverMenuList from 'znipe-elements/navigation/PopOverMenuList/PopOverMenuList';
import { playerPropTypes } from 'znipe-player/src/utils/PlayerPropValidation';
import useSubscribePageLink from 'tv-hooks/useSubscribePageLink';
import { globalStreamsPopupInfo } from 'tv-routes/Stage/Stage.constants';
import Icon from 'znipe-elements/general/Icon/Icon';
import StreamsButton from 'znipe-elements/general/buttons/StreamsButton/StreamsButton';
import { streamIcons } from 'tv-modules/constants/gameIconConstants';
import { globalStreamLabels } from 'tv-modules/constants/globalStreamsConstants';
import {
  GlobalStreamItem,
  GlobalStreamItemDescription,
  GlobalStreamItemImage,
  GlobalStreamSubLabel,
  GlobalStreamPremiumWrapper,
  PremiumBorder,
  IconContainer,
} from '../StageMatchBar.styles';

const TOOLTIP_LABEL = 'Currently watching';

const globalStreamsButtonText = (mapStream, eventStream) => {
  if (mapStream.length > 0 && eventStream.length > 0) {
    return 'event stream & map';
  }
  if (eventStream.length > 0) {
    return `event stream${eventStream.length > 1 ? 's' : ''}`;
  }
  if (mapStream.length > 0) {
    return `map stream${mapStream.length > 1 ? 's' : ''}`;
  }
  return null;
};

const GlobalStreamsButton = ({
  matchId,
  selectedMatchGameIndex,
  onStreamItemClick,
  playerRef,
  onStreamPopout,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const globalStreams = useGetGameGlobalStreams({
    matchId,
    selectedMatchGameIndex,
  });

  const mapStreams = globalStreams.filter(stream => stream.type === 'map');
  const eventStreams = globalStreams.filter(stream => stream.type === 'event');
  const hasOnlyOneGlobalStream = globalStreams.length === 1;

  const hasPremiumAccess = useHasPremiumAccess();
  const subscribePageLink = useSubscribePageLink();

  const selectedStreamsIndexes = [];

  const availableGlobalStreams = useMemo(
    () =>
      globalStreams
        .filter(stream => stream.type !== 'audio')
        .map((stream, streamIndex) => {
          const id = stream.id || '';
          const name = stream.name || '';
          const type = stream.type || '';
          const image = stream.imageSrc || '';
          const streamId = stream.streamId || '';
          const isLocked = stream.locked || false;
          const onGlobalClick = () => {
            if (isLocked && !hasPremiumAccess) {
              navigate(subscribePageLink);
            } else onStreamItemClick(streamId);
          };
          const onStreamClick = () => {
            if (!isLocked || (hasPremiumAccess && isLocked)) {
              onStreamItemClick(streamId);
            }
          };
          const isSelected = !!playerStreams.find(currentStream => currentStream?.id === streamId);
          if (isSelected) selectedStreamsIndexes.push(streamIndex);
          return (
            <StreamPreviewOnHover
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              placement="right"
              streamId={streamId}
              playerRef={playerRef}
              onStreamPopout={onStreamPopout}
              disable={isSelected}
              isLocked={isLocked}
              hasPremiumAccess={hasPremiumAccess}
              onStreamItemClick={onStreamClick}
            >
              <PremiumBorder $isPremium={isLocked && !hasPremiumAccess}>
                <ToolTip label={TOOLTIP_LABEL} isVisible={isSelected}>
                  <GlobalStreamItem
                    key={id}
                    $streamIndex={streamIndex}
                    $streamLength={globalStreams.length}
                    onClick={playerStreams.length < 2 && isSelected ? undefined : onGlobalClick}
                  >
                    {image && <GlobalStreamItemImage src={image} alt={`${name}`} />}
                    {!image && (
                      <IconContainer>
                        <Icon type={streamIcons?.[type] || streamIcons.default} size={24} />
                      </IconContainer>
                    )}
                    <GlobalStreamItemDescription>
                      <span>{name}</span>
                      <GlobalStreamSubLabel>{globalStreamLabels[type]}</GlobalStreamSubLabel>
                    </GlobalStreamItemDescription>
                    {isLocked && !hasPremiumAccess && (
                      <GlobalStreamPremiumWrapper $isSelected={isSelected}>
                        <PremiumIcon size="small" />
                      </GlobalStreamPremiumWrapper>
                    )}
                  </GlobalStreamItem>
                </ToolTip>
              </PremiumBorder>
            </StreamPreviewOnHover>
          );
        }),
    [
      globalStreams,
      playerStreams,
      matchId,
      selectedMatchGameIndex,
      playerRef,
      onStreamPopout,
      hasPremiumAccess,
      onStreamItemClick,
      navigate,
      subscribePageLink,
    ],
  );

  const onOpenStateChange = useCallback(isOpen => {
    setOpen(isOpen);
  }, []);

  if (hasOnlyOneGlobalStream) {
    const globalStream = globalStreams[0];
    const isSelected = !!playerStreams.find(
      currentStream => currentStream?.id === globalStream.streamId,
    );

    const clickStreamButton = streamId => {
      if (playerStreams.length < 2 && isSelected) return;
      onStreamItemClick(streamId);
    };

    return (
      <OnboardingPopup
        popupId={globalStreamsPopupInfo.popupId}
        headline={globalStreamsPopupInfo.headline}
        description={globalStreamsPopupInfo.description}
        timer={globalStreamsPopupInfo.timer}
        direction="left"
      >
        <StreamPreviewOnHover
          matchId={globalStream.matchId}
          selectedMatchGameIndex={selectedMatchGameIndex}
          placement="over"
          streamId={globalStream.streamId}
          playerRef={playerRef}
          onStreamPopout={onStreamPopout}
          disable={isSelected}
          isLocked={globalStream.isLocked}
          onStreamItemClick={() => clickStreamButton(globalStream.streamId)}
        >
          <ToolTip label={TOOLTIP_LABEL} placement="over" isVisible={isSelected} isMobile={false}>
            <StreamsButton
              icon="arrowUp"
              isToggledOn={open}
              dataTestId="global-stream-button"
              text={globalStreamsButtonText(mapStreams, eventStreams)}
              size="xsmall"
              titleSize="title-xs"
              onClick={() => clickStreamButton(globalStream.streamId)}
              animation
              smoothAnimation
            />
          </ToolTip>
        </StreamPreviewOnHover>
      </OnboardingPopup>
    );
  }

  const targetElement = (
    <OnboardingPopup
      popupId={globalStreamsPopupInfo.popupId}
      headline={globalStreamsPopupInfo.headline}
      description={globalStreamsPopupInfo.description}
      timer={globalStreamsPopupInfo.timer}
      direction="left"
    >
      <StreamsButton
        icon="arrowUp"
        isToggledOn={open}
        dataTestId="global-stream-button"
        text={globalStreamsButtonText(mapStreams, eventStreams)}
        size="xsmall"
        titleSize="title-xs"
        animation
        smoothAnimation
      />
    </OnboardingPopup>
  );

  return (
    <PopOverMenuList
      position="top"
      align="end"
      menuList={availableGlobalStreams}
      menuPadding="0px 0px"
      selectedItemIndexes={selectedStreamsIndexes}
      onOpenStateChange={onOpenStateChange}
      targetElement={targetElement}
      customBorderRadius="4px"
      useBorder={false}
      animationType="fadeIn"
      fadeDurationMs={200}
    />
  );
};

GlobalStreamsButton.propTypes = {
  matchId: PropTypes.string.isRequired,
  selectedMatchGameIndex: PropTypes.number.isRequired,
  onStreamItemClick: PropTypes.func.isRequired,
  playerRef: playerPropTypes.isRequired,
  onStreamPopout: PropTypes.func.isRequired,
};

export default memo(GlobalStreamsButton);
