import { useEffect, useState } from 'react';
import useChatContext from './useChatContext';
import getTimeDiffAsString from '../utils/getTimeDiffAsString';
import { BanStatus } from '../contexts/ChatContext';

type BanInfo = {
  reason?: string;
  active: boolean;
  time?: string;
};

const createBanInfo = async (banStatus: BanStatus = {}): Promise<BanInfo> => {
  const { reason, banEnd } = banStatus;
  const currentTime = await fetch('https://time.akamai.com?ms')
    .then(res => res.json())
    .then(time => time * 1000);
  const endTime = new Date(banEnd || '').getTime();

  const timeLeft = endTime - currentTime;

  const active = !banEnd || timeLeft > 0;

  return {
    reason,
    active,
    time: getTimeDiffAsString(timeLeft / 1000, true),
  };
};

const POLL_INTERVAL = 60 * 1000;

const useBanInfo = (): BanInfo | null => {
  const { banStatus } = useChatContext() ?? {};
  const [banInfo, setBanInfo] = useState<BanInfo | null>(null);

  useEffect(() => {
    if (!banStatus) {
      setBanInfo(null);
      return undefined;
    }
    const handleBanStatus = async () => {
      const info = await createBanInfo(banStatus);
      setBanInfo(info);
    };

    void handleBanStatus();
    const interval = setInterval(handleBanStatus, POLL_INTERVAL);

    return () => clearInterval(interval);
  }, [banStatus]);

  return banInfo;
};

export default useBanInfo;
