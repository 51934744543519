import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { device } from 'znipe-styles/breakpoints';
import { MEDIUM, LARGE, SOLID, OUTLINE } from './TextOverlay.constants';

export const getFontSize = ({ size }) => {
  switch (size) {
    case LARGE:
      return css`
        font-size: 55px;
        line-height: 55px;
        @media ${device.desktop} {
          font-size: 70px;
          line-height: 70px;
        }
      `;
    case MEDIUM:
      return css`
        font-size: 35px;
        line-height: 35px;
        @media ${device.desktop} {
          font-size: 55px;
          line-height: 55px;
        }
      `;
    default:
      return css`
        font-size: 35px;
        line-height: 35px;
      `;
  }
};

export const Text = styled.h2`
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 35px;
  color: ${({ theme }) => theme.primaryTextColor};
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  width: max-content;
  ${getFontSize}
`;

export const Foreground = styled(Text)`
  transform: translate(${({ translateX }) => `${translateX}px`}, -45%);
  ${({ type }) =>
    type === SOLID &&
    css`
      transform: skew(-20deg) translate(${({ translateX }) => `${translateX}px`}, -45%);
    `};
`;

const OUTLINE_STYLES = css`
  @supports (-webkit-text-stroke: 3px transparent) {
    color: ${({ theme }) => theme.secondaryBackgroundColor};
    background: linear-gradient(
      325.34deg,
      ${({ theme }) => theme.bodyColor} 0%,
      ${({ strokeColor }) => strokeColor} 101.17%
    );
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-stroke: 4px transparent;
    @supports not (-moz-appearance: none) {
      /* Adding extra stroke thickness to Firefox */
      -webkit-text-stroke: 2px transparent;
    }
    /* stylelint-enable property-no-vendor-prefix */
  }
`;

const SOLID_STYLES = css`
  @supports (-webkit-text-stroke: 3px transparent) {
    transform: skew(-20deg);
    padding-right: 10px;
    background: linear-gradient(267.39deg, ${colors.grey65} 3.67%, ${colors.white} 105.93%);
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* stylelint-enable property-no-vendor-prefix */
  }
`;

export const Background = styled(Text)`
  ${({ type }) => type === OUTLINE && OUTLINE_STYLES};
  ${({ type }) => type === SOLID && SOLID_STYLES};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;
