import { ReactNode } from 'react';
import { styled } from 'styled-components';
import PopOverSection from 'znipe-elements/feedback/PopOverSection/PopOverSection';
import { chatPopOverMaxHeight, chatPopOverWidth } from 'znipe-constants/chat';

type ChatPopOverProps = {
  children: ReactNode | ReactNode[];
  targetElement?: ReactNode;
  header?: string;
  size?: 'small' | 'medium' | 'large';
  allowScroll?: boolean;
  onCloseClick?: () => void;
};

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatPopOver: React.FC<ChatPopOverProps> = ({
  children,
  targetElement,
  header,
  size = 'small',
  allowScroll = true,
  onCloseClick,
}) => (
  <PopOverSection
    header={header}
    targetElement={targetElement}
    minWidth={chatPopOverWidth?.[size] || chatPopOverWidth.small}
    maxHeight={chatPopOverMaxHeight?.[size] || chatPopOverMaxHeight.small}
    align="start"
    allowScroll={allowScroll}
    onCloseClick={onCloseClick}
  >
    <ContentLayout>{children}</ContentLayout>
  </PopOverSection>
);

export default ChatPopOver;
