import { styled, css } from 'styled-components';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';

export const tileSizes = {
  small: { padding: '2px', image: '24px' },
  medium: { padding: '4px', image: '32px' },
};

type TileProps = {
  as?: keyof HTMLElementTagNameMap;
  $size: 'small' | 'medium';
  $isLoading: boolean;
};

export const emojiTileFeedbackStyles = css`
  &:focus {
    background: unset;
  }
  &:hover {
    background: ${({ theme }) => theme.tertiaryBackgroundColor};
  }
  &:active {
    background: ${({ theme }) => theme.containerStroke};
  }
`;

export const Tile = styled.div<TileProps>`
  ${({ as }) => as === 'button' && buttonDefaultStyle};
  justify-content: center;
  display: flex;
  border-radius: 3px;
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      img {
        display: none;
      }
    `}
  ${emojiTileFeedbackStyles};
  ${({ $size = 'medium' }) => {
    const { padding, image } = tileSizes?.[$size] ?? tileSizes.medium;
    return css`
      padding: ${padding};
      img,
      svg {
        pointer-events: none;
        width: ${image};
        height: ${image};
      }
    `;
  }}
`;
