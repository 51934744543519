import firebase from 'firebase/app';

const setPersistentState = enabled => {
  const { SESSION, LOCAL } = firebase.auth.Auth.Persistence;
  const staySignedInState = enabled ? LOCAL : SESSION;
  return firebase
    .auth()
    .setPersistence(staySignedInState)
    .catch(() => {
      console.error('Failed to set persistence');
    });
};

export default setPersistentState;
