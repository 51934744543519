import { styled, css } from 'styled-components';

type ContainerProps = {
  $minHeight?: number;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  ${({ $minHeight }) =>
    $minHeight &&
    css`
      min-height: ${$minHeight}px;
    `}
`;

export const EmojiSection = styled(Container)`
  gap: 8px;
`;

export const TilesWrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 8px;
`;
