import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';
import { BaseContainer, borderRadius } from '../Selector.styles';

const flexCenterCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const small = {
  containerWidth: '208px',
  containerHeight: '56px',
  leftWidth: '56px',
  rightWidth: '52px',
};

const medium = {
  containerWidth: '288px',
  containerHeight: '72px',
  leftWidth: '72px',
  rightWidth: '76px',
};

const sizes = { small, medium };

export const Container = styled(BaseContainer)`
  ${({ $size }) => {
    const { containerWidth, containerHeight } = sizes[$size] ?? sizes.small;
    return css`
      width: ${containerWidth};
      height: ${containerHeight};
    `;
  }};
`;

export const AreaDivider = styled.div`
  width: 1px;
  opacity: 0.1;
  background: white;
  height: ${({ $size }) => (sizes[$size] ?? sizes.small).containerHeight};
`;

export const LeftArea = styled.div`
  & > * {
    margin: auto;
  }
  margin-right: 8px;
`;

export const CenterArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RightArea = styled.div`
  width: 52px;
  ${flexCenterCss}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  pointer-events: none;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius};
  ${({ $size }) => {
    const { leftWidth, rightWidth } = sizes[$size] ?? sizes.small;
    return css`
      ${LeftArea} {
        width: ${leftWidth};
      }
      ${RightArea} {
        width: ${rightWidth};
      }
    `;
  }}
  ${/* sc-selector */ LeftArea}, ${/* sc-selector */ CenterArea}, ${/* sc-selector */ RightArea} {
    height: 100%;
  }
`;

export const Marker = styled.div`
  width: 4px;
  height: 100%;
  background: ${({ theme, $color }) => theme[$color] ?? colors.znipeBlue};
`;

export const LeftImage = styled.div`
  width: ${({ $imageWidth }) => $imageWidth}px;
  height: 100%;
  ${flexCenterCss}
`;
