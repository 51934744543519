import { createContext } from 'react';
import type {
  BAN_ACTION,
  BAN_ACTION_PREVIEW,
  UN_BAN_ACTION,
  REMOVED_ACTION,
} from '../components/ModActionCard/ModActionCard';

export type BanAction = {
  avatarId?: string | null;
  color: string;
  username: string;
  userId: string;
  messageId: string;
  location?: string;
};

export type ReportMessage = {
  messageId: string;
  content: string;
  reports: number;
};

export type ModQueueItem = {
  user: {
    username: string;
    id: string;
    imageUrl: string;
    color: string;
    avatarId: string;
  };
  reportedMessages: ReportMessage[];
  numberOfBans: number;
  numberOfRemovals: number;
};

export type Ban = {
  serverMessageId: string;
  id: string;
  type: string;
  module: string;
  timestamp: string;
  user: {
    id: string;
    username: string;
    avatarId: string;
    color: string;
  };
  reason: string;
  createdTimestamp: string;
  message: {
    channel: string;
    content: string;
    serverMessageId: string;
  };
  banEnd?: string;
};

export type LogItem = {
  action:
    | typeof BAN_ACTION
    | typeof BAN_ACTION_PREVIEW
    | typeof UN_BAN_ACTION
    | typeof REMOVED_ACTION;
  ban?: Ban;
  channel: string;
  id: string;
  message?: {
    content: string;
    createdTimestamp: string;
    serverMessageId: string;
  };
  moderator: {
    avatarId: string;
    color: string;
    id: string;
    username: string;
  };
  partnerId: string;
  reason?: string;
  serverTimestamp: string;
  user: {
    id: string;
    imageUrl?: string;
    username: string;
  };
};

export type ChatAdminContextType = {
  banAction: BanAction | null;
  modQueue: ModQueueItem[];
  logItems: LogItem[];
  bans: Ban[];
  setBanAction?: React.Dispatch<React.SetStateAction<BanAction | null>>;
  setModQueue: React.Dispatch<React.SetStateAction<ModQueueItem[]>>;
  setLogItems: React.Dispatch<React.SetStateAction<LogItem[]>>;
  setBans: React.Dispatch<React.SetStateAction<Ban[]>>;
  global: boolean;
};

const ChatAdminContext = createContext<ChatAdminContextType>({
  banAction: null,
  modQueue: [],
  logItems: [],
  bans: [],
  setModQueue: () => {},
  setLogItems: () => {},
  setBans: () => {},
  global: false,
});

export const { Provider, Consumer } = ChatAdminContext;

export default ChatAdminContext;
