import { styled, css } from 'styled-components';

export const PreviewContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100%;
  position: relative;
  border: 1px solid
    ${({ theme, $isSelected }) => ($isSelected ? theme.UIAccent : theme.tertiaryBackgroundColor)};
  border-radius: 3px;
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.containerStroke : theme.tertiaryBackgroundColor};
  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
`;

export const PreviewInnerContainer = styled.div<{ $size: string }>`
  width: 100%;
  height: inherit;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ $size }) => {
    if ($size === 'xlarge')
      return css`
        padding: 11% 0 4.5% 0;
      `;
    if ($size === 'large')
      return css`
        padding: 11% 0 7% 0;
      `;
    if ($size === 'medium')
      return css`
        padding: 12% 0 5% 0;
      `;
    return css`
      padding: 12% 0 8% 0;
    `;
  }}
`;

export const PreviewImageContainer = styled.div`
  height: 70%;
  img {
    height: 100%;
    max-width: 100%;
  }
`;
export const TeamColor = styled.div<{ $isHomeTeam: boolean; $isAwayTeam: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  background: ${({ theme, $isHomeTeam, $isAwayTeam }) => {
    if ($isHomeTeam)
      return `radial-gradient(ellipse at bottom left, ${theme.teamOneColor}, rgba(55, 85, 192, 0))`;
    if ($isAwayTeam)
      return `radial-gradient(ellipse at bottom left, ${theme.teamTwoColor}, rgba(55, 85, 192, 0))`;
    return 'transparent';
  }};
`;
