import { styled, css, keyframes } from 'styled-components';
import prefers from 'znipe-styles/prefers';

const animation = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

export const SVG = styled.svg``;

export const Path = styled.path`
  ${({ $animate, $animationDuration }) =>
    $animate &&
    css`
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      @media ${prefers.motion} {
        animation: ${animation} ${$animationDuration}s linear forwards;
      }
    `}
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  ${SVG} {
    height: 60%;
    width: auto;
  }
`;
