import colors from 'znipe-styles/colors';
import gradients, { getThemableGradients } from 'znipe-styles/gradients';
import { Theme } from './theme.types';

const theme: Theme = {
  themeName: 'default',
  mobileOnly: false,
  primaryFont: "'Titillium Web', sans-serif",
  secondaryFont: "'Roboto', sans-serif",

  // Background Colors
  primaryBackgroundColor: colors.znipeBackgroundPrimary,
  secondaryBackgroundColor: colors.znipeBackgroundSecondary,
  tertiaryBackgroundColor: colors.znipeBackgroundTertiary,
  containerStroke: colors.znipeContainerStroke,
  highlight: colors.znipeIconBlue,

  // Accents
  UIAccent: colors.znipeBlue,
  teamOneColor: colors.znipeBlue,
  teamTwoColor: colors.goldYellow,
  errorRed: colors.velvetCream,
  errorLight: colors.flamingo,
  amberYellow: colors.goldYellow,
  successGreen: colors.trollGreen,

  buttonPrimary: colors.znipeBlue,

  // Text
  textPrimary: colors.white,
  textSecondary: colors.grey75,

  // Gradients
  defaultBackgroundGradientColor: 'rgba(28, 222, 144, 0.16)',
  teamOneGradientColor: 'rgba(222, 47, 47, 0.4)',
  teamTwoGradientColor: 'rgba(21, 128, 182, 0.4)',

  teamAGradient: (gradients as any).teamAGradient, // @TODO fix
  teamBGradient: (gradients as any).teamBGradient, // @TODO fix
  backgroundAvsB: gradients.backgroundAvsB,
  teamABackground: gradients.backgroundTeamA,
  teamBBackground: gradients.backgroundTeamB,

  // TODO cleanup
  secondaryBakgroundFade: gradients.bg2Fade,
  bodyBackground: colors.znipeBackgroundPrimary,
  bodyColor: colors.white,
  borderColor: colors.znipeContainerStroke,
  liveColor: colors.velvetCream,
  primaryTextColor: colors.white,
  primaryTextColorDark: colors.grey20,
  secondaryTextColor: colors.grey75,
  csgoTColor: colors.TYellow,
  csgoCTColor: colors.CTBlue,
  listItemDividerColor: colors.onyxGrey,
  overlayBackground: 'rgba(0,0,0,0.75)',
  popOverBackground: colors.znipeContainerStroke,
  containerGradientBackground: gradients.subtleContainer,
  videoBackground: 'https://assets.znipe.tv/anna-concepts/caro-csgo-bg-FINALfinal.mp4',
  // old styles below - needs ux review
  headerColor: colors.riotBackgroundRule,
  teamOneColorSecondary: colors.riotTeamOrderSecondary,
  teamTwoColorSecondary: colors.riotTeamChaosSecondary,
  accentGold: colors.riotAccentGold,
  healthColor: 'green',
  manaColor: '#0089FF',
  progressBarBackground: colors.onyxGrey,
  progressBarBaseHandleColor: colors.riotTextPrimary,
  hoverColor: colors.riotAccentBlue,
  navMenuPadding: '0px',
  navMenuBackgroundColor: colors.black,
  navMenuItemColorActive: colors.riotAccentBlue,
  navMenuItemColorActiveText: colors.white,
  navMenuItemColor: colors.riotTextSecondary,
  navMenuItemFontWeightActive: 400,
  navMenuItemFontWeight: 400,
  navMenuItemFontSizeLarge: '15px',
  navMenuItemFontSizeSmall: '13px',
  navMenuItemCaps: false,
  navMenuItemMargin: '0px',
  avatarXXSmallSize: '16px',
  avatarXSmallSize: '30px',
  avatarSmallSize: '32px',
  avatarMediumSize: '40px',
  avatarLargeSize: '48px',
  avatarCustomSize: '45px',
  avatarXSmallSizeRound: '10px',
  avatarSmallSizeRound: '24px',
  avatarMediumSizeRound: '44px',
  avatarLargeSizeRound: '54px',
  avatarBorderActiveColor: colors.riotAccentBlue,
  avatarLevelTextColor: colors.white,
  avatarLevelFont: "'Source Sans Pro', sans-serif",
  avatarBackgroundColor: colors.onyxGrey,
  avatarLevelCircleBorderColor: colors.riotBackgroundRule,
  avatarLevelCircleBackgroundColor: colors.black,
  avatarPlaceholderBackgroundColor: colors.riotTextSecondary,
  shouldZoomInAvatarImage: true,
  gatTeamOneAvatarBorderColor: colors.riotTeamOrderPrimary,
  gatTeamTwoAvatarBorderColor: colors.riotTeamChaosPrimary,
  gatBaronTimerBarColor: gradients.handOfBaron,
  ruleBackgroundColor: colors.riotBackgroundRule,
  articleBackgroundColor: colors.riotBackgroundArticle,
  blue: colors.riotAccentBlue,
  gold: colors.riotAccentGold,
  orange: colors.riotAccentOrange,
  settingsActiveColor: colors.riotAccentBlue,
  ternaryBackgroundColor: colors.riotBackgroundSecondary,
  poweredByZnipeLogoWidth: '150px',
  optionsMenuHeaderColor: colors.white,
  optionsMenuActiveColor: colors.riotAccentBlue,
  disabledIconsColor: colors.riotBackgroundRule,
  scoreboardIconColor: colors.riotAccentGold,
  disabledTextColor: '#666E75',
  winnerColor: '#c48a27',
  playerStatsButtonArrowDisplay: 'none',
  playerStatsTitlePadding: '0',
  playerStatsRowHeight: '26px',
  playerStatsWrapperPadding: '0',
  playerStatsIconPadding: '0',
  playerStatsFontSize: '14px',
  playerStatsFontWeight: '400',
  playerStatsHoverSvgFill: colors.riotAccentBlue,
  chatBackgroundColor: colors.znipeBackgroundTertiary,
  chatFooterBackgroundColor: colors.znipeBackgroundPrimary,
  chatTimeStampBorderColor: colors.znipeContainerStroke,
  chatTimeStampTextColor: colors.grey90,
  chatFooterColor: colors.znipeIconBlue,
  gatCategory: colors.goldYellow,
  gatTextColor: colors.white,
  popOverAreaText: colors.midGrey,
  kdaSpacing: '0px 2px',
  kdaLineHeight: '16px',
  roleIconColor: colors.riotAccentGold,

  /* bar chart */
  barChartLabelTextColor: colors.white,
  barChartFontFamily: 'Titillium Web',
  barChartFontSize: 12,
  barChartGridCount: 3,
  barChartGridOpacity: 0.1,

  /* line graph */
  lineGraphAxisTickColor: colors.white,
  lineGraphAxisTicksLineColor: 'transparent',
  lineGraphAxisTicksTextColor: colors.white,
  lineGraphGridLineColor: colors.white,
  lineGraphGridLineOpacity: 0.1,
  lineGraphAreaOpacity: 0.3,
  lineGraphLegendTextColor: colors.white,
  lineGraphTooltipBackgroundColor: colors.onyxGrey,

  // InputField
  placeholderText: colors.grey65,

  // Score
  scoreFont: "'Roboto', sans-serif",

  // StageMatchBar
  stageMatchBarBgColor: colors.riotBackgroundSecondary,

  // LOLChampionHeader
  LOLChampionHeaderAccentColor: colors.riotAccentGold,

  /* overlay */
  overlayBackgroundColor: colors.riotBackgroundSecondary,
  overlayBackgroundOpacity: 0.6,

  /* carousel */
  carouselNavigatonDotColor: colors.spaceGreen,

  // LOLChampionAbilities
  LOLChampionAbilitiesTextColor: colors.white,

  /* Stats runes */
  runesLabelColor: colors.riotBackgroundArticle,
  runeVerticalLineBackgroundColor: colors.riotBackgroundSecondary,
  runeVerticalLineBorderColor: colors.riotBackgroundRule,

  /* Scrollbar */
  scrollbarColor: colors.riotBackgroundPrimary,

  // a11y colors
  grey3: colors.grey75,

  // Feature Buttons
  primaryButtonBackground: '#373b43',

  // Global Library Colors
  tooBlue: colors.tooBlue,
  pastelYellow: colors.pastelYellow,
  bubblePink: colors.bubblePink,
  beanGreen: colors.beanGreen,
  lavender: colors.lavender,

  // Per Page Color Scheme
  colorSchemeMap: {
    home: {
      gradient: gradients.seaBreeze,
      rootColor: colors.tooBlue,
      hoverGradient: gradients.lavenderHover,
      activeGradient: gradients.lavenderActive,
    },
    matches: {
      gradient: gradients.plushPurple,
      rootColor: colors.lavender,
      hoverGradient: gradients.lavenderHover,
      activeGradient: gradients.lavenderActive,
    },
    stage: {
      gradient: gradients.plushPurple,
      rootColor: colors.lavender,
      hoverGradient: gradients.lavenderHover,
      activeGradient: gradients.lavenderActive,
    },
    standings: {
      gradient: gradients.cottonCandy,
      rootColor: colors.bubblePink,
      hoverGradient: gradients.pinkHover,
      activeGradient: gradients.pinkActive,
    },
    browse: {
      gradient: gradients.sourApple,
      rootColor: colors.beanGreen,
      hoverGradient: gradients.lavenderHover,
      activeGradient: gradients.lavenderActive,
    },
    highlights: {
      gradient: gradients.peachParty,
      rootColor: colors.pastelYellow,
      hoverGradient: gradients.yellowHover,
      activeGradient: gradients.yellowActive,
    },
    default: {
      gradient: gradients.seaBreeze,
      rootColor: colors.tooBlue,
      hoverGradient: gradients.blueHover,
      activeGradient: gradients.blueActive,
    },
  },
  stagingGradient: gradients.rubySea,
  productionGradient: gradients.twillightOcean,

  // masthead
  mastheadGradient: gradients.unspecifiedGradient,

  /* onboarding */
  onboardingStroke: colors.goldYellow,
};

export default { ...theme, ...getThemableGradients(theme) } as Theme; // @TODO remove type assertion once function has proper return type
