import { memo, forwardRef, useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from 'styled-components';
import withTheme from 'znipe-themes/hocs/withTheme';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Container,
  Wrapper,
  Required,
  Label,
  inputStyles,
  InlineLabelContent,
  InlineLabel,
  MaxLength,
  Comment,
  InputStylesProps,
} from 'znipe-elements/data-entry/Input/Input.styles';
import themes from 'znipe-elements/data-entry/Input/Input.themes';

const Textarea = styled.textarea<InputStylesProps>`
  ${inputStyles};
  padding-top: ${({ $isInline, theme: { input } }) => ($isInline ? '30px' : input.verticalPadding)};
  height: initial;
  border: 1px solid
    ${({ $error, theme: { containerStroke, errorRed } }) => ($error ? errorRed : containerStroke)};
  resize: none;
`;

type TextInputProps = {
  label?: string;
  rows?: number;
  name?: string;
  labelPosition?: 'inline' | 'left' | 'top';
  labelAlign?: 'flex-start' | 'flex-end' | 'center';
  centerAlign?: string;
  customWidth?: string;
  defaultValue?: string;
  comment?: React.ReactNode;
  active?: boolean;
  isRequired?: boolean;
  showLengthCount?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
  error?: boolean;
};

const TextInput = forwardRef<HTMLTextAreaElement, TextInputProps>(
  (
    {
      maxLength,
      name,
      label,
      rows = 6,
      error = false,
      active = false,
      isRequired = false,
      showLengthCount = false,
      labelPosition = 'left',
      labelAlign = 'flex-start',
      onChange,
      centerAlign = '',
      customWidth = '',
      defaultValue = '',
      comment = '',
    },
    ref,
  ) => {
    const [data, setData] = useState(defaultValue);
    const [currentLength, setCurrentLength] = useState(0);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) onChange(e);
        if (e.target) {
          setData(e.target.value);
          setCurrentLength(e.target.value.length);
        }
      },
      [onChange],
    );

    useEffect(() => {
      setCurrentLength(defaultValue.length);
    }, [defaultValue]);

    const getMaxLengthCounter = () => {
      if (showLengthCount && maxLength) {
        const remainingLength = maxLength - currentLength;
        return <MaxLength>{`${remainingLength}/${maxLength}`}</MaxLength>;
      }
      return null;
    };

    const isInline = Boolean(label && labelPosition === 'inline');
    const isHorizontal = Boolean(label && labelPosition === 'top');

    const commentElement = useMemo(
      () =>
        comment && (
          <Comment $centerAlign={centerAlign} $customWidth={customWidth}>
            <Typography type="paragraph-s">{comment}</Typography>
          </Comment>
        ),
      [comment, centerAlign, customWidth],
    );

    return (
      <Container>
        <Wrapper data-testid="text-input" $isHorizontal={isHorizontal}>
          {!isInline && label && (
            <Label
              data-testid={`label-${labelPosition}`}
              $error={error}
              $centerAlign={centerAlign}
              $labelAlign={labelAlign}
            >
              <Typography type="heading-xs">{label}</Typography>
              {isRequired && <Required />}
            </Label>
          )}
          {isHorizontal && commentElement}
          <Textarea
            name={name}
            data-testid="input"
            $error={error}
            autoComplete="autocomplete-off"
            $isInline={isInline}
            ref={ref}
            onChange={handleChange}
            maxLength={maxLength}
            rows={rows}
            $centerAlign={centerAlign}
            $customWidth={customWidth}
          />
          {isInline && label && (
            <InlineLabel data-testid="label-inline" $error={error}>
              <InlineLabelContent $hasData={active || data.length > 0}>
                <Typography type="heading-xs">{label}</Typography>
                {isRequired && <Required />}
              </InlineLabelContent>
            </InlineLabel>
          )}
          {getMaxLengthCounter()}
        </Wrapper>
        {!isHorizontal && commentElement}
      </Container>
    );
  },
);

const DefaultInput = withTheme(memo(TextInput), themes, 'input');

export default DefaultInput;
