import { useState, useEffect, useMemo } from 'react';
import cj from 'cj';
import AnalyticsManager from 'znipe-analytics-manager';
import isBrowser from 'znipe-utils/web/isBrowser';
import ZnipeEventTarget from 'znipe-utils/events/ZnipeEventTarget';
import createStore from '../utils/chatStore';
import {
  AlertData,
  Avatar,
  BanStatus,
  ChatStatus,
  ModalInfo,
  Provider,
  UserData,
  FeaturedAvatar,
} from '../contexts/ChatContext';
import useEmojis from '../hooks/useEmojis';
import useAvatarImage from '../hooks/useAvatarImage';
import useNameColors from '../hooks/useNameColors';
import useUserSignifiers from '../hooks/useUserSignifiers';
import type { AccountInfo } from 'znipe-chat/src/hooks/useAccountInfo';
import type { ReplyTo } from '../utils/chatStore';

const useSettingsChange = (
  value: string | null,
  type: string,
  onSettingsChange?: (value: string | null, type: string) => void,
) => {
  useEffect(() => {
    if (onSettingsChange && type) onSettingsChange(value, type);
  }, [onSettingsChange, value, type]);
};

type ChatProviderProps = {
  children?: React.ReactNode;
  authManagerId?: string;
  chatId?: string;
  authToken?: string;
  avatars?: Avatar[];
  color?: string;
  featuredAvatars?: FeaturedAvatar[];
  account?: AccountInfo;
  avatar?: string;
  onSettingsChange?: (value: string | null, type: string) => void;
  onUsernameClick?: (userdata: UserData) => void;
  onLoginClick?: () => void;
  onAvatarClick?: (avatarId: string) => void;
  onAccountClick?: (accountId: string) => void;
  emojiSet?: string[];
  eventTarget?: ZnipeEventTarget;
};

const ChatProvider: React.FC<ChatProviderProps> = ({
  children,
  chatId,
  authToken,
  avatars,
  color: providedColor = '',
  featuredAvatars,
  account,
  avatar: providedAvatar = '',
  onSettingsChange,
  onLoginClick,
  authManagerId,
  onUsernameClick,
  emojiSet,
  eventTarget,
}) => {
  const accountInfo = useMemo(
    () => ({
      isModerator: false,
      uid: '',
      username: '',
      ...(account ?? {}),
    }),
    [account],
  );

  const analyticsManager = useMemo(
    () => new AnalyticsManager('chat', authManagerId),
    [authManagerId],
  );

  const { allEmojis, quickSelectEmojis } = useEmojis(emojiSet);
  const nameColors = useNameColors(authManagerId);
  const { chatSignifiers, signifierIds } = useUserSignifiers(authManagerId);

  const chatApp = useMemo(() => cj('chat'), []);

  const [usernameColor, setUsernameColor] = useState(providedColor);
  const [chatStatus, setChatStatus] = useState<ChatStatus>({
    disabled: false,
    spamFilter: undefined,
  });
  const [avatar, setUserAvatar] = useState(providedAvatar);
  const [banStatus, setBanStatus] = useState<BanStatus | null>(null);
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [modalInfo, setModalInfo] = useState<ModalInfo>({});
  const [replyData, setReplyData] = useState<ReplyTo | null>(null);

  const avatarId = useMemo(() => {
    if (!avatar) {
      return null;
    }
    return avatars?.find(a => a.id === avatar)?.id ?? '';
  }, [avatars, avatar]);

  const verifiedUsernameColor = useMemo(() => {
    const unlockedHexCode = nameColors
      .filter(({ locked }) => !locked)
      .map(({ hexCode }) => hexCode);
    if (unlockedHexCode.includes(usernameColor)) return usernameColor;
    return unlockedHexCode[Math.floor(Math.random() * unlockedHexCode.length)];
  }, [nameColors, usernameColor]);

  useSettingsChange(verifiedUsernameColor, 'color', onSettingsChange);
  useSettingsChange(avatarId, 'avatarId', onSettingsChange);

  // Setup the connection with the chat back end
  const chatStore = useMemo(() => createStore(), []);
  const chatChannel = useMemo(() => {
    if (!chatId || !isBrowser()) return undefined;
    return chatApp.ref(chatId);
  }, [chatId, chatApp]);

  useEffect(() => {
    if (!isBrowser() || !authToken) return;
    chatApp.authenticate(authToken);
  }, [authToken, chatApp]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Sign out on unmount
  useEffect(() => () => chatApp?.unauthenticate(), []);

  useEffect(() => {
    if (chatStatus.disabled) {
      chatStore.clearChat();
    }
  }, [chatStatus, chatStore]);

  const userAvatar = useAvatarImage(avatarId, false, avatars);

  return (
    <Provider
      value={{
        onLoginClick,
        onUsernameClick,
        chatStatus,
        chatChannel,
        chatStore,
        chatId,
        authToken,
        accountInfo,
        usernameColor: verifiedUsernameColor,
        userAvatar,
        avatars,
        featuredAvatars,
        banStatus,
        alert,
        modalInfo,
        replyData,
        nameColors,
        chatSignifiers,
        signifierIds,
        allEmojis,
        quickSelectEmojis,
        setChatStatus,
        setUsernameColor,
        setUserAvatar,
        setBanStatus,
        setAlert,
        setModalInfo,
        setReplyData,
        avatarId,
        authManagerId,
        analyticsManager,
        emojiSet,
        eventTarget,
      }}
    >
      {children}
    </Provider>
  );
};

export default ChatProvider;
