import isBrowser from 'znipe-utils/web/isBrowser';
import registerZnipeGlobal from 'znipe-utils/web/registerZnipeGlobal';
import { BehaviorSubject } from 'rxjs';

type AuthResponse = {
  message: string;
  results: {
    id: string;
    username: string | null;
    partnerId: string;
    customization?: {
      privileges: Array<string>;
      chatColorSets: Array<string>;
      chatSignifiers: Array<string>;
    };
  };
};

export type UserSubject = {
  username?: string | null;
  userId?: string;
  authToken?: string;
  roles?: Array<string>;
  privileges?: Array<string>;
  chatColorSets?: Array<string>;
  chatSignifiers?: Array<string>;
};

type AuthManagers = {
  [key: string]: AuthManager | undefined;
};

const DEFAULT_ID = 'znipe-auth-manager';

class AuthManager {
  static authManagers: AuthManagers = {};

  static getAuthManager(id = DEFAULT_ID) {
    return AuthManager.authManagers?.[id];
  }

  private subject = new BehaviorSubject<UserSubject>({});

  constructor(id = DEFAULT_ID) {
    if (!isBrowser()) return;
    if (AuthManager.authManagers[id]) {
      throw Error(`Auth manager with id ${id} already exists`);
    }
    AuthManager.authManagers[id] = this;
  }

  private reset() {
    this.subject.next({});
  }

  public async authenticate(authToken?: string) {
    if (!authToken) {
      this.reset();
      return;
    }
    try {
      const response = await fetch(`${process.env.QUARTERBACK_API_URL}/resolve-user`, {
        headers: {
          Authorization: `bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        this.reset();
        return;
      }
      const { results }: AuthResponse = await response.json();
      const { username, id: userId, customization } = results;
      const { privileges = [], chatColorSets = [], chatSignifiers = [] } = customization ?? {};
      this.subject.next({
        username,
        userId,
        authToken,
        privileges,
        chatColorSets,
        chatSignifiers,
      });
    } catch (_e) {
      this.reset();
    }
  }

  public getUsername() {
    return this.subject.value.username;
  }

  public getUserId() {
    return this.subject.value.userId;
  }

  public getAuthToken() {
    return this.subject.value.authToken;
  }

  public getUserSubject() {
    return this.subject;
  }

  public hasPrivilege(privilege: string) {
    return this.subject.value.privileges?.includes(privilege);
  }

  public getChatColorSets() {
    return this.subject.value.chatColorSets;
  }
}

export type AuthManagerType = typeof AuthManager;

export default registerZnipeGlobal('AuthManager', AuthManager);
