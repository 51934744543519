import { useState, useCallback, useMemo, memo, useRef, useEffect, useLayoutEffect } from 'react';
import useOutsideClick from 'znipe-hooks/useOutsideClick';
import withTheme from 'znipe-themes/hocs/withTheme';
import { Container, PopOverArea, PopOverTargetWrapper, themes } from './PopOver.styles';

type PopOverProps = {
  targetElement: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'start' | 'center' | 'end';
  minWidth?: string;
  popOverAreaBackground?: string;
  children?: React.ReactNode;
  externalOpenState?: boolean;
  onOpenStateChange?: (value: boolean) => void;
  useBorderRadius?: boolean;
  animationType?: 'fadeInFadeOut' | 'fadeIn' | 'none';
  fadeDurationMs?: number;
  customBorderRadius?: string;
  useBorder?: boolean;
  marginOffset?: number;
  disableLayoutEffect?: boolean;
};

const PopOver: React.FC<PopOverProps> = ({
  targetElement,
  position = 'top',
  align = 'center',
  minWidth = '210px',
  children = null,
  onOpenStateChange,
  popOverAreaBackground = '',
  externalOpenState,
  useBorder = true,
  useBorderRadius = false,
  customBorderRadius = '',
  animationType = 'none',
  fadeDurationMs = 3000,
  marginOffset = 10,
  disableLayoutEffect = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [contentWidth, setContentWidth] = useState<number>(0);

  const isOpen = useMemo(() => {
    if (typeof externalOpenState !== 'undefined') {
      return externalOpenState;
    }
    return open;
  }, [externalOpenState, open]);

  const handleOpenStateChange = useCallback(
    (value: boolean) => {
      setOpen(value);
      return onOpenStateChange && onOpenStateChange(value);
    },
    [onOpenStateChange],
  );

  const toggleOpenState = useCallback(
    () => handleOpenStateChange(!isOpen),
    [handleOpenStateChange, isOpen],
  );

  const onOutsideClick = useCallback(() => {
    handleOpenStateChange(false);
  }, [handleOpenStateChange]);

  const ref = useOutsideClick(onOutsideClick) as React.RefObject<HTMLDivElement>;
  const popOverAreaRef = useRef<HTMLDivElement | null>(null);

  const resizeHook = useCallback(() => {
    const currentPopAreaRef = popOverAreaRef.current;
    if (!currentPopAreaRef) return;
    const popoverAreaHeight = currentPopAreaRef.offsetHeight || 0;
    const popoverAreaWidth = currentPopAreaRef.offsetWidth || 0;
    if (contentHeight !== popoverAreaHeight) setContentHeight(popoverAreaHeight);
    if (contentWidth !== popoverAreaWidth) setContentWidth(popoverAreaWidth);
  }, [contentHeight, contentWidth]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Resize when it's opened
  useEffect(() => {
    if (disableLayoutEffect) resizeHook();
  }, [isOpen, disableLayoutEffect, resizeHook]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Resize when it's opened
  useLayoutEffect(() => {
    if (!disableLayoutEffect) resizeHook();
  }, [isOpen, disableLayoutEffect, resizeHook]);

  return (
    <Container ref={ref}>
      {isOpen && (
        <PopOverArea
          ref={popOverAreaRef}
          minWidth={minWidth}
          position={position}
          align={align}
          data-testid="popover-area"
          popOverAreaBackground={popOverAreaBackground}
          popoverAreaHeight={contentHeight}
          popoverAreaWidth={contentWidth}
          useBorder={useBorder}
          useBorderRadius={useBorderRadius}
          animationType={animationType}
          customBorderRadius={customBorderRadius}
          fadeDurationMs={fadeDurationMs}
          marginOffset={marginOffset}
        >
          {children}
        </PopOverArea>
      )}
      <PopOverTargetWrapper
        role="button"
        tabIndex={0}
        onClick={toggleOpenState}
        data-testid="popover-button"
      >
        {targetElement}
      </PopOverTargetWrapper>
    </Container>
  );
};

export default withTheme(memo(PopOver), themes, 'popOver');
