import { useState, useEffect, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import useGqlStoreDispatch from 'tv-hooks/useGqlStoreDispatch';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import { useGetGameInitialPlayers } from 'tv-selectors/games/makeGetGameInitialPlayers';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetStreams } from 'tv-selectors/streams/makeGetStreams';
import { useIsIOS } from 'tv-selectors/deviceInfo/makeGetIsIOS';
import { useCasterMute } from 'tv-selectors/control/makeGetCasterMute';
import { useGetGameGlobalStreams } from 'tv-selectors/games/makeGetGameGlobalStreams';
import { useStreamLanguage } from 'tv-selectors/ui/makeGetStreamLanguage';
import useGameStreamOffsets from 'tv-hooks/useGameStreamOffsets';
import usePrevious from 'znipe-hooks/usePrevious';
import buildStreamObject from 'tv-utils/buildStreamObject';
import { setMatchPlayerStreams } from 'tv-actions/matches';
import { useShouldShowOnboardingStep } from 'tv-selectors/auth/makeGetShouldShowOnboardingStep';
import { STAGE_WELCOME_STEP_ID } from 'tv-routes/Stage/Stage.constants';

const emptyObject = {};

const useSetupMatchInfo = (matchId, selectedMatchGameIndex) => {
  const [initialized, setInitialized] = useState(false);
  const gameId = useGetGameId({ matchId, selectedMatchGameIndex });
  const playerStreams = useGetMatchPlayerStreams({ matchId });
  const isIOS = useIsIOS();
  const gqlDispatch = useGqlStoreDispatch();
  const offsets = useGameStreamOffsets(matchId, gameId) || emptyObject;
  const prevOffsets = usePrevious(offsets);
  const streamsObject = useGetStreams();
  const casterMute = useCasterMute();
  const globalStreams = useGetGameGlobalStreams({ matchId, selectedMatchGameIndex });
  const language = useStreamLanguage();
  const initialPlayers = useGetGameInitialPlayers({ matchId, selectedMatchGameIndex });
  const showStageWelcome = useShouldShowOnboardingStep({ stepId: STAGE_WELCOME_STEP_ID });

  // reset initial stream on matchId or gameId change
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setInitialized(false);
  }, [matchId, gameId]);

  // setup the initial match values
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (
      initialized ||
      !matchId ||
      (!selectedMatchGameIndex && selectedMatchGameIndex !== 0) ||
      !gameId ||
      !offsets
    )
      return;

    if (showStageWelcome) {
      const eventStream = globalStreams.find(stream => stream.type === 'event');
      if (!eventStream) return;
      const { streamId } = eventStream;
      const currentStream = streamsObject[streamId] || {};
      const currentStreamInformation = currentStream.hls || currentStream.dash;
      const { tokenExpireTime } = currentStream;

      const streamObject = buildStreamObject({
        streamId,
        initialSoundFrom: streamId,
        offsets,
        tokenExpireTime,
        ...currentStreamInformation,
      });
      if (!streamObject) return;
      setInitialized(true);
      gqlDispatch(setMatchPlayerStreams(matchId, [streamObject]));
      return;
    }

    const initialSoundFrom = initialPlayers[0] || '';

    const initialStreams = initialPlayers.reduce((acc, streamId) => {
      const currentStream = streamsObject[streamId] || {};
      const currentStreamInformation = currentStream.hls || currentStream.dash;
      const { tokenExpireTime } = currentStream;
      if (!currentStreamInformation) return acc;

      const streamObject = buildStreamObject({
        streamId,
        initialSoundFrom,
        offsets,
        tokenExpireTime,
        ...currentStreamInformation,
      });

      if (streamObject) acc.push(streamObject);
      return acc;
    }, []);

    gqlDispatch(setMatchPlayerStreams(matchId, initialStreams));
    if (initialStreams.length > 0) {
      setInitialized(true);
    }
  }, [
    matchId,
    selectedMatchGameIndex,
    gameId,
    initialized,
    initialPlayers,
    streamsObject,
    offsets,
    isIOS,
    gqlDispatch,
    globalStreams,
    showStageWelcome,
  ]);

  const playCasterAudio = useMemo(() => {
    if (casterMute) return false;
    const master = playerStreams.find(stream => stream?.master);
    if (!master) return false;
    const globalMaster = globalStreams.find(stream => stream.streamId === master.id);
    if (!globalMaster) return true;
    return globalMaster.type !== 'event';
  }, [playerStreams, casterMute, globalStreams]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    const audioStreams = globalStreams.filter(stream => stream.type === 'audio');
    const audioStream = !casterMute
      ? audioStreams.find(s => s.language === language) ||
        audioStreams.find(s => s.language === 'en_us') ||
        audioStreams[0] ||
        {}
      : {};

    const audioStreamId = audioStream.streamId;
    const currentStream = streamsObject[audioStreamId] || {};
    const { tokenExpireTime } = currentStream;
    const streamInfo = currentStream.hls || currentStream.dash || {};

    const streamObj = buildStreamObject({
      streamId: audioStreamId,
      offsets,
      audioOnly: true,
      tokenExpireTime,
      ...streamInfo,
    });

    const defaultValue = playCasterAudio ? [streamObj] : [];
    const updatedStreams = playerStreams.reduce((acc, stream) => {
      const audioOnly = stream.audioOnly || false;
      if (!audioOnly) {
        acc.push(stream);
        return acc;
      }
      if (!playCasterAudio) return acc;
      const currentStreamId = stream.id;
      if (currentStreamId !== audioStreamId) return acc;
      if (acc.find(s => (s || {}).id === currentStreamId)) return acc; // Do not add duplicates
      acc.push(stream);
      return acc;
    }, defaultValue);
    if (updatedStreams.length === 0) return;
    gqlDispatch(setMatchPlayerStreams(matchId, updatedStreams));
  }, [language, playCasterAudio, gqlDispatch]);

  // listen for offset changes and update current streams
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (Object.keys(offsets).length === 0 || isEqual(prevOffsets, offsets)) return;
    const updatedStreams = playerStreams.map(stream => {
      const currentStream = streamsObject[stream.id] ?? {};
      const currentStreamInformation = currentStream.hls || currentStream.dash || {};
      const srcOffsetId = currentStreamInformation.srcOffsetId || '';
      const streamOffset = offsets[srcOffsetId] || 0;
      return { ...stream, offset: streamOffset };
    });
    if (updatedStreams.length === 0) return;
    gqlDispatch(setMatchPlayerStreams(matchId, updatedStreams));
  }, [matchId, streamsObject, offsets, prevOffsets, playerStreams, isIOS, gqlDispatch]);

  return playerStreams;
};

export default useSetupMatchInfo;
