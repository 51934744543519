import styled from 'styled-components';
import prefers from 'znipe-styles/prefers';

export const BackgroundLayer = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0%;
  @media ${prefers.motion} {
    transition: 0.2s ease;
  }
  will-change: transform;
  background: ${({ theme }) => theme.secondaryTextColor};
`;
