import { forwardRef } from 'react';
import withTheme from 'znipe-themes/hocs/withTheme';
import types from './Typography.styles';
import themes from './Typography.themes';

export type TypographyProps<T extends React.ElementType = React.ElementType> = {
  dataTestId?: string;
  'data-testid'?: string;
  className?: string;
  domType?: string;
  type: keyof typeof types;
  color?: string;
  italic?: boolean;
  as?: T;
  children?: React.ReactNode;
  align?: 'left' | 'right' | 'center';
  transform?: 'uppercase' | 'lowercase' | 'capitalize';
} & React.ComponentPropsWithoutRef<T>;

const Typography = forwardRef<HTMLHeadingElement, TypographyProps>(
  // @ts-ignore
  (
    {
      dataTestId,
      className,
      type,
      as,
      color,
      italic = false,
      children,
      align,
      domType,
      ...props
    }: TypographyProps,
    ref,
  ) => {
    const Component = types[type] as any; // @TODO figure out why type for 'as' doesn't work

    return (
      <Component
        {...props}
        ref={ref}
        data-testid={dataTestId ?? props['data-testid']}
        as={as}
        $color={color}
        className={className}
        $align={align}
        type={domType}
        $italic={italic}
      >
        {children}
      </Component>
    );
  },
);

export const typeNames = Object.keys(types);

export default withTheme(Typography, themes, 'typography');
