import { useMemo } from 'react';
import PropTypes from 'prop-types';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Icon from 'znipe-elements/general/Icon/Icon';
import LazyElement from 'znipe-elements/feedback/LazyElement/LazyElement';
import Typography from 'znipe-elements/general/Typography/Typography';
import withTheme from 'znipe-themes/hocs/withTheme';
import { ImageWrapper, LazyWrapper, TextWrapper, Container, themes } from './TeamLogo.styles';

const getTypographySize = (size, longName) => {
  switch (size) {
    case 'xlarge':
      return longName ? 'heading-l' : 'title-xxl';
    case 'large':
      return longName ? 'heading-m' : 'title-xl';
    case 'medium':
      return longName ? 'heading-s' : 'title-l';
    default:
      return longName ? 'heading-xs' : 'title-m';
  }
};

const getImageSize = (componentSize, themeSizes) => {
  switch (componentSize) {
    case 'xlarge':
      return themeSizes.xlargeSize;
    case 'large':
      return themeSizes.largeSize;
    case 'medium':
      return themeSizes.mediumSize;
    default:
      return themeSizes.smallSize;
  }
};

const TeamLogo = ({
  size = 'large',
  logo,
  logoPlacement = 'left',
  label = '',
  useLazyLoading = true,
  currentTheme = '',
}) => {
  const { teamLogo } = useThemeContext();
  const longName = useMemo(() => label.length > 4, [label]);
  const typographySize = getTypographySize(size, longName);
  const themeSizes = useMemo(() => themes[currentTheme] || themes.default, [currentTheme]);
  const imageSize = useMemo(() => parseInt(getImageSize(size, themeSizes), 10), [size, themeSizes]);

  const labelElement = useMemo(
    () =>
      label && (
        <TextWrapper size={size}>
          <Typography dataTestId="team-logo-label" type={typographySize}>
            {label}
          </Typography>
        </TextWrapper>
      ),
    [label, size, typographySize],
  );

  if (!logo)
    return (
      <Container $logoPlacement={logoPlacement} data-testid="team-logo-container">
        <ImageWrapper size={size}>
          <Icon type="fallbackTeamLogo" fillColor={teamLogo.fallbackLogoFill} />
        </ImageWrapper>
        {labelElement}
      </Container>
    );

  return (
    <Container $logoPlacement={logoPlacement} size={size} data-testid="team-logo-container">
      {useLazyLoading ? (
        <LazyWrapper size={size} useLazyLoading>
          <LazyElement
            src={logo}
            alt={`${label} logo`}
            srcSet={{
              tiny: imageSize,
            }}
          />
        </LazyWrapper>
      ) : (
        <ImageWrapper size={size}>
          <img
            src={logo}
            alt={`${label} logo`}
            width={imageSize}
            height={imageSize}
            data-testid="team-logo-image"
          />
        </ImageWrapper>
      )}
      {labelElement}
    </Container>
  );
};

TeamLogo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  logo: PropTypes.string,
  logoPlacement: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.string,
  useLazyLoading: PropTypes.bool,
  currentTheme: PropTypes.string,
};

export default withTheme(TeamLogo, themes, 'teamLogo');
