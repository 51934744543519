import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import useOnPremiumAction from 'tv-hooks/useOnPremiumAction';
import BaseSelector from 'znipe-elements/navigation/Selector/Selector';

const Selector = ({
  isLocked = false,
  onClick = () => {},
  onHover = () => {},
  playerId = '',
  streamId = '',
  type = 'vertical',
  streamType = 'event',
  ...props
}) => {
  const hasPremiumAccess = useHasPremiumAccess();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(streamId, playerId);
    }
  }, [onClick, playerId, streamId]);

  const handleMouseOver = useCallback(
    () => onHover(streamId, isLocked),
    [streamId, isLocked, onHover],
  );
  const handleMouseOut = useCallback(() => onHover(), [onHover]);

  const onClickAction = useOnPremiumAction(handleClick, isLocked);

  return (
    <BaseSelector
      {...props}
      type={type}
      streamType={streamType}
      onClick={onClickAction}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      hasPremiumAccess={hasPremiumAccess}
      isLocked={isLocked}
    />
  );
};

Selector.propTypes = {
  game: PropTypes.string,
  image: PropTypes.string,
  isSelected: PropTypes.bool,
  isLocked: PropTypes.bool,
  label: PropTypes.string,
  level: PropTypes.number,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  playerId: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  streamType: PropTypes.oneOf(['player', 'event', 'map']),
  streamId: PropTypes.string,
  type: PropTypes.oneOf(['vertical', 'horizontal', 'slim-horizontal']),
};

export default memo(Selector);
