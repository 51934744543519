import isBrowser from 'znipe-utils/web/isBrowser';
import type { ZnipeType } from '../../../global';

const registerZnipeGlobal = <T extends keyof ZnipeType>(
  key: T,
  value: Required<ZnipeType>[T],
): Required<ZnipeType>[T] => {
  if (!isBrowser()) return value;

  if (!window.Znipe) window.Znipe = {};
  if (!window.Znipe[key]) window.Znipe[key] = value;

  // TS doesn't know that window.Znipe[key] is defined
  return window.Znipe[key] as Required<ZnipeType>[T];
};

export const existsZnipeGlobal = <T extends keyof ZnipeType>(key: T): boolean => {
  if (!isBrowser()) return false;
  if (!window.Znipe) return false;
  if (!window.Znipe[key]) return false;
  return true;
};

export const getZnipeGlobal = <T extends keyof ZnipeType>(
  key: T,
): Required<ZnipeType>[T] | undefined => {
  if (!isBrowser()) return undefined;
  if (!window.Znipe) return undefined;
  if (!window.Znipe[key]) return undefined;
  return window.Znipe[key] as Required<ZnipeType>[T];
};

export const unregisterZnipeGlobal = <T extends keyof ZnipeType>(key: T): void => {
  if (!isBrowser()) return;
  if (!window.Znipe) return;
  if (!window.Znipe[key]) return;
  delete window.Znipe[key];
};

export default registerZnipeGlobal;
