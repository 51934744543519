import { memo, useState, useEffect, useReducer, useCallback } from 'react';
import colors from 'znipe-styles/colors';
import PropTypes from 'prop-types';
import withTheme from 'znipe-themes/hocs/withTheme';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import WarningMessage from 'znipe-elements/data-display/WarningMessage/WarningMessage';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import themes from './BillingHistory.themes';
import BillingHistoryItem from './BillingHistoryItem';
import isPaid from './isPaid';
import useGetReceiptsRequest from './useGetReceiptsRequest';
import {
  Container,
  List,
  Item,
  Field,
  ButtonWrapper,
  LoadingSpinnerWrapper,
  ButtonLoading,
  Disclaimer,
} from './BillingHistory.styles';

export const authorizeBankMessage = 'Your latest payment needs to be authorized with your bank.';
export const authorizeFailedErrorMessage = 'Authorization failed, please contact your bank';

const fetchReducer = (state, action) => {
  switch (action.type) {
    case 'IS_FETCHING':
      return { ...state, ...{ loading: true } };
    case 'FAILURE':
      return {
        receipts: state.receipts,
        totalCount: state.totalCount,
        loading: false,
        error: action.error,
      };
    case 'SUCCESS':
      return {
        receipts: [...state.receipts, ...action.receipts],
        totalCount: action.totalCount,
        loading: false,
        error: '',
      };
    default:
      return state;
  }
};

export const SORT = 'dueAt';
export const SORT_ORDER = 'descending';

const initialState = { receipts: [], loading: true, error: '', totalCount: 0 };

const useGetProductReceipts = (productId, limit = 10, offset = 0) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const getReceipts = useGetReceiptsRequest(productId, SORT, SORT_ORDER);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    let mounted = true;
    const fetchReceipts = async () => {
      dispatch({ type: 'IS_FETCHING' });
      try {
        const response = await getReceipts(limit, offset);
        const {
          results: { receipts, totalCount } = {},
          status,
        } = response;
        if (status !== 'ok') throw Error('Failed to fetch receipts payment');
        if (mounted) {
          dispatch({ type: 'SUCCESS', receipts, totalCount });
        }
      } catch (error) {
        if (mounted) {
          dispatch({ type: 'FAILURE', error: error.message || error });
        }
      }
    };
    if (productId && typeof offset === 'number') {
      fetchReceipts();
    }
    return () => {
      mounted = false;
    };
  }, [productId, offset, getReceipts]); // limit intentionally not included in dependency array since it updates on viewport width change

  return state;
};

const ListHeader = memo(() => (
  <Item isHeader data-testid="list-header">
    <Field gridArea="Icon" isHeader />
    <Field gridArea="Date" isHeader>
      date
    </Field>
    <Field gridArea="Invoice" isHeader>
      invoice
    </Field>
    <Field gridArea="Amount" isHeader>
      amount
    </Field>
    <Field gridArea="Status" isHeader />
  </Item>
));

const disclaimerText =
  'Only receipts paid with a Credit Card are linked below. Receipts for invoices paid through PayPal are available in your PayPal account.';

export const MOBILE_LIMIT = 5;
export const TABLET_LIMIT = 10;

const BillingHistory = ({ productId }) => {
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const [showAuthError, setShowAuthError] = useState(false);
  const limit = isTabletOrGreater ? TABLET_LIMIT : MOBILE_LIMIT;
  const [offset, setOffset] = useState(0);
  const { loading, receipts, totalCount, error } = useGetProductReceipts(productId, limit, offset);
  const hasMoreToLoad = receipts.length > 0 && receipts.length < totalCount;
  const [latest] = receipts;
  const latestNotPaid =
    !showAuthError && latest && isPaid(latest.amount, latest.amountPaid) === false;

  const handleClickLoadMore = useCallback(() => {
    setOffset(receipts.length);
  }, [receipts]);

  if (loading)
    return (
      <LoadingSpinnerWrapper height={`${45 * limit}px`}>
        <LoadingSpinner fillColor={colors.black} />
      </LoadingSpinnerWrapper>
    );

  return (
    <Container>
      {latestNotPaid && <WarningMessage message={authorizeBankMessage} />}
      {showAuthError && <WarningMessage message={authorizeFailedErrorMessage} />}
      {error && <WarningMessage message={error} />}
      <Disclaimer>{disclaimerText}</Disclaimer>
      <List>
        {isTabletOrGreater && <ListHeader />}
        {receipts.map((receipt, index) => (
          <BillingHistoryItem
            key={receipt.invoiceNumber}
            productId={productId}
            receipt={receipt}
            index={index}
            onAuthFailure={setShowAuthError}
          />
        ))}
      </List>
      {receipts.length === 0 && <Disclaimer>You have no billing history.</Disclaimer>}
      {hasMoreToLoad && (
        <ButtonWrapper>
          <Button
            onClick={handleClickLoadMore}
            variant="text"
            size="small"
            textColor="black"
            data-testid="load-more-button"
          >
            {loading ? (
              <ButtonLoading>
                <LoadingSpinner fillColor={colors.black} />
              </ButtonLoading>
            ) : (
              'load more'
            )}
          </Button>
        </ButtonWrapper>
      )}
    </Container>
  );
};

BillingHistory.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default withTheme(memo(BillingHistory), themes, 'billingHistory');
