const defaultIcons = {
  alertTriangle: 'default/alert-triangle.svg',
  americanExpress: 'default/american-express.svg',
  anchor: 'default/anchor.svg',
  archive: 'default/archive.svg',
  armBottom: 'default/arm-bottom.svg',
  armTop: 'default/arm-top.svg',
  arrowDown: 'default/arrow-down.svg',
  arrowDownExtended: 'default/arrow-down-extended.svg',
  arrowDownExtendedGradient: 'default/arrow-down-extended-gradient.svg',
  arrowLeft: 'default/arrowLeft.svg',
  arrowLeftExtended: 'default/arrow-left-extended.svg',
  arrowLeftWithWall: 'default/arrow-left-with-wall.svg',
  arrowRight: 'default/arrowRight.svg',
  arrowRightWithWall: 'default/arrow-right-with-wall.svg',
  arrowUp: 'default/arrowUp.svg',
  arrowUpDown: 'default/arrow-up-down.svg',
  award: 'default/award.svg',
  awp: 'default/awp.svg',
  banChat: 'default/ban-chat.svg',
  banHammer: 'default/ban.svg',
  barChart: 'default/barChart.svg',
  biggerCaretDown: 'default/bigger-carret-down.svg',
  biggerCaretLeft: 'default/bigger-carret-left.svg',
  biggerCaretRight: 'default/bigger-carret-right.svg',
  biggerCaretUp: 'default/bigger-carret-up.svg',
  binoculars: 'default/binoculars.svg',
  bomb: 'default/bomb.svg',
  book: 'default/book.svg',
  bookOpen: 'default/book-open.svg',
  briefcase: 'default/briefcase.svg',
  c4Explode: 'default/c4-explode.svg',
  calendar: 'default/calendar.svg',
  caretDown: 'default/carret-down.svg',
  caretLeft: 'default/carret-left.svg',
  caretRight: 'default/carret-right.svg',
  caretUp: 'default/carret-up.svg',
  chat: 'default/chat.svg',
  check: 'default/check.svg',
  chevronDown: 'default/chevronDown.svg',
  chevronLeft: 'default/chevronLeft.svg',
  chevronRight: 'default/chevronRight.svg',
  chevronsDown: 'default/chevronsDown.svg',
  chevronsLeft: 'default/chevronsLeft.svg',
  chevronsRight: 'default/chevronsRight.svg',
  chevronsUp: 'default/chevronsUp.svg',
  chevronUp: 'default/chevronUp.svg',
  circleCheck: 'default/circle-check.svg',
  clock: 'default/clock.svg',
  clockFilled: 'default/clock-filled.svg',
  close: 'default/close.svg',
  cloudOff: 'default/cloud-off.svg',
  compare: 'default/compare.svg',
  contactCard: 'default/contact-card.svg',
  convert: 'default/convert.svg',
  copy: 'default/copy.svg',
  cornerUpLeft: 'default/cornerUpLeft.svg',
  counterstrike: 'default/counter-strike.svg',
  creditCard: 'default/credit-card.svg',
  crosshair: 'default/crosshair.svg',
  crown: 'default/crown.svg',
  deathSkull: 'default/death-skull.svg',
  decoy: 'default/decoy.svg',
  defuse: 'default/defuse.svg',
  diners: 'default/diners.svg',
  discover: 'default/discover.svg',
  dollar: 'default/dollar.svg',
  dotsDouble: 'default/dots-double.svg',
  edit: 'default/edit.svg',
  edit3: 'default/edit-3.svg',
  emptyStar: 'default/empty-star.svg',
  errorCreditCard: 'default/error-credit-card.svg',
  eventStream: 'default/event.svg',
  exclamation: 'default/exclamation.svg',
  explosion: 'default/explosion.svg',
  export: 'default/export.svg',
  external: 'default/external.svg',
  externalLink: 'default/external-link.svg',
  eye: 'default/eye.svg',
  eyeOff: 'default/eye-off.svg',
  fallbackTeamLogo: 'default/fallback-team-logo.svg',
  film: 'default/film.svg',
  fire: 'default/fire.svg',
  flag: 'default/flag.svg',
  flash: 'default/flash.svg',
  fullscreen: 'default/fullscreen.svg',
  fullscreenExit: 'default/exit-fullscreen.svg',
  gifIcon: 'default/gif-icon.svg',
  globe: 'default/globe.svg',
  globeFilled: 'default/globeFilled.svg',
  globeWithLabel: 'default/globeWithLabel.svg',
  googleLogo: 'default/google-logo.svg',
  happyBirthday: 'default/happy-birthday.svg',
  headphones: 'default/headphones.svg',
  headphonesThin: 'default/headphones-thin.svg',
  headset: 'default/headset.svg',
  heart: 'default/heart.svg',
  hegrenade: 'default/hegrenade.svg',
  helpCircle: 'default/help-circle.svg',
  highlights: 'default/highlights.svg',
  home: 'default/home.svg',
  incendiary: 'default/incendiary.svg',
  info: 'default/info.svg',
  jcb: 'default/jcb.svg',
  knife: 'default/knife.svg',
  laughingIconEyesClosed: 'default/emojiMenuHover.svg',
  laughingIconEyesOpen: 'default/emojiMenu.svg',
  layers: 'default/layers.svg',
  layout: 'default/layout.svg',
  list: 'default/list.svg',
  loader: 'default/loader.svg',
  lockIcon: 'default/lockIcon.svg',
  logOut: 'default/log-out.svg',
  map: 'default/map.svg',
  mapFallback: 'default/map-fallback.svg',
  mapIcon: 'default/map-icon.svg',
  mastercard: 'default/mastercard.svg',
  maximize: 'default/maximize.svg',
  menu: 'default/menu.svg',
  messageSquare: 'default/message-square.svg',
  mic: 'default/mic.svg',
  minimize: 'default/minimize.svg',
  minus: 'default/minus.svg',
  molly: 'default/molly.svg',
  moreHorizontal: 'default/moreHorizontal.svg',
  moreVertical: 'default/moreVertical.svg',
  mute: 'default/mute.svg',
  notification: 'default/notification.svg',
  notificationFilled: 'default/notificationFilled.svg',
  notificationsOff: 'default/notificationsOff.svg',
  overview: 'default/overview.svg',
  palette: 'default/identity.svg',
  paperPlane: 'default/paper-plane.svg',
  pause: 'default/pause.svg',
  pauseCircle: 'default/pause-circle.svg',
  paypal: 'default/paypal.svg',
  placeholderCreditCard: 'default/placeholder-credit-card.svg',
  play: 'default/play.svg',
  playCircle: 'default/play-circle.svg',
  plus: 'default/plus.svg',
  plusCircle: 'default/plus-circle.svg',
  popIn: 'default/pop-in.svg',
  popOut: 'default/pop-out.svg',
  power: 'default/power.svg',
  poweredByZnipeLogo: 'default/powered-by-znipe-logo.svg',
  radio: 'default/radio.svg',
  refreshCcw: 'default/refresh-ccw.svg',
  relaying: 'default/relaying.svg',
  repeat: 'default/repeat.svg',
  report: 'default/report.svg',
  rewindTenSeconds: 'default/rewind10seconds.svg',
  room: 'default/room.svg',
  rotateCcw: 'default/rotate-ccw.svg',
  rotateCw: 'default/rotate-cw.svg',
  scissors: 'default/scissors.svg',
  scoreboard: 'default/scoreboard.svg',
  search: 'default/search.svg',
  send: 'default/paper-plane.svg',
  settings: 'default/settings.svg',
  share: 'default/share.svg',
  skipTenSeconds: 'default/skip10seconds.svg',
  skipToEnd: 'default/skip-to-end.svg',
  skipToStart: 'default/skip-to-start.svg',
  slash: 'default/slash.svg',
  slate: 'default/slate.svg',
  sliders: 'default/sliders.svg',
  smileyIcon: 'default/smiley-icon.svg',
  smoke: 'default/smoke.svg',
  sound: 'default/volume.svg',
  star: 'default/star.svg',
  stats: 'default/stats.svg',
  statsStroked: 'default/statsStroked.svg',
  stopCircle: 'default/stop-circle.svg',
  stream: 'default/stream.svg',
  streamFilled: 'default/streamFilled.svg',
  stripe: 'default/stripe.svg',
  threeHeads: 'default/three-heads.svg',
  tickFilled: 'default/tick-filled.svg',
  trash: 'default/trash.svg',
  trash2: 'default/trash-2.svg',
  trophy: 'default/trophy.svg',
  trophy2: 'default/trophy2.svg',
  tv: 'default/tv.svg',
  unionpay: 'default/unionpay.svg',
  uploadCloud: 'default/upload-cloud.svg',
  user: 'default/user.svg',
  userReview: 'default/user-review.svg',
  users: 'default/users.svg',
  versus: 'default/versus.svg',
  visa: 'default/visa.svg',
  warningTriangle: 'default/warning-triangle.svg',
  watchAgain: 'default/watch-again.svg',
  watchNext: 'default/watch-next.svg',
  zap: 'default/zap.svg',
  znipebase: 'default/znipebase.svg',
  znipebaseSimple: 'default/znipebase-simple.svg',
  znipebaseStaging: 'default/znipebase-staging.svg',
  znipeLogo: 'default/znipe-logo.svg',
  znipeLogoDark: 'default/znipeLogoDark.svg',
  znipeLogoLight: 'default/znipeLogoLight.svg',
  znipeLogoWithText: 'default/znipe-logo-with-text.svg',
};

const faceitIcons = {
  ...defaultIcons,
  eventStream: 'faceit/event.svg',
  play: 'faceit/play.svg',
  pause: 'faceit/pause.svg',
  rewindTenSeconds: 'faceit/rewind10seconds.svg',
  skipTenSeconds: 'faceit/skip10seconds.svg',
  settings: 'faceit/settings.svg',
  sound: 'faceit/volume.svg',
  mute: 'faceit/mute.svg',
  fullscreen: 'faceit/fullscreen.svg',
  fullscreenExit: 'faceit/exit-fullscreen.svg',
  send: 'faceit/send.svg',
  share: 'faceit/share.svg',
  search: 'faceit/search-faceit.svg',
  versus: 'faceit/versus-faceit.svg',
  mapIcon: 'faceit/map-icon.svg',
};

const svenskaCupenIcons = {
  ...defaultIcons,
  svenskaCupenLogo: 'svenska-cupen/svenska-cupen-logo.svg',
};

const proviewIcons = {
  ...defaultIcons,
  jungleRole: 'proview/jungle-role.svg',
  midRole: 'proview/mid-role.svg',
  supportRole: 'proview/support-role.svg',
  topRole: 'proview/top-role.svg',
  bottomRole: 'proview/bottom-role.svg',
  mageClass: 'proview/mage-class.svg',
  tankClass: 'proview/tank-class.svg',
  fighterClass: 'proview/fighter-class.svg',
  supportClass: 'proview/support-class.svg',
  assassinClass: 'proview/assasins-class.svg',
  fillClass: 'proview/fill-class.svg',
  marksmanClass: 'proview/marksman-class.svg',
  rewindTenSeconds: 'proview/rewind-ten-seconds.svg',
  skipTenSeconds: 'proview/skip-ten-seconds.svg',
  skipBackward: 'proview/rewind-ten-seconds.svg',
  skipForward: 'proview/skip-ten-seconds.svg',
  proViewLogo: 'proview/pro-view-logo.svg',
  proViewPlainLogo: 'proview/pro-view-logo.svg',
  proviewPremiumLogo: 'proview/proview-premium-logo.svg',
  lolEsports: 'proview/lol-esports.svg',
  proviewStatsLogo: 'proview/proview-stats-logo.svg',
  singleView: 'proview/single-view.svg',
  doubleView: 'proview/double-view.svg',
  tripleView: 'proview/triple-view.svg',
  quadView: 'proview/quad-view.svg',
  riotFistbump: 'proview/riot-fistbump.svg',
  mapIcon: 'proview/map.svg',
  crown: 'proview/crown.svg',
  runes: 'proview/runes.svg',
  ability: 'proview/ability.svg',
  watchWithFriends: 'proview/watch-with-friends.svg',
  createWatchWithFriends: 'proview/create-watch-with-friends.svg',
  health: 'proview/health.svg',
  airDragon: 'proview/air-dragon.svg',
  earthDragon: 'proview/earth-dragon.svg',
  elderDragon: 'proview/elder-dragon.svg',
  fireDragon: 'proview/fire-dragon.svg',
  waterDragon: 'proview/water-dragon.svg',
  baron: 'proview/baron.svg',
  riftHerald: 'proview/rift-herald.svg',
  tower: 'proview/tower.svg',
  sword: 'proview/sword.svg',
  gold: 'proview/gold.svg',
  minion: 'proview/minions.svg',
  playerRed: 'proview/player-red.svg',
  playerBlue: 'proview/player-blue.svg',
  champion: 'proview/champion.svg',
  inhibitor: 'proview/inhibitor.svg',
  defaultRole: 'proview/default-role.svg',
  matches: 'proview/matches.svg',
  LOLAttackDamage: 'proview/lol-attack-damage.svg',
  LOLArmor: 'proview/lol-armor.svg',
  LOLArmorPenetration: 'proview/lol-armor-penetration.svg',
  LOLAttackSpeed: 'proview/lol-attack-speed.svg',
  LOLMovementSpeed: 'proview/lol-movement-speed.svg',
  LOLLifeSteal: 'proview/lol-lifesteal.svg',
  LOLTenacity: 'proview/lol-tenacity.svg',
  LOLHealthRegen: 'proview/lol-health-regen.svg',
  LOLAbilityPower: 'proview/lol-ability-power.svg',
  LOLMagicResist: 'proview/lol-magic-resist.svg',
  LOLMagicPenetration: 'proview/lol-magic-penetration.svg',
  LOLCooldownReduction: 'proview/lol-cooldown-reduction.svg',
  LOLCritChance: 'proview/lol-crit-chance.svg',
  LOLSpellVamp: 'proview/lol-spell-vamp.svg',
  LOLAutoAttackRange: 'proview/lol-auto-attack-range.svg',
  LOLManaRegen: 'proview/lol-mana-regen.svg',
  LOLHealthTotal: 'proview/lol-health-total.svg',
  LOLManaTotal: 'proview/lol-mana-total.svg',
};

const znipeBaseIcons = {
  ...defaultIcons,
  confirmSpectate: 'znipebase/confirm-spectate.svg',
  confirmStartStream: 'znipebase/confirm-start-stream.svg',
  confirmStopStream: 'znipebase/confirm-stop-stream.svg',
  confirmRemoveObserver: 'znipebase/confirm-remove-observation.svg',
  sync: 'znipebase/sync.svg',
  syncConfirmed: 'znipebase/sync-confirmed-icon.svg',
  org: 'znipebase/org.svg',
  ingests: 'znipebase/ingests.svg',
  eventSeries: 'znipebase/event-series.svg',
  versus: 'znipebase/versus.svg',
};

export const ubisoftIcons = {
  ...defaultIcons,
  fallbackTeamLogo: 'ubisoft/fallback-team-logo.svg',
};

export const BASE_URL = process.env.ICONS_URL ?? 'https://icons.znipe.tv/';

export type DefaultIconNames = keyof typeof defaultIcons;
export type FaceitIconNames = keyof typeof faceitIcons;
export type ZnipeBaseIconNames = keyof typeof znipeBaseIcons;
export type SvenskaCupenIconNames = keyof typeof svenskaCupenIcons;
export type ProviewIconNames = keyof typeof proviewIcons;
export type UbisoftIconNames = keyof typeof ubisoftIcons;

export default {
  default: defaultIcons,
  faceit: faceitIcons,
  znipebase: znipeBaseIcons,
  ubisoft: ubisoftIcons,
  'svenska-cupen': svenskaCupenIcons,
  riot: defaultIcons,
  proview: proviewIcons,
  efg: defaultIcons,
  znipe: defaultIcons,
  esportal: defaultIcons,
};
