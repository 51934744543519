import { styled, css } from 'styled-components';
import { convertHexToRGBDigits } from 'znipe-utils/misc/convertHexToRGB';
import { Link } from 'react-router-dom';
import { growHeight } from 'znipe-styles/animations';
import prefers from 'znipe-styles/prefers';
import Typography from 'znipe-elements/general/Typography/Typography';

const ANIMATION_EFFECT = '250ms forwards';

const KeyboardNavigationOutline = css`
  &:focus {
    outline: 1px solid ${({ theme }) => theme.tertiaryBackgroundColor};
    outline-offset: -6px;
  }
  &:focus:not(:focus-visible) {
    outline: 1px solid transparent;
  }
`;

const heights = {
  small: 56,
  medium: 75,
  large: 64,
};
export const Container = styled.nav`
  display: grid;
  place-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  height: ${({ $size }) => heights[$size]}px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  ${({ theme }) =>
    theme.navigation.mobileBorder &&
    css`
      border-top: 1px solid ${theme.containerStroke};
    `};

  ${({ $size }) => {
    if ($size !== 'large') return css``;

    return css`
      border: none;
      background-color: transparent;
      height: 64px;
      place-items: normal;
      box-shadow: none;
    `;
  }}
  ${KeyboardNavigationOutline}
`;

export const IconWrapper = styled.div`
  ${({ $size }) =>
    $size === 'large'
      ? css`
          margin-bottom: 0;
          margin-right: 10px;
        `
      : css`
          max-width: 24px;
          margin-bottom: 5px;
        `};
`;

export const Label = styled(Typography)`
  text-decoration: none;
  color: ${({ theme }) => theme.secondaryTextColor};
`;

const BackgroundStyles = css`
  background: ${({ $size }) =>
    $size === 'large'
      ? css`
           linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        `
      : css`
           linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.16) 0%,
            rgba(255, 255, 255, 0.16) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        `};
  ${Label} {
    color: ${({ theme }) => theme.primaryTextColor};
  }
`;

export const HintContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ $size }) => ($size === 'large' ? 'row' : 'column')};
  text-decoration: none;
  color: inherit;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4px 0;
  ${KeyboardNavigationOutline}
  ${IconWrapper} {
    ${({ $shadow }) =>
      $shadow &&
      css`
        @supports (filter: drop-shadow(0px 0px 0px #000000)) {
          filter: drop-shadow(0px 0px 6px rgba(${convertHexToRGBDigits($shadow)}, 0.7));
        }
      `}
  }
  &:hover,
  &:focus {
    ${BackgroundStyles};
    ${({ $size }) =>
      $size === 'large' &&
      css`
        text-decoration: none;
      `}
  }
  ${({ $active }) =>
    $active &&
    css`
      ${BackgroundStyles}
      &::after {
        content: '';
        background: ${props => props.gradient};
        position: absolute;
        bottom: 0;
        width: 100%;

        ${({ $size }) => {
          const radius = $size === 'small' ? 2 : 4;
          return css`
            animation: ${growHeight('0px', radius)} ${ANIMATION_EFFECT};
            border-radius: ${radius}px ${radius}px 0 0;
            @media ${prefers.reducedMotion} {
              height: ${radius}px;
            }
          `;
        }}

        ${({ $size }) =>
          $size === 'large' &&
          css`
            display: none;
          `}
      }
    `}
`;
