import { useCallback } from 'react';
import { error } from 'znipe-logger';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import { MESSAGE_TYPE } from 'znipe-chat/src/contexts/ChatContext';
import useChatContext from 'znipe-chat/src/hooks/useChatContext';
import useShakeButton from './useShakeButton';
import useMessageCooldown from './useMessageCooldown';

const useSendMessage = (
  message: string,
  onMessageSent: () => void,
  clearInputOperations: () => void,
  isDisabled = false,
) => {
  const {
    chatId,
    avatarId,
    signifierIds,
    usernameColor,
    replyData,
    setReplyData,
    chatStore,
    chatChannel,
    accountInfo,
  } = useChatContext() ?? {};
  const { isShakeActivated, onSubmitAttempt } = useShakeButton();
  const { isOnCooldown, onCooldownMessage, cooldown } = useMessageCooldown();

  const sendMessage = useCallback(() => {
    if (isOnCooldown) {
      onSubmitAttempt();
      return;
    }

    if (isDisabled) {
      return;
    }

    if (!accountInfo?.uid || !accountInfo?.username) {
      error('User info is missing');
      return;
    }

    onCooldownMessage();
    let trimmedMessage = message.trim();

    if (replyData?.username && trimmedMessage.indexOf(`@${replyData?.username}`) > -1) {
      trimmedMessage = trimmedMessage.replace(`@${replyData?.username}`, '');
    }

    if (trimmedMessage && chatId) {
      const messageObject = {
        message: trimmedMessage,
        clientMessageId: generateUniqueId(),
        color: usernameColor,
        avatarId,
        signifierIds: signifierIds ?? [],
        replyTo: replyData ?? null,
        isModerator: accountInfo?.isModerator,
        channel: chatId,
      };

      chatStore?.onMessage({
        ...messageObject,
        userId: accountInfo?.uid,
        username: accountInfo?.username,
      });
      if (chatChannel) {
        chatChannel.send({
          ...messageObject,
          replyTo: replyData,
          type: MESSAGE_TYPE,
        });
      }
    }

    clearInputOperations();
    onMessageSent();
    setReplyData?.(null);
  }, [
    avatarId,
    chatChannel,
    chatId,
    chatStore,
    message,
    onCooldownMessage,
    isOnCooldown,
    onSubmitAttempt,
    isDisabled,
    replyData,
    setReplyData,
    accountInfo?.uid,
    accountInfo?.username,
    accountInfo?.isModerator,
    usernameColor,
    signifierIds,
    onMessageSent,
    clearInputOperations,
  ]);

  return { sendMessage, isShakeActivated, cooldown, isOnCooldown };
};

export default useSendMessage;
