import { forwardRef } from 'react';
import { styled, css } from 'styled-components';
import { useTransition, animated } from '@react-spring/web';

const staticIn = {
  left: 'translateX(0%)',
  top: 'translateY(0%)',
  right: 'translateX(0%)',
  bottom: 'translateY(0%)',
};

const staticOut = {
  left: 'translateX(-100%)',
  top: 'translateY(-100%)',
  right: 'translateX(100%)',
  bottom: 'translateY(100%)',
};

const positionCss = {
  bottom: css`
    z-index: 1;
    bottom: 0;
  `,
  top: css`
    z-index: 1;
    top: 0;
  `,
  left: css`
    z-index: 1;
    left: 0;
  `,
  right: css`
    z-index: 1;
    right: 0;
  `,
};

type Type = keyof typeof positionCss;
type Position = 'relative' | 'absolute' | 'fixed';

const SlideContainer = styled.div<{
  $position: Position;
  $type: Type;
  $width: string;
  $height: string;
  $zIndex: number;
  $top?: number;
}>`
  position: ${({ $position }) => $position};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  ${({ $position, $type }) => $position !== 'relative' && positionCss[$type]}
  z-index: ${({ $zIndex }) => $zIndex};
  top: ${({ $top }) => $top};
`;

const AnimatedSlideContainer = animated(SlideContainer);

type SlideProps = {
  show?: boolean;
  type?: Type;
  width?: string;
  height?: string;
  position?: Position;
  zIndex?: number;
  top?: number;
  children: React.ReactNode;
};

const Slide = forwardRef<HTMLDivElement, SlideProps>(
  (
    {
      show = false,
      children,
      type = 'left',
      width = '100%',
      height = '100%',
      position = 'absolute',
      zIndex = 1,
      top,
    },
    ref,
  ) => {
    const transitions = useTransition(show, {
      from: { transform: staticOut[type] },
      enter: { transform: staticIn[type] },
      leave: { transform: staticOut[type] },
    });

    return transitions(
      (styles, item) =>
        !!item && (
          <AnimatedSlideContainer
            $position={position}
            data-testid="slide-container"
            $type={type}
            $width={width}
            $height={height}
            $zIndex={zIndex}
            $top={top}
            style={styles}
            ref={ref}
          >
            {children}
          </AnimatedSlideContainer>
        ),
    );
  },
);

export default Slide;
