export const createInputObject = (
  type = 'player',
  _id = '',
  packageId = '',
  muted = false,
  inputType = '',
) => {
  if (type === 'riotChampion') {
    return {
      muted,
      type: inputType.toUpperCase(),
      package: {
        _id: packageId,
      },
      [type]: {
        champId: _id,
      },
    };
  }
  return {
    muted,
    type: type.toUpperCase(),
    package: {
      _id: packageId,
    },
    [type]: {
      _id,
    },
  };
};
