import { styled, css } from 'styled-components';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';
import buttonDefaultStyle from 'znipe-styles/buttonDefaultStyle';

export const CONTENT_PADDING = 16;

type ContainerProps = {
  $width?: number;
  $hasTarget?: boolean;
};

const noTargetContainerStyle = css`
  height: fit-content;
  border-radius: 4px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  background: ${({ theme }) => theme.secondaryBackgroundColor};
`;

export const Container = styled.div<ContainerProps>`
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  height: 100%;
  ${({ $hasTarget }) => !$hasTarget && noTargetContainerStyle}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.containerStroke};
`;

export const CloseButton = styled.button`
  ${buttonDefaultStyle}
  box-sizing: content-box;
  color: ${({ theme }) => theme.textPrimary};
`;

type ScrollAreaProps = {
  $type?: 'regular' | 'compact';
  $maxHeight?: number;
  $allowScroll?: boolean;
};

export const ScrollArea = styled.div<ScrollAreaProps>`
  width: initial;
  height: 100%;
  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight}px;
    `}
  display: flex;
  justify-content: center;
  ${({ $allowScroll }) =>
    $allowScroll
      ? Scrollbar
      : css`
          overflow: hidden;
        `}
  overflow-x: hidden;
`;

type ContentWrapperProps = {
  $allowScroll?: boolean;
  $hasScroll?: boolean;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  padding: ${({ $hasScroll }) =>
    $hasScroll
      ? `${CONTENT_PADDING}px 7px ${CONTENT_PADDING}px ${CONTENT_PADDING}px`
      : `${CONTENT_PADDING}px`};
  height: fit-content;
  & > * {
    width: inherit;
  }
`;
