import { useMemo, useRef, useState, useCallback, useReducer, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import GlobalStyle from 'znipe-styles/global.styles';
import useQueryObject from 'tv-hooks/useQueryObject';
import { useGetSelectedMatchGameIndex } from 'tv-selectors/match/makeGetSelectedMatchGameIndex';
import { useGetMatchPlayerStreams } from 'tv-selectors/match/makeGetMatchPlayerStreams';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import usePrevious from 'znipe-hooks/usePrevious';
import usePopoutMessenger from 'tv-hooks/usePopoutMessenger';
import useSetupMatchInfo from 'tv-hooks/useSetupMatchInfo';
import useFetchVideoCredentials from 'tv-hooks/useFetchVideoCredentials';
import useTheme from 'tv-hooks/useTheme';
import StageMatchBar from 'tv-modules/StageMatchBar/StageMatchBar';
import VideoFrame from 'tv-modules/Stage/VideoFrame/VideoFrame';
import useGqlSelector from 'tv-hooks/useGqlSelector';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import { encodeId } from 'znipe-gql/utils/misc';
import PremiumContentContext from 'tv-contexts/PremiumContentContext';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import usePackageName from 'tv-hooks/usePackageName';
import useGameRedirect from 'tv-hooks/useGameRedirect';
import queryStringToObject from 'znipe-utils/location/queryStringToObject';
import objectToQueryString from 'znipe-utils/location/objectToQueryString';
import PingFrame from 'tv-modules/Stage/PingFrame/PingFrame';
import usePlayerScaling, {
  PLAYER_SPRING,
  RIGHT_SIDEBAR_SPRING,
  LEFT_SIDEBAR_SPRING,
  STAGE_SPRING,
} from 'tv-routes/Stage/usePlayerScaling';
import Slide from 'znipe-elements/layout/Animation/Slide/Slide';
import { DesktopSidebarContainer, Container, BodyContainer } from 'tv-routes/Stage/Stage.styles';
import ChatSideMenu from 'tv-routes/Stage/ChatSideMenu';
import {
  HIGHLIGHTLIST_MAP,
  POV_SELECTOR_STREAM_ID,
  SIDEBAR_CHAT_VISIBILITY,
  SIDEBAR_HIGHLIGHTS_VISIBLITIY,
  SIDEBAR_STATS_VISIBLITIY,
  POV_SELECTOR_RESET,
} from 'tv-routes/Stage/Stage.constants';
import reducer from 'tv-routes/Stage/Stage.reducer';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import { useGetMatchGameTitle } from 'tv-selectors/match/makeGetMatchGameTitle';
import { useGetGameHasStats } from 'tv-selectors/games/makeGetGameHasStats';
import StatsMenu from 'tv-modules/Stats/StatsMenu/StatsMenu';
import { useGameTimeInfoEpic } from 'tv-epics/gameTimeInfoEpic';
import useResetGatStats from 'tv-routes/Stage/useResetGatStats';
import EmbedPageQuery from './getStagePageData.graphql';
import useIframeMessenger from './useIframeMessenger';

const initialState = {
  streamSelectorState: { selectedStreamId: undefined },
};

export const VideoFrameWrapper = styled.div`
  width: 100%;
`;

const Embed = () => {
  const [stageState, dispatchStageState] = useReducer(reducer, initialState);

  const { statsSidebarVisible, highlightsSidebarVisible, chatSidebarVisible } = stageState;

  const packageName = usePackageName();
  const { m, type } = useQueryObject();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [popoutWindowRefs, setPopoutWindowRefs] = useState([]);

  const handleWindowRefUpdate = useCallback(
    (ref, windowId) => {
      const newRef = {
        [windowId]: ref,
      };

      setPopoutWindowRefs({ ...popoutWindowRefs, ...newRef });
    },
    [popoutWindowRefs],
  );

  const playerRef = useRef(null);
  const isPreviewStage = type === 'preview';
  const matchGameIndex = useGetSelectedMatchGameIndex({ matchId: m });
  const {
    matchGameIndex: selectedMatchGameIndex,
    matchId,
    startTime,
    seekRange,
  } = useGameRedirect(playerRef, m, matchGameIndex);
  const matchGameId = useGetGameId({ matchId, selectedMatchGameIndex });
  const isLiveMatch = useGqlSelector(state => state.games?.[matchGameId]?.status === 'live');

  const base64MatchId = useMemo(() => encodeId(matchId, 'Match'), [matchId]);
  const queryOptions = useMemo(
    () => ({
      ssr: true,
      variables: {
        package: packageName,
        base64MatchId,
      },
    }),
    [base64MatchId, packageName],
  );

  const hasStats = useGetGameHasStats({ matchId, selectedMatchGameIndex });
  useGameTimeInfoEpic(playerRef, hasStats ? matchId : null, selectedMatchGameIndex);

  const { refetch } = useReduxGqlQuery(EmbedPageQuery, queryOptions);

  const prevMatchId = usePrevious(matchId);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!prevMatchId || prevMatchId !== matchId) return;
    refetch();
  }, [selectedMatchGameIndex]);

  const streamTokenRequest = useMemo(() => {
    if (!matchId || !matchGameId) return {};
    return {
      matches: [
        {
          id: matchId,
          gameId: matchGameId,
        },
      ],
    };
  }, [matchId, matchGameId]);

  useFetchVideoCredentials(streamTokenRequest, true);

  useSetupMatchInfo(matchId, selectedMatchGameIndex);
  usePopoutMessenger(matchId, selectedMatchGameIndex, playerRef, popoutWindowRefs);
  useResetGatStats(matchId, selectedMatchGameIndex);

  const onGameSelectionClick = useCallback(
    newGameIndex => {
      const path = global.document ? location.pathname : '';
      const queryObject = queryStringToObject(location.search);
      queryObject.m = matchId;
      queryObject.g = newGameIndex;
      queryObject.t = 0;
      const queryString = objectToQueryString(queryObject);
      navigate(`${path}${queryString}`);
    },
    [location.pathname, location.search, matchId, navigate],
  );

  const playerStreams = useGetMatchPlayerStreams({ matchId });

  const handleDesktopPovSelectorClick = useCallback(
    selectedStreamId => {
      const { selectedStreamId: hasSelectedStreamId } = stageState.streamSelectorState;
      if (hasSelectedStreamId) {
        dispatchStageState({
          type: POV_SELECTOR_RESET,
        });
        return;
      }

      const streamCount = playerStreams.length;
      const isSelected = playerStreams.find(stream => stream.id === selectedStreamId);
      if (streamCount === 1 && isSelected) return;
      dispatchStageState({
        type: POV_SELECTOR_STREAM_ID,
        selectedStreamId,
      });
    },
    [playerStreams, stageState.streamSelectorState],
  );

  const iframeCallbacks = useMemo(
    () => ({
      onSelectStream: handleDesktopPovSelectorClick,
    }),
    [handleDesktopPovSelectorClick],
  );

  useIframeMessenger(playerRef, matchId, selectedMatchGameIndex, iframeCallbacks);

  const closeSelector = useCallback(() => {
    dispatchStageState({
      type: POV_SELECTOR_STREAM_ID,
      selectedStreamId: undefined,
    });
  }, []);

  const { streamSelectorState } = stageState;
  const streamSelectorStreamId = streamSelectorState.selectedStreamId;

  const userHasPremiumAccess = useHasPremiumAccess();
  const premiumContextValue = useMemo(
    () => ({
      userHasPremiumAccess,
      onUnAvailableAction: null,
    }),
    [userHasPremiumAccess],
  );
  const gameTitle = useGetMatchGameTitle({ matchId });
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();

  const HighlightList = useMemo(
    () => HIGHLIGHTLIST_MAP[gameTitle] ?? HIGHLIGHTLIST_MAP.default,
    [gameTitle],
  );

  const leftSidebarActive = statsSidebarVisible;
  const rightSidebarActive = highlightsSidebarVisible || chatSidebarVisible;

  const { spring, createSpringRef } = usePlayerScaling(rightSidebarActive, leftSidebarActive);

  const toggleStatsSidebarVisibility = useCallback(() => {
    dispatchStageState({
      type: SIDEBAR_STATS_VISIBLITIY,
      isVisible: !stageState.statsSidebarVisible,
      largeScreen: isDesktopLargeOrGreater,
    });
  }, [isDesktopLargeOrGreater, stageState.statsSidebarVisible]);

  const toggleHighlightSidebarVisibility = useCallback(() => {
    dispatchStageState({
      type: SIDEBAR_HIGHLIGHTS_VISIBLITIY,
      isVisible: !stageState.highlightsSidebarVisible,
      largeScreen: isDesktopLargeOrGreater,
    });
  }, [isDesktopLargeOrGreater, stageState.highlightsSidebarVisible]);

  const toggleChatSidebarVisibility = useCallback(() => {
    dispatchStageState({
      type: SIDEBAR_CHAT_VISIBILITY,
      isVisible: !stageState.chatSidebarVisible,
      largeScreen: isDesktopLargeOrGreater,
    });
  }, [isDesktopLargeOrGreater, stageState.chatSidebarVisible]);

  return (
    <ThemeProvider theme={theme}>
      <PremiumContentContext.Provider value={premiumContextValue}>
        <GlobalStyle />
        <Container $compact>
          <BodyContainer>
            {isDesktopOrGreater && (
              <Slide
                show={leftSidebarActive && statsSidebarVisible}
                type="left"
                width="auto"
                ref={createSpringRef(LEFT_SIDEBAR_SPRING)}
              >
                <DesktopSidebarContainer data-testid="stage-left-sidebar">
                  <StatsMenu
                    type="compact"
                    withHeader
                    withPlayerSelector
                    matchId={matchId}
                    selectedMatchGameIndex={selectedMatchGameIndex}
                    onClose={toggleStatsSidebarVisibility}
                    onPovViewStreamClick={handleDesktopPovSelectorClick}
                  />
                </DesktopSidebarContainer>
              </Slide>
            )}
            <VideoFrameWrapper ref={createSpringRef(STAGE_SPRING)}>
              <VideoFrame
                ref={playerRef}
                matchId={matchId}
                selectedMatchGameIndex={selectedMatchGameIndex}
                streamSelectorStreamId={streamSelectorStreamId}
                closeSelector={closeSelector}
                handleWindowRefUpdate={handleWindowRefUpdate}
                startTime={startTime}
                springProps={spring}
                initialSeekRange={seekRange}
                setPlayerWidth={createSpringRef(PLAYER_SPRING)}
                statsDisabled={!hasStats}
                preview={isPreviewStage}
                compact
                sidebarsOpen={(leftSidebarActive ? 1 : 0) + (rightSidebarActive ? 1 : 0)}
              />
            </VideoFrameWrapper>
            {isDesktopOrGreater && (
              <>
                <Slide
                  show={highlightsSidebarVisible}
                  type="right"
                  width="auto"
                  ref={createSpringRef(RIGHT_SIDEBAR_SPRING)}
                >
                  <DesktopSidebarContainer data-testid="stage-right-sidebar">
                    <HighlightList
                      onClose={toggleHighlightSidebarVisibility}
                      compact={!isDesktopLargeOrGreater}
                      matchId={matchId}
                      selectedMatchGameIndex={selectedMatchGameIndex}
                      sidebar
                    />
                  </DesktopSidebarContainer>
                </Slide>
                <ChatSideMenu
                  ref={createSpringRef(RIGHT_SIDEBAR_SPRING)}
                  visible={chatSidebarVisible}
                  onClose={toggleChatSidebarVisibility}
                  chatId={matchGameId}
                  matchId={matchId}
                />
              </>
            )}
          </BodyContainer>
          {!isPreviewStage && (
            <StageMatchBar
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              onStatsButtonClick={StatsMenu && hasStats && toggleStatsSidebarVisibility}
              onHighlightClick={hasStats && toggleHighlightSidebarVisibility}
              onChatButtonClick={toggleChatSidebarVisibility}
              onGameSelect={onGameSelectionClick}
              onStreamItemClick={handleDesktopPovSelectorClick}
              statsOpen={false}
              playerRef={playerRef}
              onStreamPopout={handleWindowRefUpdate}
              isLiveMatch={isLiveMatch}
            />
          )}
        </Container>
        <PingFrame />
      </PremiumContentContext.Provider>
    </ThemeProvider>
  );
};

export default Embed;
