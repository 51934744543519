import { memo, forwardRef, useState, useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import withTheme from 'znipe-themes/hocs/withTheme';
import Typography from 'znipe-elements/general/Typography/Typography';
import {
  Container,
  Wrapper,
  InlineLabel,
  InlineLabelContent,
  CleaveInput,
  NormalInput,
  Required,
  Label,
  MaxLength,
  Suffix,
  Comment,
  ErrorMessage,
  Prefix,
} from './Input.styles';
import themes from './Input.themes';
import PostTab from './PostTab';

type Options = {
  creditCard?: boolean;
  onCreditCardTypeChanged?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  date?: boolean;
  datePattern?: ('d' | 'm' | 'y' | 'Y')[];
  dateMin?: string;
  dateMax?: string;
  time?: boolean;
  timePattern?: ('h' | 'm' | 's')[];
  timeFormat?: '12' | '24';
  numericOnly?: boolean;
};

type InputProps = {
  customInput?: typeof React.Component;
  label?: string;
  name?: string;
  error?: boolean;
  focus?: boolean;
  active?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  showLengthCount?: boolean;
  useCleave?: boolean;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>, postTabValue?: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBeforeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  labelPosition?: 'inline' | 'left' | 'top';
  labelAlign?: 'flex-start' | 'flex-end' | 'center';
  options?: Options;
  centerAlign?: string;
  placeholder?: string;
  customWidth?: string;
  defaultValue?: string;
  prefix?: string;
  suffix?: string;
  postTab?: string[];
  defaultPostTab?: string;
  errorMessage?: string;
  inputType?: string;
  comment?: React.ReactNode;
  lightLabel?: boolean;
  hideOverflow?: boolean;
  'data-testid'?: string;
};

const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      customInput: CustomInput,
      maxLength,
      name,
      options,
      error = false,
      focus = false,
      active = false,
      disabled = false,
      useCleave = false,
      isRequired = false,
      showLengthCount = false,
      lightLabel = false,
      hideOverflow = true,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      onKeyUp,
      onBeforeInput,
      label = '',
      centerAlign = '',
      placeholder = '',
      customWidth = '',
      defaultValue = '',
      prefix = '',
      suffix = '',
      postTab,
      defaultPostTab = '',
      errorMessage = '',
      comment = '',
      inputType = 'text',
      labelPosition = 'left',
      labelAlign = 'flex-start',
      'data-testid': testId = 'input',
    },
    ref,
  ) => {
    const [data, setData] = useState(defaultValue);
    const [currentLength, setCurrentLength] = useState(0);
    const [postTabValue, setPostTabValue] = useState<string>();

    // Casting here as ref does not work on customInput
    const Input =
      (CustomInput as typeof NormalInput | undefined) || (useCleave ? CleaveInput : NormalInput);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          if (postTab?.length) {
            onChange(e, postTabValue);
          }
          onChange(e);
        }
        const { target } = e;
        if (target) {
          const { value } = target;
          setData(value);
          setCurrentLength(value.length);
        }
      },
      [onChange, postTabValue, postTab?.length],
    );

    useEffect(() => {
      setCurrentLength(defaultValue.length);
    }, [defaultValue]);

    useEffect(() => {
      if (postTab && postTab.length > 0) {
        setPostTabValue(defaultPostTab !== '' ? defaultPostTab : postTab[0]);
      }
    }, [postTab, defaultPostTab]);

    const getMaxLengthCounter = () => {
      if (showLengthCount && maxLength) {
        const remainingLength = maxLength - currentLength;
        return (
          <MaxLength
            $isCloseToMax={remainingLength / maxLength < 0.35}
            $isMax={remainingLength <= 0}
          >{`${remainingLength}/${maxLength}`}</MaxLength>
        );
      }
      return null;
    };

    const handleClickPostTab = useCallback(
      (e: React.MouseEvent<HTMLOptionElement>) => {
        const newValue = (e.target as HTMLOptionElement).id;
        if (postTabValue === newValue) return;
        setPostTabValue(newValue);
        onChange?.(undefined, newValue);
      },
      [postTabValue, onChange],
    );

    const isInline = labelPosition === 'inline';
    const isHorizontal = labelPosition === 'top';
    const showErrorMessage = error && errorMessage !== '';

    const commentElement = useMemo(
      () =>
        comment && (
          <Comment $centerAlign={centerAlign} $customWidth={customWidth}>
            <Typography type="paragraph-s">{comment}</Typography>
          </Comment>
        ),
      [comment, centerAlign, customWidth],
    );

    return (
      <Container $hideOverflow={hideOverflow}>
        <Wrapper data-testid="text-input" $isHorizontal={isHorizontal}>
          {!isInline && label && (
            <Label
              data-testid={`label-${labelPosition}`}
              $error={error}
              $centerAlign={centerAlign}
              $labelAlign={labelAlign}
              $lightLabel={lightLabel}
            >
              <Typography type={lightLabel ? 'paragraph-m' : 'heading-xs'}>{label}</Typography>
              {isRequired && <Required />}
            </Label>
          )}
          {isHorizontal && commentElement}
          {prefix !== '' && (
            <Prefix
              $width={customWidth || '100%'}
              $centerAlign={centerAlign}
              $labelPosition={labelPosition}
              $disabled={disabled}
            >
              <Typography type="title-xs">{prefix}</Typography>
            </Prefix>
          )}
          <Input
            ref={ref}
            name={name}
            data-testid={testId}
            $error={error}
            $focus={focus}
            autoComplete="autocomplete-off"
            disabled={disabled}
            $isInline={isInline}
            $centerAlign={centerAlign}
            placeholder={placeholder}
            $required={inputType === 'time'}
            $step={inputType === 'time' ? 2 : undefined}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            onBeforeInput={onBeforeInput}
            $maxLength={maxLength}
            options={options}
            $customWidth={customWidth}
            $hasTopMargin={Boolean(label && labelPosition === 'top')}
            type={inputType}
            defaultValue={data}
          />
          {suffix !== '' && (
            <Suffix
              $width={customWidth || '100%'}
              $centerAlign={centerAlign}
              $labelPosition={labelPosition}
              $disabled={disabled}
            >
              <Typography type="title-xs">{suffix}</Typography>
            </Suffix>
          )}
          {postTab?.length && (
            <PostTab
              tabs={postTab}
              centerAlign={centerAlign}
              customWidth={customWidth}
              selectedOption={postTabValue}
              isInline={isInline}
              onClickOption={handleClickPostTab}
            />
          )}
          {isInline && (
            <InlineLabel data-testid="label-inline" $error={error} $focus={focus}>
              <InlineLabelContent $hasData={active || data.length > 0}>
                <Typography type="heading-xs">{label}</Typography>
                {isRequired && <Required />}
              </InlineLabelContent>
            </InlineLabel>
          )}
          {getMaxLengthCounter()}
        </Wrapper>
        {!isHorizontal && commentElement}
        {showErrorMessage && (
          <ErrorMessage $centerAlign={centerAlign} $customWidth={customWidth}>
            {errorMessage}
          </ErrorMessage>
        )}
      </Container>
    );
  },
);

const DefaultInput = withTheme(memo(TextInput), themes, 'input');

const withCleaveJs = (ComposedComponent: typeof DefaultInput) =>
  React.forwardRef((props, ref) => <ComposedComponent useCleave {...props} ref={ref} />);

export const CleaveJsInput = withCleaveJs(DefaultInput);

export default DefaultInput;
