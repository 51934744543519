import { css } from 'styled-components';

const hidden = css`
  display: none;
`;

const Scrollbar = css`
  overflow-y: auto;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    ${({ $hideScrollBar }) => $hideScrollBar && hidden};
    width: 8px;
    height: 8px;
    background-color: transparent;
    margin-left: 10px;
  }
  &::-webkit-scrollbar-track {
    ${({ $hideScrollBar }) => $hideScrollBar && hidden};
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    ${({ $hideScrollBar }) => $hideScrollBar && hidden};
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export default Scrollbar;
