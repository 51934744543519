import { styled, css } from 'styled-components';
import { animated } from '@react-spring/web';
import {
  variants,
  heights,
  borderRadius,
  InteractionColors,
} from 'znipe-elements/general/Button/Button.styles';
import type { Size, Variant } from './IconButton';

const minWidths = {
  xsmall: '32px',
  small: '40px',
  medium: '48px',
};

const size = css<{ $size: Size }>`
  height: ${({ $size }) => heights[$size]};
  min-width: ${({ $size }) => minWidths[$size]};
  border-radius: ${({ $size }) => borderRadius[$size]};

  ${InteractionColors} {
    border-radius:${({ $size }) => borderRadius[$size]};
  }
`;

const disabledStyle = css`
  opacity: 50%;
  cursor: not-allowed;
`;

export const StyledButton = styled(animated.button)<{
  $size: Size;
  $variant: Variant;
  $disabled: boolean;
}>`
  border: none;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;


  ${size};
  padding: 0;

  ${({ $variant }) => variants[$variant]};
  ${({ $disabled }) => $disabled && disabledStyle};
`;

export default StyledButton;
