import { schema } from 'normalizr';
import {
  eventSubSchema,
  tournamentSchema,
  streamUrlSchema,
  gameSchema,
  teamSchema,
  cleanNodes,
  removeUndefined,
} from './rootSchema.normalizr';

export const highlightSchema = new schema.Entity('highlights', {});

const matchesSchema = new schema.Entity('latestMatches', {
  teams: [teamSchema],
  games: [gameSchema],
  tournament: tournamentSchema,
  streams: [streamUrlSchema],
});

const scheduledMatchesSchema = new schema.Entity('scheduledMatches', {
  teams: [teamSchema],
  games: [gameSchema],
  tournament: tournamentSchema,
  streams: [streamUrlSchema],
});

export const playerSchema = new schema.Entity(
  'players',
  {
    latestMatches: [matchesSchema],
    scheduledMatches: [scheduledMatchesSchema],
    highlights: [highlightSchema],
  },
  {
    processStrategy: ({ playerDetails, ...playerCopy }) => {
      const { highlights, latestMatches, scheduledMatches, team } = playerDetails;
      const newValues = {
        ...team,
        ...playerCopy,
        playerDetails: {},
        ...playerDetails,
        highlights: highlights?.edges.map(cleanNodes) || [],
        latestMatches: latestMatches?.edges?.map(cleanNodes) || [],
        scheduledMatches: scheduledMatches?.edges?.map(cleanNodes) || [],
      };
      return removeUndefined(newValues);
    },
  },
);

export const riotChampionSchema = new schema.Entity(
  'champions',
  {
    scheduledMatches: [scheduledMatchesSchema],
    latestMatches: [matchesSchema],
    highlights: [highlightSchema],
  },
  {
    idAttribute: value => value.champId,
    processStrategy: ({ riotChampionDetails, ...value }) => {
      const { edges } = riotChampionDetails;
      const { id, highlights, scheduledMatches, latestMatches, ...champDetails } =
        edges?.[0]?.node ?? {};
      const newValues = {
        ...value,
        riotChampionDetails: {},
        ...champDetails,
        id,
        highlights: highlights?.edges.map(cleanNodes) || [],
        scheduledMatches: scheduledMatches?.edges.map(cleanNodes) || [],
        latestMatches: latestMatches?.edges.map(cleanNodes) || [],
      };
      return removeUndefined(newValues);
    },
  },
);

export const teamSubSchema = new schema.Entity(
  'team',
  {
    scheduledMatches: [scheduledMatchesSchema],
    latestMatches: [matchesSchema],
    highlights: [highlightSchema],
  },
  {
    processStrategy: ({ teamDetails }) => {
      const { highlights, latestMatches, scheduledMatches } = teamDetails;
      const newValues = {
        teamDetails: {},
        ...teamDetails,
        latestMatches: latestMatches?.edges?.map(cleanNodes) || [],
        scheduledMatches: scheduledMatches?.edges?.map(cleanNodes) || [],
        highlights: highlights?.edges?.map(cleanNodes) || [],
      };
      return removeUndefined(newValues);
    },
  },
);

export const contentSubSchema = new schema.Entity('contentSubscription', {
  player: playerSchema,
  team: teamSubSchema,
  riotChampion: riotChampionSchema,
  event: eventSubSchema,
});

export const contentSubscriptionSchema = new schema.Entity(
  'data',
  {
    contentSubscriptions: [contentSubSchema],
  },
  {
    // should get eventid / partner-id / humanReadableId
    idAttribute: () => 'riot-games',
  },
);
