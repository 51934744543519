import { useEffect, useState, useContext } from 'react';
import { error } from 'znipe-logger';
import { styled, css, CSSProperties } from 'styled-components';
import { sanitizeHTML } from 'znipe-elements/layout/withDangerouslySetInnerHTML/withDangerouslySetInnerHTML';
import useThemeContext from 'znipe-hooks/useThemeContext';
import isBrowser from 'znipe-utils/web/isBrowser';
import loadable from '@loadable/component';
import colors from 'znipe-styles/colors';
import { IconContext } from './IconProvider';
import reference, {
  DefaultIconNames,
  FaceitIconNames,
  ZnipeBaseIconNames,
  SvenskaCupenIconNames,
  ProviewIconNames,
} from './reference';

const otherGeneralIcons = {
  headphonesDisabled: loadable(() => import('./icons/general/HeadphonesDisabledIcon')),
  cursiveArrow: loadable(() => import('./icons/general/CursiveArrow')),
  cursiveArrowLarge: loadable(() => import('./icons/general/CursiveArrowLarge')),
  lec: loadable(() => import('./icons/general/LEC')),
  pencil: loadable(() => import('./icons/general/Pencil')),
  warningTriangleFilled: loadable(() => import('./icons/general/WarningTriangleFilled')),
  spinner: loadable(() => import('./icons/general/Spinner')),
};

const otherZnipebaseIcons = {
  ...otherGeneralIcons,
  adminShield: loadable(() => import('./icons/znipebase/AdminShield')),
};

const otherUbisoftIcons = {
  ...otherGeneralIcons,
  versus: loadable(() => import('./icons/ubisoft/Versus')),
};

const customThemePackage = {
  znipebase: otherZnipebaseIcons,
  default: otherGeneralIcons,
  proview: otherGeneralIcons,
  'svenska-cupen': otherGeneralIcons,
  faceit: otherGeneralIcons,
  ubisoft: otherUbisoftIcons,
  riot: otherGeneralIcons,
  efg: otherGeneralIcons,
  znipe: otherGeneralIcons,
  esportal: otherGeneralIcons,
};

export type IconName =
  | DefaultIconNames
  | ZnipeBaseIconNames
  | SvenskaCupenIconNames
  | FaceitIconNames
  | ProviewIconNames
  | keyof typeof otherGeneralIcons
  | keyof typeof otherZnipebaseIcons
  | keyof typeof otherUbisoftIcons;

type IconProps = {
  type: IconName | '';
  size?: number;
  inline?: boolean;
  inactive?: boolean;
  additionalStyles?: string;
  fillColor?: string;
  hoverColor?: string;
  activeColor?: string;
  gradient?: boolean;
  style?: CSSProperties;
};

export const IconWrapperContainer = styled.div<{
  $size?: number;
  $additionalStyles?: string;
  $inline?: boolean;
  $fillColor?: string;
  $inactive?: boolean;
  $hoverColor?: string;
  $activeColor?: string;
  $gradient?: boolean;
}>`
  height: ${({ $size }) => ($size ? `${$size}px` : `100%`)};
  ${({ $inactive }) => $inactive && 'opacity: 0.8;'}
  ${({ $additionalStyles }) => $additionalStyles}
  ${({ $inline }) =>
    $inline
      ? css`
          display: inline-flex;
          align-items: center;
        `
      : css`
          width: 100%;
          line-height: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        `};

  svg {
    height: 100%;
    width: auto;
  }

  ${({ $fillColor }) =>
    $fillColor &&
    css`
      svg {
        fill: ${$fillColor};
        color: ${$fillColor};
      }
    `}
`;

const Icon = ({
  type,
  size = 24,
  inline = false,
  inactive = false,
  additionalStyles,
  fillColor = colors.white,
  hoverColor,
  activeColor,
  gradient,
  style,
}: IconProps) => {
  const [componentString, setComponent] = useState<string>('');
  const { themeName } = useThemeContext();
  const fetchIcon = useContext(IconContext);

  useEffect(() => {
    if (!type) return;
    if (!isBrowser()) return;

    const themePackage = reference[themeName ?? 'default'];
    const iconPath = themePackage[type as DefaultIconNames]; // Cannot know which type is and typescript cant know if it actuall exists

    if (!iconPath) return;

    fetchIcon(iconPath)
      ?.then(svg => setComponent(svg.trim()))
      .catch(error);
  }, [themeName, type, fetchIcon]);

  if (customThemePackage[themeName]?.[type as keyof typeof otherGeneralIcons]) {
    const IconComponent = customThemePackage[themeName][type as keyof typeof otherGeneralIcons];
    return (
      <IconWrapperContainer
        className="styled-component-icon__wrapper"
        $inline={inline}
        $size={size}
        data-testid={`${type}-icon`}
        style={style}
        $additionalStyles={additionalStyles}
      >
        <IconComponent
          fillColor={fillColor}
          hoverColor={hoverColor}
          activeColor={activeColor}
          gradient={gradient}
        />
      </IconWrapperContainer>
    );
  }

  return (
    <IconWrapperContainer
      $size={size}
      $inline={inline}
      $additionalStyles={additionalStyles}
      $fillColor={fillColor}
      $inactive={inactive}
      data-testid={`${type}-icon`}
      style={style}
      // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
      dangerouslySetInnerHTML={{ __html: sanitizeHTML(componentString) }}
    />
  );
};

export default Icon;
