import { error } from 'znipe-logger';
import ZnipeEvent from './ZnipeEvent';

export type Listener = (this: ZnipeEventTarget, ev: any) => void;

class ZnipeEventTarget extends EventTarget {
  private readonly listeners: Record<string, Listener[]>;

  private readonly dispatchTarget: this;

  private readonly externalSettings: Record<string, unknown>;

  private host: Element | null;

  constructor() {
    super();
    this.listeners = {};
    this.dispatchTarget = this;
    this.externalSettings = {};
    this.host = null;
  }

  addEventListener = (type: string, listener: Listener) => {
    if (this.listeners[type]) this.listeners[type].push(listener);
    else this.listeners[type] = [listener];
  };

  removeEventListener(type: string, listener: Listener) {
    if (!this.listeners[type]) {
      return;
    }
    this.listeners[type] = this.listeners[type].filter(i => i !== listener);
  }

  dispatchEvent(event: ZnipeEvent) {
    if (!(event instanceof ZnipeEvent)) {
      throw new Error('ZnipeEventTarget can only dispatch FakeEvents');
    }

    if (this.host) {
      try {
        this.host.dispatchEvent(event);
      } catch (exception) {
        error(
          'Uncaught exception in event handler when dispatching to host element',
          exception,
          exception ? (exception as Error).message : null,
          exception ? (exception as Error).stack : null,
        );
      }
    }
    // Take a copy of all listeners in case of changes while dispatching
    const listeners = this.listeners[event.type] ? this.listeners[event.type].slice() : [];

    // This is a way of breaking a forEach loop
    // https://stackoverflow.com/a/6260865/2319925
    listeners.every(listener => {
      try {
        listener.call(this, event);
      } catch (exception) {
        // Exceptions during event handlers should not affect the caller, but should appear on the console as uncaught.
        // https://mzl.la/2JXgwRo
        error(
          'Uncaught exception in event handler',
          exception,
          exception ? (exception as Error).message : null,
          exception ? (exception as Error).stack : null,
        );
      }
      if (event.stopped) return false;
      return true;
    });

    return event.defaultPrevented;
  }

  setValue = (key: string, value: unknown) => {
    this.externalSettings[key] = value;
  };

  deleteValue = (key: string) => {
    delete this.externalSettings[key];
  };

  getValue = (key: string) => this.externalSettings[key];

  setHost = (host: Element | null) => {
    this.host = host;
  };
}

export default ZnipeEventTarget;
