import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';

export const Container = styled.div`
  height: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.containerStroke};
  overflow: hidden;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  ${({ $borderRadius }) =>
    $borderRadius &&
    css`
      border-radius: ${$borderRadius};
    `}
`;
export const Countdown = styled.div.attrs(({ fillRatio, transitionDurationWidth }) => ({
  style: {
    width: `${fillRatio}%`,
    transition: `width ${transitionDurationWidth}s linear`,
  },
}))`
  background: ${({ theme, $barColor }) => theme?.[$barColor] ?? colors[$barColor] ?? $barColor};
  height: inherit;
`;

export default Container;
