import { styled, css } from 'styled-components';

type ButtonProps = {
  $size: number;
};

export const Button = styled.button<ButtonProps>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  background: transparent;
  position: relative;
  overflow: hidden;
  ${({ $size }) => css`
    height: ${$size}px;
    width: ${$size}px;
  `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: none;
    transition: all 0.1s;
  }

  &:hover::after,
  &:focus::after {
    background: rgba(255, 255, 255, 0.15);
  }

  @media (prefers-reduced-motion) {
    &::after {
      transition: none;
    }
  }
`;
