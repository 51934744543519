import { styled } from 'styled-components';

const Triangle = styled.div`
  display: flex;
  width: 0;
  height: 0;
  border-left: ${({ width }) => `${width}px`} solid transparent;
  border-right: ${({ width }) => `${width}px`} solid transparent;
  border-bottom: ${({ height }) => `${height}px`} solid
    ${({ theme, team }) => (team === 1 ? theme.teamOneColor : theme.teamTwoColor)};
  transform: ${({ rotating }) => `rotate(${rotating})`};
`;

export default Triangle;
