import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled, css } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';
import colors from 'znipe-styles/colors';

export const XSMALL = 'xsmall';
export const SMALL = 'small';
export const MEDIUM = 'medium';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const KDAWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  width: 100%;
  ${props =>
    props.theme.kdaLineHeight &&
    css`
      line-height: ${props.theme.kdaLineHeight};
    `}
`;

const KDA = ({
  size = XSMALL,
  color = colors.white,
  kills = 0,
  deaths = 0,
  assists = 0,
  icon = null,
  swapDeathsAssists = false,
  withWhiteSpace = true,
}) => {
  const typographySize = useMemo(() => {
    const sizeMap = {
      xsmall: 'title-xs',
      small: 'title-s',
      medium: 'title-m',
    };

    return sizeMap[size];
  }, [size]);

  const kdaElements = useMemo(() => {
    const sorted = swapDeathsAssists ? [kills, assists, deaths] : [kills, deaths, assists];
    return (
      <Typography type={typographySize} color={color}>
        {sorted.join(withWhiteSpace ? ' / ' : '/')}
      </Typography>
    );
  }, [kills, assists, deaths, swapDeathsAssists, color, withWhiteSpace, typographySize]);

  return (
    <Container>
      {icon && <div data-testid="KDA-image">{icon}</div>}
      <KDAWrapper>{kdaElements}</KDAWrapper>
    </Container>
  );
};

KDA.propTypes = {
  size: PropTypes.oneOf([XSMALL, SMALL, MEDIUM]),
  color: PropTypes.string,
  kills: PropTypes.number,
  deaths: PropTypes.number,
  assists: PropTypes.number,
  icon: PropTypes.element,
  swapDeathsAssists: PropTypes.bool,
  withWhiteSpace: PropTypes.bool,
};

export default KDA;
