import { memo, useState } from 'react';
import Icon from 'znipe-elements/general/Icon/Icon';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Container, Button, IconWrapper, OptionWrapper } from './PostTab.styles';

const emptyCallback = () => {};

type PostTabProps = {
  tabs: string[];
  width?: string;
  centerAlign?: string;
  customWidth?: string;
  selectedOption?: string;
  isInline?: boolean;
  onClickOption?: (e: React.MouseEvent<HTMLOptionElement>) => void;
};

const PostTab: React.FC<PostTabProps> = ({
  tabs,
  width = '60px',
  centerAlign = '',
  customWidth = '',
  selectedOption = '',
  isInline = false,
  onClickOption,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleClickOption = (e: React.MouseEvent<HTMLOptionElement>) => {
    if (onClickOption) onClickOption(e);
    setShowOptions(!showOptions);
  };

  return (
    <Container
      $width={width}
      $centerAlign={centerAlign}
      $customWidth={customWidth}
      $elementWidth="60px"
    >
      <Button
        onClick={() => setShowOptions(!showOptions)}
        role="button"
        tabIndex={0}
        $width={width}
        data-testid="post-tab-button"
      >
        <Typography type="title-xs">{selectedOption || tabs[0]}</Typography>
        <IconWrapper>
          <Icon type="arrowUpDown" size={16} />
        </IconWrapper>
      </Button>
      {showOptions && (
        <OptionWrapper $isInline={isInline} role="listbox" tabIndex={0}>
          {tabs.map(option => (
            <option
              key={option}
              id={option}
              onClick={handleClickOption}
              data-testid="post-tab-option"
              onKeyUp={emptyCallback}
              onKeyDown={emptyCallback}
            >
              {option}
            </option>
          ))}
        </OptionWrapper>
      )}
    </Container>
  );
};

export default memo(PostTab);
