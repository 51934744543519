import { schema } from 'normalizr';
import { tournamentSchema, packageSchema } from './rootSchema.normalizr';

export const standingsSchema = new schema.Entity(
  'data',
  {
    tournaments: [tournamentSchema],
    packages: [packageSchema],
  },
  {
    idAttribute: () => 'standings',
    processStrategy: values => ({
      ...values,
      events: values.tournaments?.map(({ event }) => event?.id),
    }),
  },
);
