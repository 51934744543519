import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';
import Scrollbar from 'znipe-elements/general/Scrollbar/Scrollbar.styles';

export const FeedContainer = styled.div<{ isScrolledUp?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 8px;
  box-sizing: border-box;
  ${Scrollbar}
  > *:last-child {
    margin-bottom: 8px;
  }

  ${({ isScrolledUp }) =>
    isScrolledUp &&
    css`
      margin-bottom: 30px;
      height: calc(100% - 30px);
    `}
`;

export const ScrollDownButton = styled.div`
  margin: -30px 12px 12px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background: ${colors.grey30};
`;
