import { styled } from 'styled-components';
import colors from 'znipe-styles/colors';
import convertHexToRGB from 'znipe-utils/misc/convertHexToRGB';
import Typography from 'znipe-elements/general/Typography/Typography';
import { Tile } from 'znipe-chat/src/components/Emojis/EmojiTile/EmojiTile.styles';

const textColorWhite = convertHexToRGB(colors.white, 0.87);

export const MessageWrapper = styled(Typography).attrs({ forwardedAs: 'div' })`
  margin: 0;
  color: ${({ $dimmedText, theme }) => ($dimmedText ? theme.textSecondary : textColorWhite)};
  align-self: center;
  display: flex;
  flex: 1;
`;

export const Timestamp = styled(Typography)`
  margin-right: 8px;
`;

export const ReplyTarget = styled(Typography)<{ $color?: string }>`
  font-weight: 700;
  line-height: 20px;
  color: ${({ $color }) => $color || textColorWhite};
`;

export const ReplyTagWrapper = styled.div`
  width: fit-content;
  display: inline-flex;
  border-radius: 2px;
  padding: 0 2px;
  margin: 0 4px 0 -4px;
  background-color: ${({ theme }) => theme.containerStroke};
`;

export const MessageHtml = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

/* if we don't go with Option B we should atleast go with option A */
export const EmojiContainer = styled.div`
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  /* Option A */
  /* transform: translateY(-25%); */

  & + & {
    margin-left: -6px;
  }

  /* Option B */
  ${Tile} {
    width: inherit;
    height: inherit;
    img,
    svg {
      width: 20px;
      height: 20px;
      transform: scale(1.2);
    }
  }
`;
