import { styled, css } from 'styled-components';
import PropTypes from 'prop-types';
import colors from 'znipe-styles/colors';

const StyledButton = styled.button`
  width: ${props => (props.size === 'large' ? '65px' : '33px')};
  cursor: ${({ clickable = true }) => clickable && 'pointer'};
  height: 33px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  color: ${props => props.theme.textPrimary};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ transparent }) =>
    transparent
      ? css`
          border: 1px solid transparent;
          background-color: transparent;
        `
      : css`
          border: 1px solid ${colors.white20};
          background-color: ${colors.white20};
        `}
`;

StyledButton.propTypes = {
  clickable: PropTypes.bool,
};

export default StyledButton;
