import { useEffect, useState } from 'react';
import ls from 'local-storage';
import { useUserSession } from 'tv-selectors/deviceInfo/makeGetUserSession';
import useThemeContext from 'znipe-hooks/useThemeContext';

const ONBOARDING_SESSION_COUNT = 'ONBOARDING_SESSION_COUNT';

const usePageBanner = targetId => {
  const [banner, setBanner] = useState({});
  const userSession = useUserSession();
  const { home } = useThemeContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run once
  useEffect(() => {
    const selectedBanner = home[targetId];
    if (!selectedBanner) {
      setBanner({ isEnabled: false });
      return;
    }
    const currentSessionCount = ls.get(ONBOARDING_SESSION_COUNT) || {};
    const bannerSession = currentSessionCount[targetId] || {};
    const currentCount = bannerSession.count || 0;
    const lastUpdateSessionId = bannerSession.sessionId || 0;

    if (currentCount >= selectedBanner.minSessions) {
      setBanner({ ...selectedBanner, isEnabled: true });
      return;
    }
    // only update the counter once per session
    if (userSession !== lastUpdateSessionId) {
      ls.set(ONBOARDING_SESSION_COUNT, {
        ...currentSessionCount,
        [targetId]: {
          count: currentCount + 1,
          sessionId: userSession,
        },
      });
    }
    setBanner({ ...selectedBanner, isEnabled: false });
  }, []);

  return banner;
};

export default usePageBanner;
