import { useMemo, useState, useCallback, memo, useEffect } from 'react';
import StickyComponent from 'znipe-elements/layout/StickyComponent/StickyComponent';
import { BodyWrapper, TabsetWrapper } from 'tv-elements/layout/PageLayout/PageLayout';
import useQueryObject from 'tv-hooks/useQueryObject';
import { useIsMobile } from 'tv-selectors/deviceInfo/makeGetIsMobile';
import { useGetIsMobilePortraitOrGreater } from 'tv-selectors/browser/makeGetIsMobilePortraitOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import Tabset from 'znipe-elements/navigation/Tabset/Tabset';
import useFetchVideoCredentials from 'tv-hooks/useFetchVideoCredentials';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetScheduledMatches } from 'tv-selectors/page/makeGetScheduledMatches';
import { useGetVodMatches } from 'tv-selectors/page/makeGetVodMatches';
import useGroupMatchesByDate, { ASC, DESC } from 'tv-hooks/useGroupMatchesByDate';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import { MATCHES_PAGE } from 'tv-constants/pageTypes';
import { useGetMatchPreviews } from 'tv-selectors/matchPreviews/makeGetMatchPreviews';
import { useGetEvents as useGetTournamentEvents } from 'tv-selectors/events/makeGetEvents';
import { useGetPageCursors } from 'tv-selectors/page/makeGetPageCursors';
import debounce from 'lodash.debounce';
import GroupedMatchList from 'tv-modules/Matches/GroupedMatchList/GroupedMatchList';
import LoadingSpinner from 'znipe-elements/feedback/LoadingSpinner/StyledLoadingSpinner';
import IntroBanner from 'tv-modules/Onboarding/IntroBanner/IntroBanner';
import colors from 'znipe-styles/colors';
import usePackageName from 'tv-hooks/usePackageName';
import withTheme from 'znipe-themes/hocs/withTheme';
import useThemeContext from 'znipe-hooks/useThemeContext';
import FiltersTabset, { SORT_RECENT } from 'tv-modules/Filters/FiltersTabset/FiltersTabset';
import { SCHEDULE, VODS, themes } from './Matches.constants';
import MatchesQuery from './getMatchesPageData.graphql';
import useFilterOptions from './useFilterOptions';

const tabOptions = [SCHEDULE, VODS];
const tabsetMenu = tabOptions.map(item => ({ label: item }));

const Matches = () => {
  const { sort } = useQueryObject();
  const isMobile = useIsMobile();
  const isMobilePortraitOrGreater = useGetIsMobilePortraitOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const pageCursors = useGetPageCursors({ pageType: 'matches' });
  const { packageSlug, initialTab } = useParams();
  const packageName = usePackageName();
  const [activeTab, setActiveTab] = useState(SCHEDULE);
  const [isSticky, setIsSticky] = useState(false);
  const [pageFilters, setPageFilters] = useState({});
  const [selectedSortType, setSelectedSortType] = useState(sort || SORT_RECENT);

  const tournamentEvents = useGetTournamentEvents();

  const [nextCursor, setNextCursor] = useState('');

  const queryOptions = useMemo(() => {
    let activeFilters = {};
    if (pageFilters.filterParams) {
      activeFilters = Object.keys(pageFilters.filterParams)
        .filter(key => !!pageFilters.filterParams[key] !== false)
        .reduce((acc, key) => ({ ...acc, [key]: pageFilters.filterParams[key] }), {});
    }
    return {
      variables: {
        package: packageName,
        nextPage: nextCursor,
        vodSort: 'DESC',
        ...activeFilters,
      },
    };
  }, [nextCursor, packageName, pageFilters]);

  const handleFilterChange = useCallback(filter => {
    setPageFilters(filter);
  }, []);

  const handleSortChange = useCallback(filter => {
    setSelectedSortType(filter);
  }, []);

  const queryOptionsWithFilters = useMemo(() => {
    const vodSortType = selectedSortType === SORT_RECENT ? 'DESC' : 'ASC';
    return {
      variables: { ...queryOptions.variables, vodSort: vodSortType },
    };
  }, [queryOptions, selectedSortType]);

  const { loading: queryDataLoading } = useReduxGqlQuery(MatchesQuery, queryOptionsWithFilters);

  const { requestHashId } = pageFilters;

  const scheduledMatches = useGetScheduledMatches({ pageType: MATCHES_PAGE });
  const [allScheduled, setScheduled] = useState({
    [requestHashId]: scheduledMatches,
  });
  const currentScheduleList = allScheduled[requestHashId];
  const groupedScheduledMatches = useGroupMatchesByDate(currentScheduleList, ASC);

  const vodMatches = useGetVodMatches({ pageType: MATCHES_PAGE });
  const [allVods, setAllVods] = useState({ [requestHashId]: vodMatches });

  const currentVodsList = allVods[requestHashId];
  const groupedVodMatches = useGroupMatchesByDate(
    currentVodsList,
    selectedSortType === SORT_RECENT ? DESC : ASC,
  );

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const selectedTab = tabOptions.includes(initialTab) ? initialTab : SCHEDULE;
    setActiveTab(selectedTab);
  }, [initialTab]);

  const handleTabClick = useCallback(
    nextView => {
      if (nextView?.label === activeTab) return;
      const baseUrl = packageSlug ? `/${packageSlug}` : '';
      const nextPath =
        nextView?.label === VODS ? `${baseUrl}/matches/${VODS}` : `${baseUrl}/matches`;
      navigate(nextPath);
      setActiveTab(nextView?.label);
    },
    [activeTab, navigate, packageSlug],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setAllVods(prevMatches => {
      const prevMatchesList = prevMatches[requestHashId] || [];
      return {
        ...prevMatches,
        [requestHashId]: [...prevMatchesList, ...vodMatches],
      };
    });
    setLoading(false);
  }, [vodMatches]); // do not include requestHashId

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setScheduled(prevMatches => {
      const prevMatchesList = prevMatches[requestHashId] || [];
      return {
        ...prevMatches,
        [requestHashId]: [...prevMatchesList, ...scheduledMatches],
      };
    });
    setLoading(false);
  }, [scheduledMatches]); // do not include requestHashId

  const matchPreviews = useGetMatchPreviews();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const requestedMatchIds = useMemo(() => {
    const allMatches = vodMatches.concat(scheduledMatches);
    const requestArr = [];
    allMatches.forEach(matchId => {
      const previewStreamId = matchPreviews[matchId];
      if (previewStreamId) {
        requestArr.push({ id: matchId, streams: [previewStreamId] });
      }
    });
    return { matches: requestArr };
  }, [vodMatches, scheduledMatches]); // o not include matchPreviews

  useFetchVideoCredentials(requestedMatchIds);

  const elementSizes = useMemo(() => {
    if (isDesktopOrGreater) {
      return {
        matchItem: 'medium',
        vodItem: 'large',
        showVideo: true,
      };
    }
    if (isTabletOrGreater) {
      return {
        matchItem: 'medium',
        vodItem: 'medium',
        showVideo: true,
      };
    }
    return {
      matchItem: 'small',
      vodItem: 'small',
      showVideo: false,
    };
  }, [isTabletOrGreater, isDesktopOrGreater]);

  const eventFilterOptions = useMemo(() => {
    if (tournamentEvents) {
      return Object.values(tournamentEvents).map(
        ({ id, name: title, logo: image, country: subtitle }) => ({
          id,
          title,
          image,
          subtitle,
        }),
      );
    }
    return [];
  }, [tournamentEvents]);

  const handleNextCursor = useCallback(() => {
    const { vodMatches: vods, scheduledMatches: matches } = pageCursors;
    const { hasNextPage: hasVods, endCursor: vodsCursor } = vods || {};
    const { hasNextPage: hasMatches, endCursor: matchesCursor } = matches || {};

    if (activeTab === SCHEDULE && hasMatches) {
      setNextCursor(matchesCursor);
      setLoading(true);
      return;
    }
    if (activeTab === VODS && hasVods) {
      setNextCursor(vodsCursor);
      setLoading(true);
    }
  }, [activeTab, pageCursors]);

  const handleChange = useMemo(
    () =>
      debounce(isOnScreen => {
        if (isOnScreen) {
          handleNextCursor();
        }
      }, 1000),
    [handleNextCursor],
  );

  const onStickyChange = useCallback(newState => setIsSticky(newState), []);

  const filterOptions = useFilterOptions(activeTab);

  const { matches: matchesTheme } = useThemeContext();
  const { banner } = matchesTheme;

  const OnboardingBanner = useMemo(() => {
    const { targetId, coverImage, title, text } = banner;
    return (
      <IntroBanner
        targetId={targetId}
        coverImage={coverImage}
        title={title}
        text={text}
        margin="16px 0 24px 0"
        isEnabled
      />
    );
  }, [banner]);

  return (
    <BodyWrapper>
      <StickyComponent
        enableStickyScroll={!isMobilePortraitOrGreater}
        onStickyChange={onStickyChange}
      >
        <TabsetWrapper hasBackground={isSticky} data-testid="tab-container">
          <Tabset
            size={isDesktopOrGreater ? 'medium' : 'small'}
            tabs={tabsetMenu}
            activeTab={activeTab}
            onClickTab={handleTabClick}
            alignment="left"
            isMobile={isMobile}
          />
        </TabsetWrapper>
        {isMobilePortraitOrGreater && OnboardingBanner}
        <FiltersTabset
          eventOptions={eventFilterOptions}
          filterOptions={filterOptions}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
        />
      </StickyComponent>
      {!isMobilePortraitOrGreater && OnboardingBanner}
      <div>
        {activeTab === SCHEDULE && (
          <div data-testid="schedule-view">
            <GroupedMatchList
              groupedItems={groupedScheduledMatches}
              size={elementSizes.vodItem}
              callback={handleChange}
              type="schedule"
              isLoading={queryDataLoading}
            />
          </div>
        )}
        {activeTab === VODS && (
          <div data-testid="vod-view">
            <GroupedMatchList
              groupedItems={groupedVodMatches}
              size={elementSizes.vodItem}
              callback={handleChange}
              isLoading={queryDataLoading}
            />
          </div>
        )}
        {loading && <LoadingSpinner fillColor={colors.white} position="relative" />}
      </div>
    </BodyWrapper>
  );
};

export default withTheme(memo(Matches), themes, 'matches');
