import { styled } from 'styled-components';
import useThemeContext from 'znipe-hooks/useThemeContext';
import Typography from 'znipe-elements/general/Typography/Typography';
import Icon from 'znipe-elements/general/Icon/Icon';
import Button from 'znipe-elements/general/Button_deprecated/Button';
import colors from 'znipe-styles/colors';

const Container = styled.div`
  width: 100%;
  padding: 16px 16px 24px;
  box-sizing: border-box;
  text-align: center;
  background: ${({ theme }) => theme.tertiaryBackgroundColor};
  position: absolute;
  bottom: 0;
  z-index: 1;
  box-shadow: 0 0 20px ${colors.black};
`;
const StyledTypography = styled(Typography)`
  margin-bottom: 16px;
`;
const IconWrapper = styled.div`
  margin-bottom: 8px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const DescriptionWrapper = styled.div`
  max-width: 308px;
  margin: auto;
  overflow: hidden;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  /* stylelint-enable property-no-vendor-prefix */
`;
type AlertProps = {
  action: string;
  description?: string;
  onClose: () => void;
  onResolve?: () => void;
  resolveText?: string;
};

const Alert: React.FC<AlertProps> = ({
  action,
  description = '',
  onClose,
  onResolve,
  resolveText = '',
}) => {
  const { errorLight, primaryTextColor, secondaryTextColor } = useThemeContext();
  return (
    <Container>
      <IconWrapper>
        <Icon type="alertTriangle" size={24} fillColor={errorLight} />
      </IconWrapper>
      <StyledTypography type="heading-xs" color={primaryTextColor}>
        {action}
      </StyledTypography>
      {description && (
        <StyledTypography type="paragraph-s" color={secondaryTextColor}>
          <DescriptionWrapper>{description}</DescriptionWrapper>
        </StyledTypography>
      )}
      {onResolve ? (
        <ButtonWrapper>
          <Button variant="secondary" size="xsmall" onClick={onResolve}>
            {resolveText}
          </Button>
          <Button variant="text" size="xsmall" onClick={onClose}>
            Cancel
          </Button>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper>
          <Button variant="secondary" size="xsmall" onClick={onClose}>
            I understand
          </Button>
        </ButtonWrapper>
      )}
    </Container>
  );
};

export default Alert;
