const ENVIRONMENT_IS_REACT_NATIVE =
  typeof navigator === 'object' && navigator.product === 'ReactNative';
const ENVIRONMENT_IS_NODE = typeof process === 'object' && typeof require === 'function';
const ENVIRONMENT_IS_WEB = typeof window === 'object';
const ENVIRONMENT_IS_WORKER = typeof importScripts === 'function';

if (process.env !== 'production' && !global.document) {
  const fetch = require('isomorphic-fetch');
  global.fetch = fetch;
}

let interceptors = [];

const interceptor = (fetch, ...args) => {
  const reversedInterceptors = interceptors.reduce(
    (array, newInterceptor) => [newInterceptor].concat(array),
    [],
  );
  let promise = Promise.resolve(args);

  // Register request interceptors
  reversedInterceptors.forEach(({ request, requestError }) => {
    if (request || requestError) {
      promise = promise.then(params => request(...params), requestError);
    }
  });

  // Register fetch call
  promise = promise.then(params => fetch(...params));

  // Register response interceptors
  reversedInterceptors.forEach(({ response, responseError }) => {
    if (response || responseError) {
      promise = promise.then(response, responseError);
    }
  });

  return promise;
};

const attach = env => {
  // Make sure fetch is available in the given environment
  if (!env.fetch) {
    throw Error('No fetch available. Unable to register fetch-intercept');
  }
  env.fetch = (
    fetch =>
    (...args) =>
      interceptor(fetch, ...args)
  )(env.fetch);
};

if (ENVIRONMENT_IS_REACT_NATIVE) {
  attach(global);
} else if (ENVIRONMENT_IS_WORKER) {
  // biome-ignore lint/style/noRestrictedGlobals: Fine in workers
  attach(self);
} else if (ENVIRONMENT_IS_WEB) {
  attach(window);
} else if (ENVIRONMENT_IS_NODE) {
  attach(global);
} else {
  throw new Error('Unsupported environment for fetch-intercept');
}

export const register = newInterceptor => {
  interceptors.push(newInterceptor);
  return () => {
    const index = interceptors.indexOf(newInterceptor);
    if (index >= 0) {
      interceptors.splice(index, 1);
    }
  };
};
export const clear = () => {
  interceptors = [];
};
