import { styled, css } from 'styled-components';
import prefers from 'znipe-styles/prefers';

export const theme = {
  default: {
    flourish: 'edgedWaves',
  },
  proview: {
    flourish: 'waves',
  },
};

type ContainerProps = {
  $background?: string;
  $activeBackground?: string;
  $active?: boolean;
  $animationDuration?: number;
  $containerMask?: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: ${({
    $background,
    theme: { tertiaryBackgroundColor },
    $active,
    $activeBackground,
  }) => ($active && $activeBackground ? $activeBackground : $background || tertiaryBackgroundColor)};
  ${({ $animationDuration }) =>
    $animationDuration &&
    $animationDuration > 0 &&
    css`
      @media ${prefers.motion} {
        transition: background ${$animationDuration}s linear;
      }
    `}
  ${({ $containerMask }) =>
    $containerMask &&
    css`
      overflow: hidden;
    `}
`;

export const ActiveBackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`;

type ActiveBackgroundContainerProps = {
  active?: boolean;
  animationDuration?: number;
  background?: string;
};

export const ActiveBackgroundContainer = styled.div<ActiveBackgroundContainerProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(${({ active }) => (active ? 0 : 100)}%);
  @media ${prefers.motion} {
    transition: transform ${({ animationDuration }) => animationDuration}s linear;
  }
  background: ${({ background }) => background};
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
