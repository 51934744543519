import { createContext, useRef, useCallback } from 'react';
import fetchIcon from './fetchIcon';

type FetchIcon = (iconPath: string) => Promise<string>;

export const IconContext = createContext<FetchIcon>(fetchIcon);

const IconProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const icons = useRef<Record<string, string>>({});
  const promises = useRef<Record<string, Promise<string>>>({});

  const requestIcon = useCallback(async (iconPath: string) => {
    if (icons.current[iconPath]) return icons.current[iconPath];
    if (!promises.current[iconPath]) promises.current[iconPath] = fetchIcon(iconPath);
    return promises.current[iconPath];
  }, []);

  return <IconContext.Provider value={requestIcon}>{children}</IconContext.Provider>;
};

export default IconProvider;
