import {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import usePrevious from 'znipe-hooks/usePrevious';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import useBeforeWindowClose from 'znipe-hooks/useBeforeWindowClose';
import ZnipeAnalyticsEventListeners from '../analytics/ZnipeAnalyticsEventListeners';

const withZnipeAnalytics = () => ComposedComponent => {
  const znipeAnalytics = forwardRef((props, ref) => {
    const {
      master,
      videoPlayerId,
      streamId,
      matchId,
      userSession,
      userId,
      region,
      clientConfig,
      videoOrigin,
      videoId,
      isIOS,
      packageName,
      productId,
      isPremiumUser,
      authManagerId,
      module,
      playerTag,
      configuration,
      initializedDate,
      isFullscreen,
    } = props;

    const configRef = useRef(configuration);

    const [shakaPlayer, setShakaPlayer] = useState(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Should only be created once
    const listener = useMemo(
      () => new ZnipeAnalyticsEventListeners({ initializedDate }, clientConfig),
      [],
    );
    const shakaPlayerRef = useRef(null);

    useImperativeHandle(
      ref,
      () => {
        if (!shakaPlayer) return null;

        // cloning a class instance will exclude the prototype functions. This code merges
        // merges the shakaPlayer ref object with the AnalyticsListener with the prototype
        // functions intact
        const prototypeFunctions = Object.create(Object.getPrototypeOf(shakaPlayer));
        Object.assign(shakaPlayer, prototypeFunctions, { AnalyticsListener: listener });
        return shakaPlayer;
      },
      [listener, shakaPlayer],
    );

    const handleSetPlayer = useCallback(() => {
      if (shakaPlayerRef.current) {
        setShakaPlayer(shakaPlayerRef.current);
      }
    }, []);

    const reset = useCallback(() => {
      if (!listener) return;
      listener.resetMediaPlayer();
    }, [listener]);

    const onIdChange = useCallback(() => {
      if (!listener || !listener.shakaPlayerInstance) return;
      listener.onEnded();
      listener.removeAllListeners();
      listener.loadMediaAnalytics();
      listener.playerState = listener.PlayerStateEnum.Init;
    }, [listener]);

    useBeforeWindowClose(reset, isIOS);
    const previousStreamId = usePrevious(streamId);

    const updateStreamId = () => {
      if (listener) {
        if (previousStreamId) onIdChange();
        if (!streamId || streamId === 'empty') {
          const cmcd = {
            enabled: false,
          };
          listener.setData('streamId', undefined);
          configRef.current = { ...configuration, cmcd };
          return;
        }
        listener.setData('streamId', streamId);
        const streamSession = generateUniqueId();
        listener.setData('streamSession', streamSession);

        const cmcd = {
          enabled: true,
          useHeaders: false,
          sessionId: streamSession,
          contentId: streamId,
        };

        configRef.current = { ...configuration, cmcd };
      }
    };

    useEffect(() => () => listener?.resetMediaPlayer(), [listener]);

    if (streamId !== previousStreamId) {
      updateStreamId();
    }

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('matchId', matchId);
      return onIdChange;
    }, [listener, matchId, onIdChange]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('videoOrigin', videoOrigin);
      listener.setData('videoId', videoId);
      return onIdChange;
    }, [videoOrigin, videoId, onIdChange, listener]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('userId', userId);
      return () => {};
    }, [listener, userId]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('regionId', region);
      return () => {};
    }, [listener, region]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('userSession', userSession);
      return onIdChange;
    }, [userSession, onIdChange, listener]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('playbackSession', videoPlayerId);
      return onIdChange;
    }, [videoPlayerId, onIdChange, listener]);

    useEffect(() => {
      if (!shakaPlayer) return;
      listener.setMediaPlayer(shakaPlayer);
    }, [listener, shakaPlayer]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('packageName', packageName);
      return onIdChange;
    }, [packageName, onIdChange, listener]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('isPremiumUser', isPremiumUser);
      return onIdChange;
    }, [isPremiumUser, listener, onIdChange]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('productId', productId);
      return onIdChange;
    }, [productId, onIdChange, listener]);

    useEffect(() => {
      if (!listener) return () => {};
      listener.setData('playerTag', playerTag);
      return onIdChange;
    }, [playerTag, onIdChange, listener]);

    useEffect(() => {
      if (!listener) return;
      listener.setAnalyticsManager(authManagerId, module);
    }, [authManagerId, listener, module]);

    useEffect(() => {
      listener.setData('master', master);
      listener.handleHeartbeat(true);
    }, [listener, master]);

    useEffect(() => {
      if (!listener) return;
      listener.setData('isFullscreen', isFullscreen);
    }, [listener, isFullscreen]);

    return (
      <ComposedComponent
        ref={shakaPlayerRef}
        {...props}
        AnalyticsListener={listener}
        onSetPlayer={handleSetPlayer}
        configuration={configRef.current}
      />
    );
  });

  znipeAnalytics.propTypes = {
    module: PropTypes.string.isRequired,
    master: PropTypes.bool.isRequired,
    videoPlayerId: PropTypes.string.isRequired,
    streamId: PropTypes.string,
    matchId: PropTypes.string.isRequired,
    videoOrigin: PropTypes.string,
    videoId: PropTypes.string,
    userSession: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    packageName: PropTypes.string,
    productId: PropTypes.string,
    isPremiumUser: PropTypes.bool,
    authManagerId: PropTypes.string,
    isFullscreen: PropTypes.bool,
    clientConfig: PropTypes.shape({
      PRODUCT_NAME: PropTypes.string.isRequired,
      NODE_ENV: PropTypes.string.isRequired,
      ANALYTICS_EVENT_API_URL: PropTypes.string.isRequired,
      VERSION: PropTypes.string.isRequired,
    }).isRequired,
    isIOS: PropTypes.bool,
    playerTag: PropTypes.string,
    configuration: PropTypes.shape({}),
    initializedDate: PropTypes.instanceOf(Date).isRequired,
  };

  return znipeAnalytics;
};

export default withZnipeAnalytics;
