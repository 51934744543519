import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { useEffect, useRef } from 'react';

type KeyBindingsMap = { [key: string]: () => void };

const useKeyBindings = <T extends HTMLElement>(
  keyBindingsMap: KeyBindingsMap,
  withinFocus = true,
  allowBodyFocus = true,
) => {
  const containerRef = useRef<T>(null);

  useEffect(() => {
    const container = containerRef?.current;
    if (!container) return () => null;
    const keyDowns = fromEvent<KeyboardEvent>(window, 'keydown');
    const keyPresses = keyDowns.pipe(
      map(e => ({ e, modifiedKey: e.shiftKey ? `${e.key}_shift` : e.key })),
      filter(({ modifiedKey }) => Object.keys(keyBindingsMap).includes(modifiedKey)),
      filter(() => {
        const { activeElement: focus } = document;
        if (!withinFocus) return true;
        return (
          container === focus ||
          container.contains(focus) ||
          (allowBodyFocus ? focus?.tagName?.toLowerCase() === 'body' : false)
        );
      }),
    );
    const subscription = keyPresses.subscribe(({ e, modifiedKey }) => {
      e.preventDefault();
      const handlerCallback = keyBindingsMap?.[modifiedKey];
      if (handlerCallback) handlerCallback();
    });

    return () => subscription.unsubscribe();
  }, [keyBindingsMap, withinFocus, allowBodyFocus]);

  return containerRef;
};

export default useKeyBindings;
