import { styled, css } from 'styled-components';
import { device } from 'znipe-styles/breakpoints';
import prefers from 'znipe-styles/prefers';

export const Container = styled.div`
  perspective: 600px;
  max-width: 100%;
`;

export const Block = styled.div`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.containerStroke};
  background: ${({ theme }) => theme.secondaryBackgroundColor};
  background-clip: padding-box;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  position: relative;
  ${({ flipStatus }) =>
    flipStatus &&
    css`
      transform: rotateY(180deg);
    `}
  ${({ flipStatus }) =>
    typeof flipStatus !== 'undefined' &&
    css`
      @media ${prefers.motion} {
        transform-style: preserve-3d;
        transition: all 0.5s ease-out;
      }
    `}
`;

export const ContentWrapper = styled.div`
  padding: ${props => (props.noPadding ? '0' : '15px 15px 20px 15px')};
  @media ${device.desktopLarge} {
    padding: ${props => (props.noPadding ? '0' : '9px 18px')};
  }
  ${({ alignMiddle }) =>
    alignMiddle &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100% - 37px);
      position: relative;
    `}
  ${({ hideOverflow }) =>
    hideOverflow &&
    css`
      overflow: hidden;
    `}
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  gap: 0 9px;
  align-items: center;
  margin: 0;
  padding: ${({ size }) => {
    switch (size) {
      case 'large':
        return '12px 24px';
      case 'medium':
        return '12px 16px';
      default:
        return '8px 16px';
    }
  }};
  border-bottom: 1px solid ${({ theme }) => theme.containerStroke};
`;

export const IconWrapper = styled.div`
  display: flex;
  grid-column-start: 3;
  cursor: pointer;
`;

export const StyledCard = styled.div`
  backface-visibility: hidden;
  width: 100%;
  ${({ position }) =>
    position === 'back' &&
    css`
      transform: rotateY(-180deg);
      position: absolute;
      top: 0;
    `}
  ${({ position }) =>
    position === 'front' &&
    css`
      z-index: 2;
      transform: rotateY(0deg);
    `}
`;
