import styled, { css } from 'styled-components';

const MarkerDefaultCss = css`
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

const MarkerWithNumberCss = css`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallMarkerWithNumberCss = css`
  ${MarkerWithNumberCss}
  width: 16px;
  height: 16px;
  border-radius: 8px;
`;

export const Marker = styled.div`
  background-color: ${({ theme }) => theme.UIAccent};
  color: ${({ theme }) => theme.textPrimary};
  ${({ type }) => {
    if (type === 'medium') return MarkerWithNumberCss;
    if (type === 'small') return SmallMarkerWithNumberCss;
    return MarkerDefaultCss;
  }};
`;
