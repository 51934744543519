import { ReactNode } from 'react';
import { styled } from 'styled-components';
import Typography from 'znipe-elements/general/Typography/Typography';

type ContentSectionProps = {
  header?: string;
  children: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  gap: 8px;
`;

const ContentSection: React.FC<ContentSectionProps> = ({ header, children, ...restProps }) => (
  <Container {...restProps}>
    {header && <Typography type="title-xs">{header}</Typography>}
    <Content>{children}</Content>
  </Container>
);

export default ContentSection;
