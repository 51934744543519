if (global.document) {
  window.mousetrapContexts = {};
}
// Mousetrap is downloaded and defined in the HTML
// If you want to use a global keybind, send undefined or null as ID
export const getContext = id => {
  if (!global.document) return undefined;
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  return window.mousetrapContexts[id] || Mousetrap;
};

export const createNewContext = id => {
  if (!global.document) return undefined;
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  if (typeof id === 'undefined') return { id, context: Mousetrap };
  if (window.mousetrapContexts[id]) return { id, context: window.mousetrapContexts[id] };
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  const context = new Mousetrap();
  window.mousetrapContexts[id] = context;
  return { id, context };
};

export const bindKey = (keys, callback, id) => {
  if (!global.document) return undefined;
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  return (window.mousetrapContexts[id] || Mousetrap).bind(keys, callback);
};

export const deleteContext = id => {
  if (!global.document) return;
  if (!window.mousetrapContexts[id]) return;
  window.mousetrapContexts[id].reset();
  delete window.mousetrapContexts[id];
};

export const deleteAll = (deleteGlobal = false) => {
  if (!global.document) return;
  Object.keys(window.mousetrapContexts).forEach(deleteContext);
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  if (deleteGlobal) Mousetrap.reset();
};

export const resetContext = id => {
  if (!global.document) return;
  if (!window.mousetrapContexts[id]) return;
  window.mousetrapContexts[id].reset();
};

export const resetAll = (resetGlobal = false) => {
  if (!global.document) return;
  Object.keys(window.mousetrapContexts).forEach(resetContext);
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  if (resetGlobal) Mousetrap.reset();
};

export const pauseContext = id => {
  if (!global.document) return;
  if (!window.mousetrapContexts[id])
    // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
    Mousetrap.pause();
  else window.mousetrapContexts[id].pause();
};

export const pauseAll = (pauseGlobal = false) => {
  if (!global.document) return;
  Object.keys(window.mousetrapContexts).forEach(pauseContext);
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  if (pauseGlobal) Mousetrap.pause();
};

export const pauseAllOtherContexts = id => {
  if (!global.document) return;
  pauseAll();
  if (!window.mousetrapContexts[id])
    // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
    Mousetrap.unpause();
  else window.mousetrapContexts[id].unpause();
};

export const unpauseContext = id => {
  if (!global.document) return;
  if (!window.mousetrapContexts[id])
    // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
    Mousetrap.unpause();
  else window.mousetrapContexts[id].unpause();
};

export const unpauseAll = (unpauseGlobal = false) => {
  if (!global.document) return;
  Object.keys(window.mousetrapContexts).forEach(unpauseContext);
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  if (unpauseGlobal) Mousetrap.unpause();
};

export const unpauseAllOtherContexts = id => {
  if (!global.document) return;
  unpauseAll();
  // biome-ignore lint/correctness/noUndeclaredVariables: Global variable
  if (window.mousetrapContexts[id]) Mousetrap.pause();
};
