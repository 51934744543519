import PropTypes from 'prop-types';
import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';

export const Image = styled.img`
  border-radius: ${props => props.shape === 'round' && '100%'};
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.avatarBackgroundColor || colors.black};
  ${props =>
    !props.src &&
    css`
      border: 2px solid #000000;
      box-sizing: border-box;
      box-shadow: inset 0px 0px 10px #000000;
    `}
  svg {
    fill: ${props => props.theme.avatarPlaceholderBackgroundColor};
    width: 100%;
    height: 100%;
  }
`;

const Avatar = ({
  imageUrl,
  size = 'large',
  shape = 'square',
  fallbackIcon,
  alt = 'avatar-image',
}) =>
  imageUrl ? (
    <Image src={imageUrl} alt={alt} shape={shape} size={size} data-testid="avatar-image" />
  ) : (
    <Image as="div" shape={shape} size={size} data-testid="avatar-fallback-icon">
      {fallbackIcon}
    </Image>
  );

Avatar.propTypes = {
  imageUrl: PropTypes.string,
  size: PropTypes.oneOf(['xxsmall', 'xsmall', 'small', 'medium', 'large', 'custom']),
  shape: PropTypes.oneOf(['square', 'round']),
  /**
   alt attribute to set on <img /> element
   */
  alt: PropTypes.string,

  /**
   When no imageUrl is supplied, Avatar can display a fallback icon. The svg can have any styling it wants as long as its set to width="100%" and height="100%".
   */
  fallbackIcon: PropTypes.element,
};

export default Avatar;
