import { useMemo } from 'react';
import { styled, css } from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'znipe-elements/general/Icon/Icon';

const VersusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  ${({ $extendedCss }) => $extendedCss}
`;

const VersusIcon = ({ size = 'small', hideScore = false }) => {
  const extendedCss = useMemo(() => {
    switch (size) {
      case 'xlarge':
        return css`
          height: 48px;
          ${!hideScore && 'margin: 0 12px'};
        `;
      case 'large':
        return css`
          height: 32px;
          ${!hideScore && 'margin: 0 12px'};
        `;
      case 'medium':
        return css`
          height: 24px;
          ${!hideScore && 'margin: 0 12px'};
        `;
      default:
        return css`
          height: 24px;
          ${!hideScore && 'margin: 0 8px'};
        `;
    }
  }, [size, hideScore]);

  const iconVariant = useMemo(() => {
    switch (size) {
      case 'xlarge':
      case 'large':
        return 'flourish';
      default:
        return 'default';
    }
  }, [size]);

  return (
    <VersusIconWrapper data-testid="versus-icon-wrapper" $extendedCss={extendedCss}>
      <Icon type="versus" variant={iconVariant} inline fillColor="white" />
    </VersusIconWrapper>
  );
};

VersusIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  hideScore: PropTypes.bool,
};

export default VersusIcon;
