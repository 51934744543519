import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';
import type { Theme as BaseTheme } from 'znipe-themes/theme.types';

export const themes = {
  proview: {
    backgroundActive: colors.spaceGreen,
  },
  default: {
    backgroundActive: colors.white,
    shadowActive: `0px 0px 4px ${colors.white}`,
  },
};

type Theme = BaseTheme & {
  sectionIndicators: typeof themes.default;
};

type Size = 'small' | 'medium' | 'large' | 'xlarge';

type GetDotSizeProps = {
  $size: Size;
};
const getDotSize = ({ $size }: GetDotSizeProps) => {
  if ($size === 'small') {
    return css`
      width: 6px;
      height: 6px;
    `;
  }
  return css`
    width: 8px;
    height: 8px;
  `;
};

type GetLineSizeProps = {
  $size: Size;
  $layout: 'vertical' | 'horizontal';
};
const getLineSize = ({ $size, $layout }: GetLineSizeProps) => {
  let length = 6;
  const thickness = 1;

  if ($size === 'small') {
    length = 4;
  }

  return $layout === 'vertical'
    ? css`
        width: ${thickness}px;
        height: ${length}px;
      `
    : css`
        width: ${length}px;
        height: ${thickness}px;
      `;
};

type ContainerProps = {
  $layout: 'vertical' | 'horizontal';
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => (props.$layout === 'vertical' ? 'column' : 'row')};
  @media (min-width: 1500px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

type DotProps = {
  $isActive: boolean;
  $size: Size;
};
export const Dot = styled.div<DotProps>`
  ${props => getDotSize(props)}
  border-radius: 100%;
  ${({ $isActive, theme }) =>
    theme.themeName !== 'proview' &&
    !$isActive &&
    css`
      border: 1px solid ${theme.highlight};
    `}
  box-shadow: ${({ $isActive, theme }) =>
    $isActive ? (theme as Theme).sectionIndicators.shadowActive : 'none'};
  background: ${({ $isActive, theme }) =>
    $isActive ? (theme as Theme).sectionIndicators.backgroundActive : theme.containerStroke};
`;

type LineProps = {
  $isLined: boolean;
  $size: Size;
  $layout: 'vertical' | 'horizontal';
};

export const Line = styled.div<LineProps>`
  ${props => getLineSize(props)}
  background: ${({ $isLined, theme }) => ($isLined ? theme.highlight : 'transparent')};
`;
