import { Fragment, memo } from 'react';
import withTheme from 'znipe-themes/hocs/withTheme';
import { Container, Dot, Line, themes } from './SectionIndicators.styles';

type SectionIndicatorsProps = {
  numberOfItems: number;
  activeSection: number;
  layout?: 'horizontal' | 'vertical';
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  progressStyle?: boolean;
  isLined?: boolean;
};

const SectionIndicators: React.FC<SectionIndicatorsProps> = ({
  numberOfItems,
  activeSection,
  layout = 'horizontal',
  progressStyle = false,
  isLined = false,
  size = 'small',
}) => {
  const arrayofNElements = [...Array(Number.isFinite(numberOfItems) ? numberOfItems : 0).keys()];
  return (
    <Container $layout={layout} data-testid="section-indicators">
      {arrayofNElements.map(element => {
        const elementIndex = element + 1;
        return (
          <Fragment key={elementIndex}>
            <Dot
              $size={size}
              $isActive={
                progressStyle ? elementIndex <= activeSection : elementIndex === activeSection
              }
              data-testid={`indicator-dot-${elementIndex}`}
            />
            {elementIndex !== numberOfItems && (
              <Line
                data-testid={`indicator-line-${elementIndex}`}
                $layout={layout}
                $size={size}
                $isLined={isLined}
              />
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default withTheme(memo(SectionIndicators), themes, 'sectionIndicators');
