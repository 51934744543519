import { styled, css } from 'styled-components';
import Slider from 'react-slick';

export const Container = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const NavigationWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: center;
`;

export const Arrow = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryTextColor};
  ${({ dimmed }) =>
    dimmed &&
    css`
      opacity: 0.4;
    `}
  ${({ rotated }) =>
    rotated &&
    css`
      transform: rotate(180deg);
    `}
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.carouselNavigatonDotColor};
  margin: 10px;
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.carouselNavigatonDotColor};
    `}
`;

export const StyledSlider = styled(Slider)`
  position: relative;
  display: flex;
  align-items: center;
  height: calc(100% - 26px);
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    height: 100%;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    min-width: 1px;
    float: left;
    height: ${({ extraTopPush }) => (extraTopPush ? 'calc(100% + 26px);' : '100%')};
    display: flex;
    place-items: center;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 1px;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`;
