import { useEffect, useMemo, memo, useRef, useState, useCallback, useReducer } from 'react';
import { ThemeProvider } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import GlobalStyle from 'znipe-styles/global.styles';
import Header from 'tv-elements/layout/Header/Header';
import useQueryObject from 'tv-hooks/useQueryObject';
import { useGetSelectedMatchGameIndex } from 'tv-selectors/match/makeGetSelectedMatchGameIndex';
import { useGetGameId } from 'tv-selectors/games/makeGetGameId';
import useFetchVideoCredentials from 'tv-hooks/useFetchVideoCredentials';
import usePopoutMessenger from 'tv-hooks/usePopoutMessenger';
import useSetupMatchInfo from 'tv-hooks/useSetupMatchInfo';
import useTheme from 'tv-hooks/useTheme';
import { useGamePauseSubscription } from 'tv-epics/gamePausesEpic';
import { useGameTimeInfoEpic } from 'tv-epics/gameTimeInfoEpic';
import { useStatsUpdateEpic } from 'tv-epics/statsUpdateEpic';
import Typography from 'znipe-elements/general/Typography/Typography';
import StageMatchBar from 'tv-modules/StageMatchBar/StageMatchBar';
import Slide from 'znipe-elements/layout/Animation/Slide/Slide';
import StickyComponent from 'znipe-elements/layout/StickyComponent/StickyComponent';
import { useGetIsDesktopOrGreater } from 'tv-selectors/browser/makeGetIsDesktopOrGreater';
import { useGetIsMobileLandscapeOrGreater } from 'tv-selectors/browser/makeGetIsMobileLandscapeOrGreater';
import { useGetIsTabletOrGreater } from 'tv-selectors/browser/makeGetIsTabletOrGreater';
import { useGetIsDesktopLargeOrGreater } from 'tv-selectors/browser/makeGetIsDesktopLargeOrGreater';
import VideoFrame from 'tv-modules/Stage/VideoFrame/VideoFrame';
// import SocialWatching from 'tv-modules/SocialWatching/SocialWatching';
import usePlayerScaling, {
  RIGHT_SIDEBAR_SPRING,
  LEFT_SIDEBAR_SPRING,
  PLAYER_SPRING,
  STAGE_SPRING,
} from 'tv-routes/Stage/usePlayerScaling';
import StatsMenu, {
  BOOKCOVER_ID as STATS_BOOKCOVER_ID,
} from 'tv-modules/Stats/StatsMenu/StatsMenu';
import Predictions, {
  BOOKCOVER_ID as PREDICTIONS_BOOKCOVER_ID,
} from 'tv-modules/Predictions/Predictions';
import { BOOKCOVER_ID as HIGHLIGHTS_BOOKCOVER_ID } from 'tv-modules/GAT/HighlightList/HighlightList';
import MatchGameSelector from 'tv-modules/MatchGameSelector/MatchGameSelector';
import MobileStreamList from 'tv-modules/MobileStreamList/MobileStreamList';
import { PLAYER_SELECTOR, STATS_MAP, TEAM_STATS } from 'tv-modules/Stats/StatsMenu/constants';
import queryStringToObject from 'znipe-utils/location/queryStringToObject';
import objectToQueryString from 'znipe-utils/location/objectToQueryString';
import { useGetMatchGameTitle } from 'tv-selectors/match/makeGetMatchGameTitle';
import MobileNavbar from 'tv-modules/Stage/MobileNavbar/MobileNavbar';
import PingFrame from 'tv-modules/Stage/PingFrame/PingFrame';
import useReduxGqlQuery from 'tv-hooks/useReduxGqlQuery';
import { useDebug } from 'tv-selectors/ui/makeGetDebug';
import StatsDebugMenu from 'tv-modules/Stats/StatsDebugMenu/StatsDebugMenu';
import SubscribeToPremiumModal from 'tv-modules/Premium/SubscribeToPremiumModal/SubscribeToPremiumModal';
import { encodeId } from 'znipe-gql/utils/misc';
import useHasPremiumAccess from 'tv-hooks/useHasPremiumAccess';
import PremiumContentContext from 'tv-contexts/PremiumContentContext';
import usePackageName from 'tv-hooks/usePackageName';
import PageWelcome from 'tv-modules/Onboarding/PageWelcome/PageWelcome';
import useSetPlayedStatus from 'tv-modules/Onboarding/hooks/useSetPlayedStatus';
import useResetGatStats from 'tv-routes/Stage/useResetGatStats';
import useGameRedirect from 'tv-hooks/useGameRedirect';
import useForceRefresh from 'tv-hooks/useForceRefresh';
import usePrevious from 'znipe-hooks/usePrevious';

import { useShouldShowOnboardingStep } from 'tv-selectors/auth/makeGetShouldShowOnboardingStep';
import { useGetGameHasStats } from 'tv-selectors/games/makeGetGameHasStats';
import reducer from './Stage.reducer';
import ChatSideMenu from './ChatSideMenu';
import StagePageQuery from './getStagePageData.graphql';
import {
  HIGHLIGHTLIST_MAP,
  POV_SELECTOR_STREAM_ID,
  SIDEBAR_STATS_VISIBLITIY,
  SIDEBAR_HIGHLIGHTS_VISIBLITIY,
  // SIDEBAR_ROOMS_VISIBLITIY,
  SIDEBAR_PREDICTIONS_VISIBLITIY,
  SIDEBAR_GAMES_VISIBILITY,
  SIDEBAR_STREAMS_VISIBILITY,
  SIDEBAR_CHAT_VISIBILITY,
  STAGE_WELCOME_STEP_ID,
  initialState,
  hints,
  laptopHints,
  mobileHints,
  pageWelcomeSubHeading,
} from './Stage.constants';

import HelmetWrapper from './HelmetWrapper';
import {
  BodyContainer,
  DesktopSidebarContainer,
  DesktopSidebarHighlightContainer,
  MobileStatsContainer,
  Container,
  MobileMenuContainer,
  PlaceholderMenu,
  MenuContent,
  InnerModalWrapper,
  VideoFrameWrapper,
  PlayerSelectorWrapper,
  TitleWrapper,
  MobileHintContainer,
  MobileStatsLoading,
} from './Stage.styles';

const Stage = () => {
  const [stageState, dispatchStageState] = useReducer(reducer, initialState);
  const [statsPlayerInfo, setStatsPlayerInfo] = useState({});
  const [mobileStatsView, setMobileStatsView] = useState(TEAM_STATS);
  const [rendered, setRendered] = useState(false);
  const showStageWelcome = useShouldShowOnboardingStep({ stepId: STAGE_WELCOME_STEP_ID });
  const setStatsPlayedStatus = useSetPlayedStatus(STATS_BOOKCOVER_ID);
  const setHighlightsPlayedStatus = useSetPlayedStatus(HIGHLIGHTS_BOOKCOVER_ID);
  const setPredictionsPlayedStatus = useSetPlayedStatus(PREDICTIONS_BOOKCOVER_ID);

  const packageName = usePackageName();
  const { m, socialChannel } = useQueryObject();
  const playerRef = useRef(null);
  // match info
  const matchGameIndex = useGetSelectedMatchGameIndex({ matchId: m });
  const {
    matchGameIndex: selectedMatchGameIndex,
    matchId,
    startTime,
    seekRange,
  } = useGameRedirect(playerRef, m, matchGameIndex);

  const matchGameId = useGetGameId({ matchId, selectedMatchGameIndex: matchGameIndex });
  const gameTitle = useGetMatchGameTitle({ matchId });
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const base64MatchId = useMemo(() => encodeId(matchId, 'Match'), [matchId]);
  const [loadingGameInfo, setLoadingGameInfo] = useState(!matchGameId || !gameTitle);

  const HighlightList = useMemo(
    () => HIGHLIGHTLIST_MAP[gameTitle] ?? HIGHLIGHTLIST_MAP.default,
    [gameTitle],
  );

  const queryOptions = useMemo(
    () => ({
      ssr: true,
      cacheTime: 30,
      variables: {
        package: packageName,
        base64MatchId,
      },
    }),
    [base64MatchId, packageName],
  );

  const executableQuery = useMemo(
    () => (matchId && packageName ? StagePageQuery : null),
    [matchId, packageName],
  );

  const { refetch } = useReduxGqlQuery(executableQuery, queryOptions);

  useForceRefresh(matchId, refetch);

  const prevMatchId = usePrevious(matchId);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    // Refetch match info if game changed within the same match
    if (!prevMatchId || prevMatchId !== matchId) return;
    refetch();
  }, [selectedMatchGameIndex]);

  const [popoutWindowRefs, setPopoutWindowRefs] = useState([]);

  const handleWindowRefUpdate = useCallback(
    (ref, windowId) => {
      const newRef = {
        [windowId]: ref,
      };

      setPopoutWindowRefs({ ...popoutWindowRefs, ...newRef });
    },
    [popoutWindowRefs],
  );

  const streamTokenRequest = useMemo(() => {
    if (!matchId || !matchGameId) return {};
    return {
      matches: [
        {
          id: matchId,
          gameId: matchGameId,
        },
      ],
    };
  }, [matchId, matchGameId]);

  const hasStats = useGetGameHasStats({ matchId, selectedMatchGameIndex });

  useFetchVideoCredentials(streamTokenRequest, true);
  useResetGatStats(matchId, selectedMatchGameIndex);
  useSetupMatchInfo(matchId, selectedMatchGameIndex);
  usePopoutMessenger(matchId, selectedMatchGameIndex, playerRef, popoutWindowRefs);
  useGamePauseSubscription(matchId);
  useGameTimeInfoEpic(playerRef, hasStats ? matchId : null, selectedMatchGameIndex);
  useStatsUpdateEpic(matchId);

  const onGameSelectionClick = useCallback(
    (newGameIndex, mid, timestamp = 0) => {
      const selectedMatchId = mid ?? matchId;

      const path = global.document ? location.pathname : '';
      const search = global.document ? location.search : '';

      const query = queryStringToObject(location.search);
      const hasGame = 'g' in query;

      query.m = selectedMatchId;
      query.g = newGameIndex;
      query.t = timestamp;
      if (socialChannel) query.socialChannel = socialChannel;

      const newSearch = objectToQueryString(query);
      const newPath = `${path}${newSearch}`;

      const noChange =
        newPath === `${path}${search}` || (hasGame && query.g === newGameIndex.toString());

      if (hasGame && !noChange) navigate(newPath);
      else navigate(newPath, { replace: true });
    },
    [location.search, location.pathname, navigate, matchId, socialChannel],
  );

  const isDesktopOrGreater = useGetIsDesktopOrGreater();
  const isDesktopLargeOrGreater = useGetIsDesktopLargeOrGreater();
  const isMobileLandscapeOrGreater = useGetIsMobileLandscapeOrGreater();
  const isTabletOrGreater = useGetIsTabletOrGreater();
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const handleDesktopPovSelectorClick = useCallback(selectedStreamId => {
    dispatchStageState({
      type: POV_SELECTOR_STREAM_ID,
      selectedStreamId,
    });
  }, []);

  const closeSelector = useCallback(() => {
    dispatchStageState({
      type: POV_SELECTOR_STREAM_ID,
      selectedStreamId: undefined,
    });
  }, []);

  const toggleStatsSidebarVisibility = useCallback(() => {
    if (stageState.statsSidebarVisible) setStatsPlayedStatus(true);
    dispatchStageState({
      type: SIDEBAR_STATS_VISIBLITIY,
      isVisible: !stageState.statsSidebarVisible,
      largeScreen: isDesktopLargeOrGreater,
    });
  }, [stageState.statsSidebarVisible, setStatsPlayedStatus, isDesktopLargeOrGreater]);

  const togglePredictionsSidebarVisibility = useCallback(() => {
    if (stageState.predictionsSidebarVisible) setPredictionsPlayedStatus(true);
    dispatchStageState({
      type: SIDEBAR_PREDICTIONS_VISIBLITIY,
      isVisible: !stageState.predictionsSidebarVisible,
      largeScreen: isDesktopLargeOrGreater,
    });
  }, [stageState.predictionsSidebarVisible, setPredictionsPlayedStatus, isDesktopLargeOrGreater]);

  const toggleHighlightSidebarVisibility = useCallback(() => {
    if (stageState.highlightsSidebarVisible) setHighlightsPlayedStatus(true);
    dispatchStageState({
      type: SIDEBAR_HIGHLIGHTS_VISIBLITIY,
      isVisible: !stageState.highlightsSidebarVisible,
      largeScreen: isDesktopLargeOrGreater,
    });
  }, [isDesktopLargeOrGreater, setHighlightsPlayedStatus, stageState.highlightsSidebarVisible]);

  /*  const toggleRoomSidebarVisibility = useCallback(() => {
    dispatchStageState({
      type: SIDEBAR_ROOMS_VISIBLITIY,
      isVisible: !stageState.roomsSidebarVisible,
      largeScreen: isDesktopLargeOrGreater,
    });
  }, [dispatchStageState, stageState.roomsSidebarVisible, isDesktopLargeOrGreater]); */

  const toggleGamesSidebarVisibility = useCallback(() => {
    dispatchStageState({
      type: SIDEBAR_GAMES_VISIBILITY,
      isVisible: !stageState.gamesSidebarVisibility,
    });
  }, [stageState.gamesSidebarVisibility]);

  const toggleShowPremiumModal = useCallback(
    () => setShowPremiumModal(!showPremiumModal),
    [showPremiumModal],
  );

  const toggleStreamsSidebarVisibility = useCallback(() => {
    if (showPremiumModal) toggleShowPremiumModal();
    dispatchStageState({
      type: SIDEBAR_STREAMS_VISIBILITY,
      isVisible: !stageState.streamsSidebarVisibility,
    });
  }, [showPremiumModal, toggleShowPremiumModal, stageState.streamsSidebarVisibility]);

  const toggleChatSidebarVisibility = useCallback(() => {
    dispatchStageState({
      type: SIDEBAR_CHAT_VISIBILITY,
      isVisible: !stageState.chatSidebarVisible,
      largeScreen: isDesktopLargeOrGreater,
    });
  }, [isDesktopLargeOrGreater, stageState.chatSidebarVisible]);

  const onMobileMenuItemSelect = useCallback(
    item => {
      if (item === 'highlights') toggleHighlightSidebarVisibility();
      // else if (item === 'rooms') toggleRoomSidebarVisibility();
      else if (item === 'streams') toggleStreamsSidebarVisibility();
      else if (item === 'games') toggleGamesSidebarVisibility();
      else if (item === 'chat') toggleChatSidebarVisibility();
    },
    [
      toggleHighlightSidebarVisibility,
      toggleGamesSidebarVisibility,
      toggleStreamsSidebarVisibility,
      toggleChatSidebarVisibility,
    ],
  );

  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    setLoadingGameInfo(!matchGameId || !gameTitle);
  }, [matchGameId, gameTitle]);

  useEffect(() => {
    if (!matchId || selectedMatchGameIndex < 0) return;
    const query = queryStringToObject(location.search);
    if (query.g) return;

    query.g = selectedMatchGameIndex;
    const search = objectToQueryString(query);

    navigate(`${location.pathname}${search}`, { replace: true });
  }, [selectedMatchGameIndex, matchId, location.search, location.pathname, navigate]);

  const {
    streamSelectorState,
    statsSidebarVisible,
    predictionsSidebarVisible,
    highlightsSidebarVisible,
    roomsSidebarVisible,
    gamesSidebarVisibility,
    streamsSidebarVisibility,
    chatSidebarVisible,
  } = stageState;
  const streamSelectorStreamId = streamSelectorState.selectedStreamId;
  const leftSidebarActive = statsSidebarVisible || predictionsSidebarVisible;
  const rightSidebarActive = highlightsSidebarVisible || roomsSidebarVisible || chatSidebarVisible;

  const PlayerSelector = useMemo(() => {
    if (isDesktopOrGreater) return undefined;
    return STATS_MAP[gameTitle]?.[PLAYER_SELECTOR];
  }, [gameTitle, isDesktopOrGreater]);

  const onStatsPlayerSelect = useCallback(
    (playerId, gatPlayerPosition) => {
      setStatsPlayerInfo({ playerId, gatPlayerPosition });
      toggleStatsSidebarVisibility();
    },
    [toggleStatsSidebarVisibility],
  );

  const onMobileStatsViewChange = useCallback(view => {
    if (view === TEAM_STATS) setStatsPlayerInfo({});
    setMobileStatsView(view);
  }, []);

  const mobileMenuOpen =
    highlightsSidebarVisible ||
    roomsSidebarVisible ||
    gamesSidebarVisibility ||
    streamsSidebarVisibility;

  const debug = useDebug();

  const { spring, createSpringRef } = usePlayerScaling(rightSidebarActive, leftSidebarActive);

  const userHasPremiumAccess = useHasPremiumAccess();
  const premiumContextValue = useMemo(
    () => ({
      userHasPremiumAccess,
      onUnAvailableAction: isDesktopOrGreater ? null : () => setShowPremiumModal(true),
    }),
    [userHasPremiumAccess, isDesktopOrGreater],
  );

  return (
    <ThemeProvider theme={theme}>
      <HelmetWrapper matchId={matchId} packageName={packageName} />
      <PremiumContentContext.Provider value={premiumContextValue}>
        <GlobalStyle />
        <Container>
          {debug && (
            <StatsDebugMenu matchId={matchId} selectedMatchGameIndex={selectedMatchGameIndex} />
          )}
          <BodyContainer>
            {isDesktopOrGreater && (
              <Slide
                show={statsSidebarVisible}
                type="left"
                width="auto"
                ref={createSpringRef(LEFT_SIDEBAR_SPRING)}
              >
                <DesktopSidebarContainer data-testid="stage-left-sidebar">
                  <StatsMenu
                    type="compact"
                    withHeader
                    withPlayerSelector
                    matchId={matchId}
                    selectedMatchGameIndex={selectedMatchGameIndex}
                    onClose={toggleStatsSidebarVisibility}
                    onPovViewStreamClick={handleDesktopPovSelectorClick}
                  />
                </DesktopSidebarContainer>
              </Slide>
            )}
            {isDesktopOrGreater && (
              <Slide
                show={predictionsSidebarVisible}
                type="left"
                width="auto"
                ref={createSpringRef(LEFT_SIDEBAR_SPRING)}
              >
                <DesktopSidebarContainer data-testid="stage-predictions-left-sidebar">
                  <Predictions
                    onClose={togglePredictionsSidebarVisibility}
                    matchId={matchId}
                    selectedMatchGameIndex={selectedMatchGameIndex}
                  />
                </DesktopSidebarContainer>
              </Slide>
            )}
            <StickyComponent
              componentId="stage-player"
              enableStickyScroll={!isDesktopOrGreater}
              height="100%"
            >
              <VideoFrameWrapper ref={createSpringRef(STAGE_SPRING)}>
                {showStageWelcome && (
                  <PageWelcome
                    hints={isDesktopOrGreater ? hints : undefined}
                    headline="A quick overview"
                    subHeadline={
                      pageWelcomeSubHeading[packageName] ?? pageWelcomeSubHeading.default
                    }
                    buttonLabel={isTabletOrGreater ? 'Enter match' : ''}
                    stepId={STAGE_WELCOME_STEP_ID}
                  />
                )}
                <VideoFrame
                  ref={playerRef}
                  matchId={matchId}
                  selectedMatchGameIndex={selectedMatchGameIndex}
                  streamSelectorStreamId={streamSelectorStreamId}
                  closeSelector={closeSelector}
                  handleWindowRefUpdate={handleWindowRefUpdate}
                  startTime={startTime}
                  springProps={spring}
                  initialSeekRange={seekRange}
                  statsDisabled={!hasStats}
                  setPlayerWidth={createSpringRef(PLAYER_SPRING)}
                  sidebarsOpen={(leftSidebarActive ? 1 : 0) + (rightSidebarActive ? 1 : 0)}
                />
              </VideoFrameWrapper>
            </StickyComponent>
            {isDesktopOrGreater && (
              <>
                <Slide
                  show={highlightsSidebarVisible}
                  type="right"
                  width="auto"
                  ref={createSpringRef(RIGHT_SIDEBAR_SPRING)}
                >
                  <DesktopSidebarHighlightContainer data-testid="stage-right-sidebar">
                    <HighlightList
                      onClose={toggleHighlightSidebarVisibility}
                      compact={!isDesktopLargeOrGreater}
                      matchId={matchId}
                      selectedMatchGameIndex={selectedMatchGameIndex}
                      sidebar
                    />
                  </DesktopSidebarHighlightContainer>
                </Slide>
                <ChatSideMenu
                  ref={createSpringRef(RIGHT_SIDEBAR_SPRING)}
                  visible={chatSidebarVisible}
                  onClose={toggleChatSidebarVisibility}
                  chatId={matchGameId}
                  matchId={matchId}
                />
                {/* <Slide
                  show={rightSidebarActive && roomsSidebarVisible}
                  type="right"
                  width="auto"
                  ref={createSpringRef(RIGHT_SIDEBAR_SPRING)}
                >
                  <DesktopSidebarContainer data-testid="stage-right-sidebar">
                    <SocialWatching collapsable onClose={toggleRoomSidebarVisibility} />
                  </DesktopSidebarContainer>
                </Slide> */}
              </>
            )}
          </BodyContainer>

          {(isDesktopOrGreater || mobileStatsView === TEAM_STATS) && (
            <StageMatchBar
              matchId={matchId}
              selectedMatchGameIndex={selectedMatchGameIndex}
              playerRef={playerRef}
              onStatsButtonClick={StatsMenu && hasStats && toggleStatsSidebarVisibility}
              onPredictionsButtonClick={hasStats && togglePredictionsSidebarVisibility}
              onHighlightClick={hasStats && toggleHighlightSidebarVisibility}
              onChatButtonClick={toggleChatSidebarVisibility}
              onGameSelect={onGameSelectionClick}
              onStreamItemClick={handleDesktopPovSelectorClick}
              onStreamPopout={handleWindowRefUpdate}
              // onRoomClick={toggleRoomSidebarVisibility}
              statsButtonActive={statsSidebarVisible}
              predictionsButtonActive={predictionsSidebarVisible}
              highlightsButtonActive={highlightsSidebarVisible}
              chatButtonActive={chatSidebarVisible}
              onMobileStreamItemClick={toggleStreamsSidebarVisibility}
              loading={loadingGameInfo}
            />
          )}
        </Container>

        {!isDesktopOrGreater && (
          <>
            {mobileStatsView === TEAM_STATS && !statsSidebarVisible && (
              <MobileNavbar
                matchId={matchId}
                onStatsButtonClick={toggleStatsSidebarVisibility}
                onSelectContent={onMobileMenuItemSelect}
                isLoading={loadingGameInfo}
              />
            )}
            {!mobileMenuOpen &&
              !chatSidebarVisible &&
              (loadingGameInfo ? (
                <MobileStatsLoading />
              ) : (
                <MobileStatsContainer
                  data-testid="mobile-stats-container"
                  $viewHeight={statsPlayerInfo.playerId}
                >
                  {statsSidebarVisible && PlayerSelector ? (
                    <PlayerSelectorWrapper>
                      <TitleWrapper>
                        <Typography type={isTabletOrGreater ? 'title-s' : 'title-xs'}>
                          player stats
                        </Typography>
                      </TitleWrapper>
                      <PlayerSelector
                        onItemClick={onStatsPlayerSelect}
                        matchId={matchId}
                        selectedMatchGameIndex={selectedMatchGameIndex}
                        size="small"
                        type={isTabletOrGreater ? 'vertical' : 'horizontal'}
                        hideChampionInfo
                        withRoleIcons={gameTitle === 'lol'}
                      />
                    </PlayerSelectorWrapper>
                  ) : (
                    <StatsMenu
                      onViewChange={onMobileStatsViewChange}
                      type={isMobileLandscapeOrGreater ? 'default' : 'compact'}
                      matchId={matchId}
                      selectedMatchGameIndex={selectedMatchGameIndex}
                      playerId={statsPlayerInfo.playerId}
                      gatPlayerPosition={statsPlayerInfo.gatPlayerPosition}
                    />
                  )}
                </MobileStatsContainer>
              ))}
            {showStageWelcome && rendered && (
              <MobileHintContainer>
                <PageWelcome
                  hints={isTabletOrGreater ? laptopHints : mobileHints}
                  buttonLabel={isTabletOrGreater ? '' : 'Enter match'}
                  stepId={STAGE_WELCOME_STEP_ID}
                />
              </MobileHintContainer>
            )}
            <Slide show={mobileMenuOpen} type="bottom" height="auto" position="fixed">
              <MobileMenuContainer>
                {highlightsSidebarVisible && (
                  <HighlightList
                    onClose={toggleHighlightSidebarVisibility}
                    compact={!isTabletOrGreater}
                    matchId={matchId}
                    selectedMatchGameIndex={selectedMatchGameIndex}
                  />
                )}
                {/* {roomsSidebarVisible && <SocialWatching onClose={toggleRoomSidebarVisibility} />} */}
                {gamesSidebarVisibility && (
                  <PlaceholderMenu>
                    <Header onClose={toggleGamesSidebarVisibility} heading="Games" />
                    <MatchGameSelector
                      matchId={matchId}
                      selectedMatchGameIndex={selectedMatchGameIndex}
                      setSelectedMatchGame={onGameSelectionClick}
                    />
                  </PlaceholderMenu>
                )}
                {streamsSidebarVisibility && (
                  <PlaceholderMenu>
                    <Header onClose={toggleStreamsSidebarVisibility} heading="Streams" />
                    <MenuContent>
                      <MobileStreamList
                        matchId={matchId}
                        selectedMatchGameIndex={selectedMatchGameIndex}
                        afterStreamSelect={toggleStreamsSidebarVisibility}
                      />
                      {showPremiumModal && (
                        <InnerModalWrapper>
                          <SubscribeToPremiumModal onClickBack={toggleShowPremiumModal} />
                        </InnerModalWrapper>
                      )}
                    </MenuContent>
                  </PlaceholderMenu>
                )}
              </MobileMenuContainer>
            </Slide>
            <ChatSideMenu
              visible={chatSidebarVisible}
              onClose={toggleChatSidebarVisibility}
              chatId={matchGameId}
              matchId={matchId}
              type="bottom"
            />
          </>
        )}
        <PingFrame />
      </PremiumContentContext.Provider>
    </ThemeProvider>
  );
};

export default memo(Stage);
