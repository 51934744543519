import { createContext } from 'react';
import GqlCache from './GqlCacheClient';
import GqlClient from './GqlClient';

const gqlCacheClient = new GqlCache();
const gqlClient = new GqlClient();

interface GqlClientContextType {
  cache: GqlCache;
  client: GqlClient;
}

export const GqlClientContext = createContext<GqlClientContextType>({
  cache: gqlCacheClient,
  client: gqlClient,
});
