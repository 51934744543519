import type { QueryObject } from './queryStringToObject';

const objectToQueryString = (params: QueryObject) => {
  if (typeof params !== 'object') return '';
  const paramsArr = Object.keys(params);
  if (paramsArr.length === 0) return '';
  return `?${Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')}`;
};

export default objectToQueryString;
