import { styled, css } from 'styled-components';
import colors from 'znipe-styles/colors';
import convertHexToHSL from 'znipe-utils/misc/convertHexToHSL';
import { Bar } from './ActionsBar/ActionsBar.styles';

export const baseStyles = css<{ $clickable?: boolean }>`
  display: flex;
  gap: 10px;
  width: inherit;
  color: ${colors.grey75};
  word-break: break-word;
  border-radius: 2px;
  padding: 2px 4px;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
`;

const errorLightHLS = { l: 72 };
const bgErrorLightHLSA = { ...errorLightHLS, a: 0.15 };
const hoverErrorLightHLSA = { ...errorLightHLS, a: 0.2 };

export const reportedHighLight = css<{ $reported?: boolean }>`
  ${({ $reported, theme }) =>
    $reported &&
    css`
      background: ${convertHexToHSL(theme.errorLight, bgErrorLightHLSA)};
      border-left: 2px solid ${convertHexToHSL(theme.errorLight, errorLightHLS)};
      box-sizing: border-box;
      &:hover,
      &:focus {
        background: ${convertHexToHSL(theme.errorLight, hoverErrorLightHLSA)};
      }
    `};
`;

export const Entry = styled.div<{
  $replyHighlight?: boolean;
  $reported?: boolean;
  $clickable?: boolean;
}>`
  ${baseStyles};
  position: relative;
  ${Bar} {
    opacity: 0;
  }

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.05);
    ${Bar} {
      opacity: 1;
    }
  }

  ${({ $replyHighlight }) =>
    $replyHighlight &&
    css`
      background: rgba(255, 255, 255, 0.1);
      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.15);
      }
    `};

  ${reportedHighLight}
`;
