import { useMemo, memo, forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShakaPlayer, {
  ShakaPlayerWithAnalytics,
} from 'znipe-player/src/components/ShakaPlayer/ShakaPlayer';
import Debug from 'znipe-player/src/components/Debug/Debug';
import useVideosWithRef from 'znipe-player/src/hooks/useVideosWithRef';
import useRefWithPlayerFunctionsAndEvents from 'znipe-player/src/hooks/useRefWithPlayerFunctionsAndEvents';
import generateUniqueId from 'znipe-utils/misc/generateUniqueId';
import OverlayComponent from 'znipe-elements/layout/Overlay/Overlay';

export const VideoContent = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

const SingleView = forwardRef(
  (
    {
      src,
      onReady,
      muted,
      controls,
      startTime,
      poster,
      debug,
      autoPlay,
      offset,
      analyticsParams,
      liveDelay,
      configuration,
      streamId,
      isFullscreen,
      overlay,
    },
    ref,
  ) => {
    const [debugInfo, setDebugInfo] = useState();
    const Player = analyticsParams ? ShakaPlayerWithAnalytics : ShakaPlayer;
    const generatedId = useMemo(() => generateUniqueId(), []);
    const videosWithRef = useVideosWithRef([{ id: generatedId, src, offset }]);
    useRefWithPlayerFunctionsAndEvents(videosWithRef, ref);
    const videoPlayerId = useMemo(
      () => analyticsParams?.videoId ?? generateUniqueId(),
      [analyticsParams?.videoId],
    );
    const initializedDate = useMemo(() => new Date(), []);
    useEffect(() => {
      if (onReady) onReady();
    }, [onReady]);

    const { ref: videoPlayerRef } = videosWithRef[0];

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
      const player = ref?.current;
      if (!debug || !player) return () => null;
      const handleTimeUpdate = e => {
        const shaka = videosWithRef[0].ref.current;
        const video = shaka.getMediaElement();
        const { currentTime } = e;
        const info = {
          id: videosWithRef[0].id,
          offset,
          currentTime,
          playbackRate: video.playbackRate,
          muted: video.muted,
          master: true,
          volume: video.volume,
          stats: shaka.getStats(),
          buffering: shaka.isBuffering(),
          paused: video.paused,
        };
        setDebugInfo(info);
      };
      player.addEventListener('timeupdate', handleTimeUpdate);
      return () => player.removeEventListener('timeupdate', handleTimeUpdate);
      // Don't dare to change this as it might break the old player.
      // Leaving this as we should depricate this file soon
    }, [debug, videosWithRef[0].id, offset]);

    return (
      <VideoContent>
        <OverlayComponent overlay={overlay} topMost={!debug}>
          {debug && <Debug info={debugInfo} />}
          <Player
            {...(analyticsParams ?? {})}
            ref={videoPlayerRef}
            src={src}
            poster={poster}
            startTime={startTime}
            autoPlay={autoPlay}
            muted={muted}
            controls={controls}
            videoPlayerId={videoPlayerId}
            streamId={streamId}
            liveDelay={liveDelay}
            configuration={configuration}
            isFullscreen={isFullscreen}
            master
            initializedDate={initializedDate}
          />
        </OverlayComponent>
      </VideoContent>
    );
  },
);

SingleView.propTypes = {
  src: PropTypes.string.isRequired,
  onReady: PropTypes.func, // Used to tell parent when ref is ready for event listeners
  muted: PropTypes.bool,
  controls: PropTypes.bool,
  startTime: PropTypes.number,
  poster: PropTypes.string,
  debug: PropTypes.bool,
  autoPlay: PropTypes.bool,
  offset: PropTypes.number,
  liveDelay: PropTypes.number,
  isFullscreen: PropTypes.bool,
  streamId: PropTypes.string,
  analyticsParams: PropTypes.shape({
    authManagerId: PropTypes.string,
    matchId: PropTypes.string,
    userSession: PropTypes.string,
    userId: PropTypes.string,
    region: PropTypes.string,
    isIOS: PropTypes.bool,
    videoId: PropTypes.string,
    videoOrigin: PropTypes.string,
    clientConfig: PropTypes.shape({
      PRODUCT_NAME: PropTypes.string.isRequired,
      NODE_ENV: PropTypes.string.isRequired,
      ANALYTICS_EVENT_API_URL: PropTypes.string.isRequired,
      VERSION: PropTypes.string.isRequired,
    }),
  }),
  configuration: PropTypes.shape({}),
  overlay: PropTypes.node,
};

export default memo(SingleView);
