import { schema } from 'normalizr';
import urlifyString from 'znipe-utils/web/urlifyString';
import getLastItem from 'znipe-utils/misc/getLastItem';

export const cleanNodes = ({ node }) => ({ ...node });

export const removeUndefined = values =>
  Object.entries(values).reduce((acc, [key, val]) => {
    if (val) acc[key] = val;
    return acc;
  }, {});

export const championSchema = new schema.Entity(
  'champion',
  {},
  {
    idAttribute: value => value.champId || value.id,
    processStrategy: ({ otherOfSameClass, videos, ...value }) => {
      const actors = [];
      videos?.edges?.forEach(({ node }) => {
        node.actors?.forEach(({ id, player }) => {
          const { team, ...rest } = player;
          if (!actors.find(actor => actor.id === id)) {
            actors.push({
              id,
              nickname: getLastItem(rest.nickname),
              image: getLastItem(rest.image),
              teamId: team.id,
              teamName: getLastItem(team.name),
              teamLogoLight: getLastItem(team.logoDark),
              teamLogoDark: getLastItem(team.logoLight),
            });
          }
        });
      });
      const flattenedClasses =
        otherOfSameClass?.edges?.map(({ node }) =>
          Object.keys(node).reduce((output = {}, key) => {
            const copy = { ...output };
            if (key === 'class') {
              copy[key] = node[key]?.[0];
            } else if (Array.isArray(node[key])) {
              copy[key] = getLastItem(node[key]);
            } else {
              copy[key] = node[key];
            }
            return copy;
          }, {}),
        ) || [];
      return {
        ...value,
        otherOfSameClass: flattenedClasses,
        topPlayers: [...new Set(actors)],
      };
    },
  },
);

export const eventSchema = new schema.Entity('event');

export const tournamentEventSchema = new schema.Entity(
  'events',
  {},
  {
    processStrategy: (value, parent) => {
      const eventCopy = { ...value };
      eventCopy.country = parent?.location?.country ?? '';
      return eventCopy;
    },
  },
);

export const allTournamentsSchema = new schema.Entity('allTournaments', {
  event: tournamentEventSchema,
});

export const tournamentStageSchema = new schema.Entity(
  'tournamentStages',
  {},
  {
    idAttribute: ({ name }, { id }) => `${id}:${urlifyString(name)}`,
    processStrategy: values => {
      const newValues = {
        ...values,
        schedules: values.schedules?.map(schedule => ({
          ...schedule,
          scheduleResults: schedule.scheduleResults?.map(result => ({
            ...result,
            team: result.team?.id,
          })),
        })),
      };
      return removeUndefined(newValues);
    },
  },
);

export const teamDetailsSchema = new schema.Entity('teamDetails');

export const teamSubSchema = new schema.Entity('team', {
  teamDetails: teamDetailsSchema,
});

export const playerSchema = new schema.Entity(
  'players',
  {
    team: teamSubSchema,
  },
  {
    processStrategy: (value, parent) => {
      const { playerDetails, ...playerCopy } = value;
      if (playerDetails?.image) {
        playerCopy.image = playerDetails.image;
      }
      if (playerDetails?.nickname) {
        playerCopy.nickname = getLastItem(playerDetails.nickname);
      }
      if (parent?.logoSrc) {
        playerCopy.teamLogoLight = parent?.logoSrc;
      }
      if (value?.team?.logoLight) {
        playerCopy.teamlogoLight = getLastItem(value?.team?.logoLight);
      }
      if (playerDetails?.team?.id) {
        playerCopy.teamId = playerDetails.team.id;
      } else if (value?.team?.id) {
        playerCopy.teamId = value.team.id;
      } else {
        playerCopy.teamId = parent?.id ?? '';
      }
      return playerCopy;
    },
  },
);

export const stream = new schema.Entity(
  'globalStreams',
  {},
  {
    processStrategy: (value, { gameNumber, matchId }) => ({ ...value, gameNumber, matchId }),
  },
);

export const streamValue = new schema.Entity(
  'streamUrls',
  {},
  {
    idAttribute: (_value, parent) => parent.key || null,
  },
);

export const teamSchema = new schema.Entity('teams', {
  players: [playerSchema],
});

export const tournamentSchema = new schema.Entity('tournaments', {
  event: eventSchema,
  teams: [teamSchema],
  tournamentStages: [tournamentStageSchema],
});

export const eventSubSchema = new schema.Entity('event', {
  eventDetails: eventSchema,
});

export const packageSchema = new schema.Entity(
  'packages',
  {
    tournaments: [tournamentSchema],
  },
  {
    idAttribute: value => value.humanReadableId || null,
    processStrategy: ({ tournaments, ...rest }) => ({
      ...rest,
      tournaments: tournaments?.edges.map(cleanNodes) || [],
    }),
  },
);

export const featuredHighlightSchema = new schema.Entity('featuredHighlight');

export const competitorSchema = new schema.Entity('competitors');

export const gameSchema = new schema.Entity(
  'games',
  {
    globalStreams: [stream],
    competitors: [competitorSchema],
  },
  {
    processStrategy: (value, parent) => ({ ...value, matchId: value.matchId ?? parent.id }),
  },
);

export const streamUrlSchema = new schema.Entity(
  'streams',
  { value: streamValue },
  {
    idAttribute: ({ key }) => key,
  },
);

export const channelSchema = new schema.Entity(
  'channels',
  {
    games: [gameSchema],
  },
  {
    processStrategy: values => ({
      live: values.live,
      games: values.matches.reduce(
        (acc, match) => [...acc, ...match.games.map(game => ({ ...game, matchId: match.id }))],
        [],
      ),
      onLiveChannelEnd: values.onLiveChannelEnd,
    }),
    mergeStrategy: (entityA, entityB) => ({
      ...entityA,
      ...entityB,
    }),
    idAttribute: value => (value._id ? value._id : value.id),
  },
);

export const matchSchema = new schema.Entity(
  'matches',
  {
    games: [gameSchema],
    tournament: tournamentSchema,
    teams: [teamSchema],
    streams: [streamUrlSchema],
    channel: channelSchema,
  },
  {
    processStrategy: values => {
      const newValues = {
        ...values,
        games: values.games?.sort((a, b) => a.gameNumber - b.gameNumber),
      };

      return removeUndefined(newValues);
    },
  },
);

export const highlightSchema = new schema.Entity('highlights', {
  match: matchSchema,
});

export const featuredSchema = new schema.Entity('featuredContents', {
  videos: [highlightSchema],
  matches: [matchSchema],
});

export const playlistVideos = new schema.Entity(
  'highlights',
  {},
  {
    processStrategy: ({ video, ...rest }) => ({
      ...video,
      ...rest,
    }),
  },
);

export const playlistSchema = new schema.Entity('playlists', {
  highlights: [playlistVideos],
});

export const championHighlightSchema = new schema.Entity(
  'highlights',
  {
    match: matchSchema,
  },
  {
    idAttribute: ({ id }) => id,
  },
);

export const riotChampionSchema = new schema.Entity(
  'champions',
  {
    highlights: [championHighlightSchema],
    scheduledMatches: [matchSchema],
    latestMatches: [matchSchema],
  },
  {
    idAttribute: value => value.champId,
    processStrategy: ({ riotChampionDetails, ...value }) => {
      const { edges } = riotChampionDetails;
      const { id, highlights, scheduledMatches, latestMatches, ...champDetails } =
        edges?.[0]?.node ?? {};
      return {
        ...value,
        ...champDetails,
        id,
        highlights: highlights?.edges.map(cleanNodes) || [],
        scheduledMatches: scheduledMatches?.edges.map(cleanNodes) || [],
        latestMatches: latestMatches?.edges.map(cleanNodes) || [],
      };
    },
  },
);

export const contentSubSchema = new schema.Entity('contentSubscription', {
  player: playerSchema,
  team: teamSubSchema,
  event: eventSubSchema,
  riotChampion: riotChampionSchema,
});

export const rootSchema = new schema.Entity(
  'data',
  {
    competitors: [competitorSchema],
    events: [eventSchema],
    featuredContents: [featuredSchema],
    highlights: [highlightSchema],
    editorials: [highlightSchema],
    videos: [highlightSchema],
    players: [playerSchema],
    featuredHighlight: [featuredHighlightSchema],
    playlists: [playlistSchema],
    scheduledMatches: [matchSchema],
    vodMatches: [matchSchema],
    latestMatches: [matchSchema],
    matches: [matchSchema],
    vods: [matchSchema],
    latestHighlights: [highlightSchema],
    popularHighlights: [highlightSchema],
    contentSubscriptions: [contentSubSchema],
    packages: [packageSchema],
    tournament: [tournamentSchema],
    allTournaments: [allTournamentsSchema],
    champions: [championSchema],
    championsSpotlight: [championSchema],
    teamsSpotlight: [teamSchema],
    teams: [teamSchema],
    spotlightPlayers: [playerSchema],
    channels: [channelSchema],
  },
  {
    // should get eventid / partner-id / humanReadableId
    idAttribute: () => 'riot-games',
  },
);
